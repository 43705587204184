define("webapp/locales/nl/translations", ["exports", "webapp/locales/nl/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {

    // auto
    "application.ui.flyout.receiver": "Neem contact op met",
    "application.ui.flyout.calendar": "Huidige data",
    "application.ui.flyout.koeppl-stier": "Neem contact op met",
    "application.ui.flyout.bag": "Winkelen",
    "application.ui.flyout.mail": "Neem contact op met",
    "application.ui.flyout.koeppl-jobs": "Banen",
    "application.ui.flyout.koeppl-request": "Inlichtingen",
    "application.ui.flyout.facebook": "Facebook",
    "application.ui.flyout.xing": "Xing",
    "application.ui.flyout.info": "Info",
    "application.ui.flyout.location": "Dealers",
    "application.ui.flyout.cog": "Dienst",
    "user.ui.accountToolbarLogin": "Portaal dealer",
    "user.ui.accountToolbarLoginTooltip": "Registratie van de handelaar",
    "product.navigation.mechanical": "Mechanische aandrijving",
    "product.navigation.hydrostatic": "Hydrostatische aandrijving",
    "product.navigation.electrical": "Elektrische aandrijving",
    "product.navigation.mechanicalCarrier": "Mechanische draagvoertuigen",
    "product.navigation.hydrostaticCarrier": "Hydrostats",
    "product.navigation.electricalCarrier": "Elektrische draagvoertuigen",
    "product.navigation.attachments": "Bijlagen",
    'product.variant.accessories': 'Accessoires voor de gekozen variant',
    "product.form.title": "Productaanvraag voor {{product}}",
    "product.form.noForm": "Voor een productaanvraag, gebruik ons algemeen contactformulier op de contactpagina.",
    "entries.entry.ui.products.delivery.red": "Product momenteel niet beschikbaar",
    "entries.entry.ui.products.delivery.yellow": "Product in voorraad. Mogelijk langere leveringstijd.",
    "entries.entry.ui.products.delivery.green": "Product in voorraad in voldoende hoeveelheid",
    'entries.product.ui.netPrizeBusiness': 'Nettoprijs plus BTW tegen het wettelijke tarief, plus ',
    "entries.entry.ui.jobs": "Vacatures",
    "entries.entry.ui.noJobs": "Momenteel geen vacatures (in deze categorie)",
    "entries.entry.ui.initiative": "Wil je een speculatieve aanvraag starten?",
    "entries.entry.ui.jobs.open": "Nog open",
    "entries.entry.ui.jobs.assessment": "Bij de selectie van kandidaten",
    "entries.entry.ui.jobs.closed": "Reeds bezet",
    "widgets.shoppingCart.wishlist": "Uitkijklijst",
    "job.summary": "Functieprofiel",
    "job.summary.contact": "Neem contact op met",
    "job.application.title": "Uw sollicitatie",
    "job.application.thanks.salutation": "Geachte Aanvrager,",
    "job.application.thanks.title": "Dank u voor uw sollicitatie en uw interesse in een volgende carrièrestap bij Bodystreet.",
    "job.application.thanks.content": "In de volgende stap zullen wij uw documenten verwerken en vervolgens contact met u opnemen.",
    "job.application.thanks.contact": "Heb je al een paar belangrijke vragen? Dan kunt u direct contact met ons opnemen.",
    "job.application.thanks.successWish": "Wij wensen u veel succes!",
    "job.application.studio": "Studio",
    "job.application.selectRegionCategory": "Selecteer een regio waarvoor u een aanvraag wilt indienen",
    "job.application.personalData": "Uw persoonlijke gegevens",
    "job.application.documents": "Aanvraagdocumenten",
    "job.application.privacyTitlePre": "Ik bevestig dat ik het gelezen en begrepen heb.",
    "job.application.privacyTitle": "en ik geef toestemming voor de verwerking van mijn persoonsgegevens door de",
    "job.application.privacyInfo": "Er zal geen verder gebruik van gegevens en geen bekendmaking van mijn persoonsgegevens aan derden plaatsvinden. Ik kan deze toestemming voor gegevensbescherming te allen tijde met werking voor de toekomst herroepen.",
    "job.application.checkPrivacy": "opgeslagen en gebruikt om schriftelijk, per e-mail of telefonisch contact met mij op te nemen in het kader van de sollicitatieprocedure en ook om mij te informeren over andere vacatures bij Köppl GmbH;",
    "job.application.checkApplicationPool": "opgeslagen voor een periode van maximaal 6 maanden in het kader van opname in de Köppl-sollicitatiepool, gedurende deze periode doorgegeven aan Köppl GmbH en derden en door hen opgeslagen en gebruikt om schriftelijk, per e-mail of telefonisch contact met mij op te nemen in het kader van de sollicitatieprocedure en ook om mij te informeren over andere vacatures in het Köppl-systeem.",
    "job.application.submit": "Aanvraag nu verzenden",
    "job.application.submitNotReady": "Alle verplichte velden moeten worden ingevuld om het formulier te verzenden",
    "job.application.coverNote": "Begeleidende brief",
    "job.application.cv": "Curriculum Vitae",
    "job.application.photo": "Toepassingsbeeld",
    "job.application.certificate": "Getuigenissen",
    "job.application.misc": "Andere",
    "job.application.message": "Noot",
    "job.application.reason": "Waarom solliciteer je bij Köppl?",
    "entries.entry.ui.eventsPartial": "Programma van het evenement",
    "entries.entry.ui.events.availableSeats": "Beschikbare plaatsen",
    "entries.entry.ui.events.bookSeats": "Ik boek bindend",
    "entries.entry.ui.events.seat": "Plaats",
    "entries.entry.ui.events.seats": "Plaatsen",
    "entries.entry.ui.events.remainingSeats": "Nog beschikbare plaatsen",
    "entries.entry.ui.events.noSeats": "Het evenement is momenteel volgeboekt. Er zijn geen plaatsen meer beschikbaar.",
    "media.documents.search.placeholder": "Zoek naar apparaatnamen, apparaatafkortingen of serienummers.",
    "media.documents.search.categoriesFiltered": "Alleen documenten uit de volgende categorieën worden weergegeven:",
    "media.documents.search.categories": "Gebruik het categoriefilter om alleen documenten uit de geselecteerde categorie weer te geven.",
    "media.documents.search.languageFilter": "Gebruik de taalfilter om alleen documenten in de geselecteerde taal weer te geven.",
    "media.documents.search.noResult": "Voer een zoekterm in of selecteer een documentcategorie die moet worden weergegeven.",
    "media.documents.search.info": "Houd er rekening mee dat de zoekopdracht zo is ingesteld dat soortgelijke termen of nummers ook worden gevonden. De zoekresultaten worden gesorteerd op relevantie. Dit betekent dat documenten met de meeste overeenkomsten bovenaan staan (linksboven). Let er bij het selecteren van het document op dat het serienummer correct is, vooral bij onderdelenlijsten en daaropvolgende bestellingen van onderdelen. Als het serienummer relevant is, wordt het samen met het document weergegeven."
  });
});