define("webapp/locales/fr/translations", ["exports", "webapp/locales/fr/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "application.ui.flyout.receiver": "Contactez",
    "application.ui.flyout.bag": "Boutique",
    "application.ui.flyout.shop": "eStore",
    "application.ui.flyout.facebook": "Facebook",
    "application.ui.flyout.xing": "Xing",
    "application.ui.flyout.info": "Info",
    "application.ui.flyout.cog": "Service",
    "entries.entry.ui.eventsPartial": "Programme des événements",
    "entries.entry.ui.events.availableSeats": "Places disponibles",
    "entries.entry.ui.events.bookSeats": "Je réserve obligatoirement",
    "entries.entry.ui.events.seat": "Place",
    'entries.entry.ui.events.seats': 'Places',
    "entries.entry.ui.events.remainingSeats": "Places encore disponibles",
    "entries.entry.ui.events.noSeats": "La manifestation est actuellement complète. Il n'y a plus de places disponibles.",

    // auto
    "application.ui.flyout.calendar": "Dates actuelles",
    "application.ui.flyout.koeppl-stier": "Contactez",
    "application.ui.flyout.mail": "Contactez",
    "application.ui.flyout.koeppl-jobs": "Emplois",
    "application.ui.flyout.koeppl-request": "Demandes",
    "application.ui.flyout.location": "Revendeurs",
    "user.ui.accountToolbarLogin": "Portail des revendeurs",
    "user.ui.accountToolbarLoginTooltip": "Inscription des commerçants",
    "product.navigation.mechanical": "Entraînement mécanique",
    "product.navigation.hydrostatic": "Entraînement hydrostatique",
    "product.navigation.electrical": "Entraînement électrique",
    "product.navigation.mechanicalCarrier": "Véhicules porteurs mécaniques",
    "product.navigation.hydrostaticCarrier": "Hydrostats",
    "product.navigation.electricalCarrier": "Véhicules porteurs électriques",
    "product.navigation.attachments": "Outils portés",
    'product.variant.accessories': 'Accessoires pour la variante choisie',
    "product.form.title": "Demande de produit pour {{product}}",
    "product.form.noForm": "Pour une demande de produit, utilisez notre formulaire de contact général sur la page de contact.",
    "entries.entry.ui.products.delivery.red": "Produit actuellement non disponible",
    "entries.entry.ui.products.delivery.yellow": "Produit en stock. Délai de livraison éventuellement plus long.",
    "entries.entry.ui.products.delivery.green": "Produit en stock en quantité suffisante",
    'entries.product.ui.netPrizeBusiness': 'Prix net, TVA légale en sus, plus ',
    "entries.entry.ui.jobs": "Postes à pourvoir",
    "entries.entry.ui.noJobs": "Aucun poste vacant actuellement (dans cette catégorie)",
    "entries.entry.ui.initiative": "Vous souhaitez lancer une candidature spontanée ?",
    "entries.entry.ui.jobs.open": "Encore ouvert",
    "entries.entry.ui.jobs.assessment": "Dans Sélection des candidats",
    "entries.entry.ui.jobs.closed": "Déjà occupé",
    "widgets.shoppingCart.wishlist": "Liste de suivi",
    "job.summary": "Fiche de poste",
    "job.summary.contact": "Contactez",
    "job.application.title": "Votre demande",
    "job.application.thanks.salutation": "Cher demandeur,",
    "job.application.thanks.title": "Nous vous remercions de votre candidature et de votre intérêt pour la prochaine étape de votre carrière chez Bodystreet.",
    "job.application.thanks.content": "Au cours de l'étape suivante, nous traiterons vos documents et prendrons ensuite contact avec vous.",
    "job.application.thanks.contact": "Vous avez déjà quelques questions importantes ? Vous pouvez alors nous contacter directement.",
    "job.application.thanks.successWish": "Nous vous souhaitons beaucoup de succès !",
    "job.application.studio": "Studio",
    "job.application.selectRegionCategory": "Sélectionnez une région pour laquelle vous souhaitez postuler",
    "job.application.personalData": "Vos données personnelles",
    "job.application.documents": "Candidature",
    "job.application.privacyTitlePre": "Je certifie que",
    "job.application.privacyTitle": "et je consens à ce que mes données personnelles soient utilisées pour",
    "job.application.privacyInfo": "Aucune autre utilisation des données et aucune divulgation de mes données personnelles à des tiers n'aura lieu. Je peux révoquer ce consentement à la protection des données à tout moment avec effet pour l'avenir.",
    "job.application.checkPrivacy": "sont enregistrées et utilisées pour me contacter par écrit, par e-mail ou par téléphone dans le cadre du processus de candidature et pour m'informer également d'autres offres d'emploi de Köppl GmbH ;",
    "job.application.checkApplicationPool": "dans le cadre de l'intégration dans le pool de candidats Köppl, sont enregistrées pour une durée maximale de 6 mois, transmises pendant cette période à Köppl GmbH et à des tiers et enregistrées et utilisées par ces derniers pour me contacter par écrit, par e-mail ou par téléphone dans le cadre du processus de candidature et pour m'informer également d'autres offres d'emploi dans le système Köppl.",
    "job.application.submit": "Envoyez votre candidature maintenant",
    "job.application.submitNotReady": "Tous les champs obligatoires doivent être remplis pour envoyer le formulaire.",
    "job.application.coverNote": "Lettre d'accompagnement",
    "job.application.cv": "Curriculum Vitae",
    "job.application.photo": "Photo de la candidature",
    "job.application.certificate": "Témoignages",
    "job.application.misc": "Autres",
    "job.application.message": "Note",
    "job.application.reason": "Pourquoi postuler chez Köppl ?",
    "media.documents.search.placeholder": "Recherchez par nom d'appareil, abréviation d'appareil ou numéro de série.",
    "media.documents.search.categoriesFiltered": "Seuls les documents des catégories suivantes s'affichent :",
    "media.documents.search.categories": "Utilisez le filtre de catégorie pour n'afficher que les documents de la catégorie sélectionnée.",
    "media.documents.search.languageFilter": "Utilisez le filtre de langue pour n'afficher que les documents de la langue sélectionnée.",
    "media.documents.search.noResult": "Saisissez un terme de recherche ou sélectionnez une catégorie de documents à afficher.",
    "media.documents.search.info": "Veuillez noter que la recherche est configurée de manière à trouver également des termes ou des numéros similaires. Les résultats de la recherche sont classés par ordre de pertinence. C'est-à-dire que les documents avec la plus grande concordance sont en première position (en haut à gauche). Lors de la sélection du document, veillez à ce que le numéro de série soit correct, en particulier pour les listes de pièces de rechange et les commandes de pièces de rechange qui s'ensuivent. Si le numéro de série est pertinent, il est affiché avec le document."
  });
});