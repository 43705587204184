define("webapp/locales/pt-br/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Defina uma imagem principal para sua entrada.",
    "account.ui.profileBannerImageNew": "Defina uma imagem principal para seu perfil.",
    "account.ui.register": "Registre-se",
    "account.ui.registeredAs": "Você está atualmente registrado como",
    "account.ui.personate": "Mudar a identidade do usuário",
    "account.ui.userNameNotEditableTooltip": "Seu nome de usuário. Você não pode mudar o nome de usuário que você digitou. Para mudar seu nome de usuário, favor entrar em contato com sua pessoa de contato. Importante: Após uma mudança, você deve entrar novamente com o novo nome de usuário.",
    "account.ui.additionalDeliveryAddresses": "Você inseriu diferentes endereços de entrega.",
    "account.ui.editDeliveryAddresses": "Editar endereço de entrega",
    "account.ui.editInvoiceAddress": "Editar endereço da empresa",
    "account.ui.noDeliveryAddresses": "Você ainda não inseriu um endereço de entrega diferente.",
    "account.ui.addDeliveryAddresses": "Adicione um {{further}} Adicionar um endereço de entrega diferente",
    "account.ui.addDeliveryAddressTitle": "Adicionar endereço de entrega",
    "account.ui.editDeliveryAddressTitle": "Editar endereço de entrega",
    "account.ui.isDefaultAddress": "Este é o endereço padrão de entrega",
    "account.ui.saveToApplyChanges": "Você deve salvar a mudança, então você pode, por exemplo, especificar diferentes endereços de entrega.",
    "accountTypes.aussteller": "Expositor",
    "accountTypes.gastgeber": "Anfitrião",
    "accountTypes.veranstalter": "Organizador",
    "application.beta.infotext": "Não foram criadas assinaturas.",
    "application.breadcrumbsHome": "Início",
    "application.month": "por mês",
    "application.newsletter.alreadySubscribed": "Você já é um assinante do {{list}} boletim informativo. Você pode acessar as assinaturas do boletim informativo em sua conta.",
    "application.newsletter.alreadySubscribedNoList": "Você já se registrou para o boletim informativo",
    "application.newsletter.goToList": "Lista de discussão de chamadas",
    "application.newsletter.subscribeButton": "Digite seu endereço de e-mail para se inscrever no boletim",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.noAccess": "O boletim oferecido não pode ser subscrito com suas permissões.",
    "application.newsletter.subscribe": "Assine",
    "application.newsletter.subscribeList": "Você está assinando este boletim informativo:",
    "application.newsletter.subscribeThanks": "Obrigado por subscrever nossa newsletter. Você receberá o último boletim informativo em breve.",
    "application.newsletter.subscribeThanksOptIn": "Obrigado por subscrever nossa newsletter. Você receberá outro e-mail de confirmação de ativação.",
    "application.newsletter.subscribeWithAccount": "Inscreva-se com seu usuário atual conectado.",
    "application.noCosts": "Gratuito",
    "application.placeholderImageText": "Sem ilustração",
    "application.searchAllGroups": "em todos",
    "application.searchAllRegions": "em todos os lugares",
    "application.ui.access": "acesso",
    "application.ui.action": "Ação",
    "application.ui.addDocument": "Adicionar documento",
    "application.ui.addImage": "Adicionar imagem",
    "application.ui.addImages": "Adicionar fotos",
    "application.ui.addMedia": "Adicionar fotos/videos",
    "application.ui.addVideo": "Adicionar vídeo",
    "application.ui.advancedEdit": "Estendido",
    "application.ui.all": "Todos",
    "application.ui.alreadyFavorite": "Este item está em sua lista de observação",
    "application.ui.makeFavorite": "Adicione este item à sua lista de observação",
    "application.ui.also": "Também",
    "application.ui.alphabetical": "Em ordem alfabética",
    "application.ui.and": "e",
    "application.ui.at": "em",
    "application.ui.in": "em",
    "application.ui.atHour": "em",
    "application.ui.hour": "Relógio",
    "application.ui.buy": "compre",
    "application.ui.certificate": "Certificados",
    "application.ui.confirmDeleteEntry": "Eliminar a entrada {{title}} eliminar de forma irrevogável?",
    "application.ui.contact": "Contato",
    "application.ui.count": "Número",
    "application.ui.currentlyActive": "Em funcionamento agora mesmo",
    "application.ui.deleteEntry": "excluir",
    "application.ui.discardUnsavedChanges": "Descartar mudanças e continuar",
    "application.ui.discardUnsavedChangesMobile": "Descartar",
    "application.ui.distance": "Distância",
    "application.ui.distant": "retirado",
    "application.ui.dreampoints": "Dreampoints",
    "application.ui.dreampointsAllDreams": "Pontos de sonho de suas entradas",
    "application.ui.dreampointsDonate": "Pontos de sonho para doar",
    "application.ui.dreampointsTooltip": "Você pode coletar Dreampoints com sua entrada",
    "application.ui.edit": "mudar",
    "application.ui.editDescription": "Editar descrição",
    "application.ui.editEntry": "editar",
    "application.ui.editEvent": "Editar evento",
    "application.ui.editImage": "Editar imagem",
    "application.ui.editPoi": "Editar local",
    "application.ui.editProduct": "Editar produto",
    "application.ui.editProfile": "Editar meu perfil",
    "application.ui.editShownProfile": "Mudar minha exibição de perfil",
    "application.ui.entriesFocus": "Entradas selecionadas em Foco:",
    "application.ui.entriesLatest": "Últimas entradas em Foco:",
    "application.ui.email": "E-mail",
    "application.ui.for": "para",
    "application.ui.from": "de",
    "application.ui.fullView": "Visão geral",
    "application.ui.gallery": "Imagens",
    "application.ui.galleryImages": "para a galeria...",
    "application.ui.gavePoints": "lhe deu {{points}} pontos.",
    "application.ui.goToEntry": "Entrada de chamada",
    "application.ui.goToEvent": "Evento de chamada",
    "application.ui.goToPoi": "Local de chamada",
    "application.ui.goToMeasuring": "Pontos de medição",
    "application.ui.goToProduct": "Chamar produto",
    "application.ui.hasBeen": "foi",
    "application.ui.here": "Nas proximidades",
    "application.ui.invalidEmbed": "Esta URL não pode ser embutida.",
    "application.ui.imprintLink": "Responsável e legalmente responsável pelo conteúdo desta subpágina {{entry}} - Impresso",
    "application.ui.isActive": "Ativo",
    "application.ui.isNotActive": "Deficiente",
    "application.ui.knowMore": "Todos {{aboutPre}} {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Idioma atualmente selecionado",
    "application.ui.language.switchTo": "Alterar o idioma para ",
    "application.ui.mailBodyPre": "Olá, encontrei algo interessante:",
    "application.ui.mailSubject": "Encontrei um link interessante:",
    "application.ui.mandatory": "Campo obrigatório",
    "application.ui.memberSince": "Membro desde",
    "application.ui.mobileRotate": "Girar para visualização em tela cheia",
    "application.ui.more": "mais",
    "application.ui.name": "Nome",
    "application.ui.navigateOneLevelUp": "Voltar para a pasta dos pais",
    "application.ui.new": "Novo",
    "application.ui.newEntry": "Criar nova entrada",
    "application.ui.newEvent": "Criar um novo evento",
    "application.ui.newPoi": "Criar novo local",
    "application.ui.newProduct": "Criar um novo produto",
    "application.ui.noEntry": "Você ainda não criou uma entrada. Crie sua primeira entrada agora mesmo...",
    "application.ui.noEntryProfile": "ainda não criou uma entrada.",
    "application.ui.noEvent": "Você ainda não criou um evento. Crie seu primeiro evento agora mesmo...",
    "application.ui.noPoi": "Você ainda não criou um local. Crie sua primeira localização agora mesmo...",
    "application.ui.noJob": "Você ainda não criou um trabalho. Crie seu primeiro trabalho agora...",
    "application.ui.noProduct": "Você ainda não criou um produto. Crie seu primeiro produto agora mesmo...",
    "application.ui.notEditable": "não pode ser mudado",
    "application.ui.nothingFound": "Não conseguimos encontrar nenhum resultado para esta busca.",
    "application.ui.offer": "Ofertas",
    "application.ui.offer.offers": "Ofertas",
    "application.ui.offer.acceptOffer": "Aceitar oferta",
    "application.ui.offer.rejectOffer": "Rejeitar oferta",
    "application.ui.offer.noOffers": "Nenhuma oferta",
    "application.ui.offer.expiresAt": "A oferta vai até",
    "application.ui.offer.offerExpires": "A oferta expira",
    "application.ui.offer.setOtherExpirationDate": "Defina outra data",
    "application.ui.offer.inXDays": "em 30 dias",
    "application.ui.only": "Somente",
    "application.ui.openDocument": "Abrir arquivo",
    "application.ui.or": "ou",
    "application.ui.playVideo": "Reproduzir vídeo",
    "application.ui.readMore": "Leia mais",
    "application.ui.region": "Região",
    "application.ui.relevance": "Relevância",
    "application.ui.reset": "Reinicializar todos os filtros",
    "application.ui.routingDescription": "Instruções",
    "application.ui.saveShownProfile": "Salvar mudanças de perfil",
    "application.ui.saveUnsavedChanges": "Salvar",
    "application.ui.select": "Favor selecionar",
    "application.ui.shop": "Loja",
    "application.ui.showAllEntries": "Mostrar todas as entradas",
    "application.ui.showAllSearch": "Mostrar todos os resultados da busca",
    "application.ui.showMoreSearch": "Mostrar mais resultados da pesquisa",
    "application.ui.resetSearch": "Restabelecer a seleção e a busca novamente",
    "application.ui.sort": "Ordenação",
    "application.ui.stockPhotoLinks": "Links para bancos de dados de imagens",
    "application.ui.suitableFor": "Recomendado para",
    "application.ui.total": "Total",
    "application.ui.totalDreampoints": "Pontos de sonho de todas as suas entradas",
    "application.ui.trySearch": "Você quer tentar outros termos de busca, tais como",
    "application.ui.type": "Tipo",
    "application.ui.unsavedChanges": "Mudanças não salvas",
    "application.ui.updated": "atualizado",
    "application.ui.uploadedFileFor": "Arquivo carregado para:",
    "application.ui.userName": "Nome de usuário",
    "application.ui.viewAsList": "Vista de lista",
    "application.ui.viewAsTable": "Vista de tabela",
    "application.ui.visibleForAll": "Visível para todos os usuários",
    "application.ui.visibleForFollower": "Visível para os seguidores",
    "application.ui.visibleForJoiner": "Visível para marceneiros",
    "application.ui.visibleForNone": "Não visível para ninguém",
    "application.ui.visibleForSupporter": "Visível para adeptos",
    "application.ui.years": "Anos",
    "application.ui.yearsFew": "A idade do bebê",
    "application.widgets.highestRatedEntries": "Os pontos mais sonhados",
    "application.widgets.inFocus": "Em foco",
    "application.widgets.newestEntries": "As últimas {{size}} Inscrições",
    "application.widgets.noNewestEntries": "Não foram encontradas novas entradas...",
    "application.widgets.showCategories": "Mostrar filtro de categoria",
    "application.widgets.showFilter": "Mostrar mais filtros",
    "application.ui.writeUs": "Escreva para nós...",
    "blog.ui.category": "Categoria",
    "blog.ui.categoryHeading": "Da categoria",
    "blog.ui.lastUpdated": "Última atualização",
    "blog.ui.openSinglePost": "Leia mais",
    "blog.ui.publishedIn": "Publicado em",
    "blog.ui.subCategories": "Subcategorias",
    "blog.ui.written": "Escrito",
    "blog.ui.writtenBy": "Escrito por",
    "buttons.alreadyMeToo": "Estou feliz que você também esteja sonhando com esta entrada.",
    "buttons.alreadyMeTooMobile": "Estou feliz que você também esteja sonhando com esta entrada.",
    "buttons.cancel": "Cancelar",
    "buttons.confirm": "Confirme",
    "buttons.createAccount": "Configurar agora o EntryerAccount",
    "buttons.delete": "excluir",
    "buttons.deleteFavorite": "Não segue mais",
    "buttons.deleteLike": "Não há mais parecidos",
    "buttons.meToo": "Eu também gostaria de fazer isso",
    "buttons.newPassword": "Solicitar nova senha",
    "buttons.ok": "Ok",
    "buttons.ready": "Pronto",
    "buttons.save": "Salvar mudanças",
    "buttons.saveMobile": "Salvar",
    "buttons.selectThis": "Selecione este",
    "buttons.tryAgain": "Por favor, tente novamente",
    "category.ui.hideCategories": "Ocultar subcategorias",
    "category.ui.oneLevelUp": "Voltar à categoria superordenada",
    "category.ui.showCategories": "Mostrar subcategorias",
    "categoryFields.attachmentWidth": "Largura do implemento",
    "categoryFields.clutch": "Acoplamento",
    "categoryFields.companyName": "Nome da empresa",
    "categoryFields.dealerInfo": "Informações sobre o revendedor",
    "categoryFields.diameter": "Diâmetro",
    "categoryFields.displayNetPrice": "Preço líquido",
    "categoryFields.electricalPower": "Energia elétrica",
    "categoryFields.engine": "Motor",
    "categoryFields.engineDescription": "Equipamento do motor",
    "categoryFields.ERPname": "Nome na administração da mercadoria",
    "categoryFields.horsePower": "Energia",
    "categoryFields.netWidth": "Largura da via sem pneus",
    "categoryFields.packingUnit": "Unidade de embalagem",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Alarga a pista por",
    "categoryFields.vendorAcronym": "Designação breve",
    "categoryFields.volume": "Volume",
    "categoryFields.weight": "Peso",
    "categoryFields.wheelLock": "Trava da roda de pé",
    "checkout.cart.title": "Meu carrinho de compras",
    "checkout.cart.item": "Artigo",
    "checkout.cart.amount": "Número",
    "checkout.cart.edit": "Mudança",
    "checkout.cart.price": "Preço",
    "checkout.cart.singlePrice": "Preço unitário",
    "checkout.cart.product.isNonPhysical": "O produto não é enviado (pode estar disponível para download)",
    "checkout.cart.product.requiresExpressDelivery": "Produto fresco com envio EXPRESS. Somente dentro de D.",
    "checkout.cart.product.deliveryProblem": "O produto selecionado pode estar sujeito a atrasos na entrega.",
    "checkout.cart.subtotal": "Subtotal",
    "checkout.cart.deliveryCosts": "Custos de envio",
    "checkout.cart.total": "Total",
    "checkout.cart.help": "Você tem perguntas sobre o processo de pedido? Nós compilamos informações importantes para você.",
    "checkout.cart.subtotalInfo": "incl. IVA",
    "checkout.cart.subtotalShipment": "mais custos de remessa estimados",
    "checkout.cart.checkout": "Checkout",
    "checkout.cart.checkoutWithoutUser": "Checkout sem conta de cliente",
    "checkout.cart.checkoutFast": "Checkout rápido",
    "checkout.cart.deliveryInfo": "Data de envio dos artigos no carrinho de compras",
    "checkout.cart.orderSuccess": "Seu pedido foi bem sucedido.",
    "checkout.cart.orderCommitting": "Seu pedido está sendo processado - por favor, aguarde",
    "checkout.cart.myOrder": "Meu pedido",
    "checkout.cart.leftover": "esquerda",
    "checkout.checkout.orderNumber": "Número do pedido",
    "checkout.checkout.account": "Conta do cliente",
    "checkout.checkout.alreadyAccount": "Já é um cliente? Clique aqui para se registrar.",
    "checkout.checkout.andOrder": "e ordem",
    "checkout.checkout.delivery": "Endereço para entrega e entrega",
    "checkout.checkout.delivery.type": "O despacho é feito com",
    "checkout.checkout.delivery.street": "Linha de endereço 1",
    "checkout.checkout.delivery.houseNumber": "Não.",
    "checkout.checkout.delivery.co": "Sufixo de endereço (c/o)",
    "checkout.checkout.delivery.city": "Cidade",
    "checkout.checkout.delivery.selectCountry": "Por favor, selecione o país",
    "checkout.checkout.delivery.addressBoth": "Endereço para faturamento e entrega",
    "checkout.checkout.delivery.addressInvoice": "Endereço da fatura",
    "checkout.checkout.delivery.addressDelivery": "Endereço de entrega",
    "checkout.checkout.delivery.default": "Defina este endereço como meu endereço de entrega padrão",
    "checkout.checkout.delivery.otherAddress": "O endereço de entrega é diferente do endereço de faturamento",
    "checkout.checkout.delivery.dhlNr": "Seu número postal DHL",
    "checkout.checkout.delivery.packingStation": "Número da estação de embalagem",
    "checkout.checkout.delivery.showPackingStations": "Mostrar Packstations nas proximidades do meu endereço",
    "checkout.checkout.delivery.wantExpressShipping": "Entrega expressa",
    "checkout.checkout.delivery.isPickUp": "Pegar no local",
    "checkout.checkout.delivery.addressNotVerified": "Seu endereço selecionado não está completo ou ainda não foi verificado. Não podemos entregar a você. Para completar seu endereço, por favor, clique no símbolo do lápis para seu endereço. Se você tiver alguma dúvida sobre o processo de pedido, nossa equipe de suporte ao cliente terá prazer em ajudar.",
    "checkout.checkout.delivery.specialCountryInfo": "Seu país não pode ser selecionado?",
    "checkout.checkout.delivery.specialCountry": "Seu país está fora de nosso padrão de envio",
    "checkout.checkout.delivery.specialCountryMessage": "Os produtos individuais em seu carrinho de compras não podem ser enviados para todos os países. Favor remover alguns produtos de seu pedido até que seu país possa ser selecionado ou entre em contato conosco.",
    "checkout.checkout.payment": "Forma de pagamento",
    "checkout.checkout.paymentProblem": "Ocorreu um erro ao executar o pagamento",
    "checkout.checkout.backToPayment": "Voltar para as configurações de pagamento.",
    "checkout.checkout.paymentTitle": "Como você quer pagar?",
    "checkout.checkout.paymentSelected": "Forma de pagamento selecionada",
    "checkout.checkout.payment.cc": "Cartão de crédito",
    "checkout.checkout.payment.ccInfo": "Para pagamentos com cartão dentro da UE, sua identidade deve ser confirmada cada vez que você paga on-line. Após fazer seu pedido, você será orientado através do processo de confirmação fornecido por seu banco.",
    "checkout.checkout.payment.complimentary": "sem custos",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Você será redirecionado para o PayPal para completar o processo de pagamento. Sua conta PayPal não será debitada até que você confirme o pedido na próxima etapa.",
    "checkout.checkout.payment.paypalInfoExtended": "<strong>Pague com PayPal mesmo sem uma conta PayPal.</strong> Você pode pagar por débito direto ou outras formas de pagamento. Você será redirecionado para o PayPal para completar o processo de pagamento. Sua conta PayPal só será debitada quando você confirmar o pedido na próxima etapa.",
    "checkout.checkout.payment.sepa": "Débito direto (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Eu autorizo {{siteOwner}}, {{siteOwnerAddress}} (Creditor ID: {{siteOwnerCreditorID}}) para cobrar pagamentos de minha conta por débito direto. Ao mesmo tempo, instruo meu banco a honrar os débitos diretos de {{siteOwner}} à minha conta por débito direto.",
    "checkout.checkout.payment.noSepa": "Seu endereço de faturamento ou de entrega está fora da UE. Portanto, não podemos lhe oferecer pagamento por débito direto.",
    "checkout.checkout.payment.sepaInfo": "Rápido e descomplicado. Ao completar seu pedido (próximo passo), você será solicitado a inserir seus dados bancários (IBAN). Por favor, tenha isto pronto. Quando a transferência for concluída, você receberá uma confirmação.",
    "checkout.checkout.payment.prepayment": "Pré-pagamento (transferência bancária)",
    "checkout.checkout.payment.prepaymentInfo": "Depois que você fizer o pedido, nós lhe enviaremos as informações de transferência bancária por e-mail. Só podemos reservar os itens em seu pedido por um período máximo de 7 dias. Por favor, mantenha esta janela de tempo para a transferência bancária. Assim que recebermos o pagamento, o pedido será enviado.",
    "checkout.checkout.payment.invoice": "Fatura",
    "checkout.checkout.payment.invoiceInfo": "O valor da fatura é devido na data de pagamento indicada na confirmação de envio por e-mail.",
    "checkout.checkout.payment.noInvoice": "Infelizmente, não podemos lhe oferecer pagamento por fatura para este pedido. Por favor, escolha uma das outras formas de pagamento.",
    "checkout.checkout.payment.sofort": "Transferência bancária instantânea",
    "checkout.checkout.payment.sofortInfo": "Ao completar seu pedido (próximo passo), você será solicitado a inserir seus dados bancários (IBAN). Por favor, tenha isto pronto. Quando a transferência for concluída, você receberá uma confirmação.",
    "checkout.checkout.payment.accountName": "Titular da conta",
    "checkout.checkout.payment.accountEmail": "Endereço de e-mail de confirmação",
    "checkout.checkout.sepaAndOrder": "Mandato de débito direto para {{totalAmount}} Euros confirmar e encomendar com custos",
    "checkout.checkout.sofortAndOrder": "Mandato de transferência para {{totalAmount}} Euros confirmar e encomendar com custos",
    "checkout.checkout.title": "Checkout",
    "checkout.checkout.next": "mais",
    "checkout.checkout.order": "Compre agora",
    "checkout.checkout.orderConsentPre": "Ao fazer um pedido, você concorda com nosso",
    "checkout.checkout.orderConsentPost": "concordam.",
    "checkout.checkout.noAccount": "Não criar uma conta de cliente",
    "checkout.redirect.countryPriceChange": "Os preços para seu país são diferentes do preço padrão. Favor confirmar a mudança de preço.",
    "comments.blockComments": "Os comentários para este posto foram encerrados.",
    "comments.comment.anonymous": "Anônimo",
    "comments.comment.button": "Escreva um novo comentário",
    "comments.comment.buttonReply": "Respostas",
    "comments.comment.cancel": "Cancelar",
    "comments.comment.content": "Seu comentário",
    "comments.comment.hasCommented": "comentários",
    "comments.comment.login": "Faça o login para enviar um comentário",
    "comments.comment.past": "tem",
    "comments.comment.replyFrom": "Resposta a",
    "comments.comment.showReplies": "Mostrar respostas",
    "comments.comment.showReply": "Mostrar resposta",
    "comments.comment.submit": "Enviar para",
    "comments.comment.title": "Título do comentário",
    "comments.comment.userName": "Seu nome de usuário",
    "comments.heading": "Sugestões, notas, comentários",
    "comments.hierarchy.flat": "Apartamento",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Vista de comentário:",
    "comments.message.afterSaveError": "Seu comentário não pode ser salvo no momento!",
    "comments.message.afterSaveSuccess": "Seu comentário foi salvo.",
    "comments.message.afterSaveUnlock": "Seu comentário foi salvo, mas ainda precisa ser verificado por um administrador.",
    "comments.noComments": "Ainda não foram feitos comentários",
    "comments.reply": "AN:",
    "comments.show": "Mostrar comentários",
    "comments.title": "Comentários",
    "entries.edit": "Entrada <strong>{{title}}</strong> editar",
    "entries.entries": "Inscrições",
    "entries.product.ui.chooseVariant": "Selecione uma variante",
    "entries.product.ui.chooseVariantMobile": "Selecione a variante",
    "entries.product.ui.combinationNotAvailable": "Combinação não possível",
    "entries.entry.fields.address.city": "Cidade",
    "entries.entry.fields.address.country": "País",
    "entries.entry.fields.address.houseNumber": "Número da casa",
    "entries.entry.fields.address.street": "Rua",
    "entries.entry.fields.address.zip": "POSTCODE",
    "entries.entry.fields.language": "Idioma",
    "entries.entry.fields.prefixMissing": "Os seguintes campos não são preenchidos:",
    "entries.entry.profile.edit": "Editar perfil",
    "entries.entry.profile.publicProfile": "para minha página de perfil público",
    "entries.entry.tooltip.alreadyCopied": "Você já está sonhando com esta entrada.",
    "entries.entry.tooltip.alreadyFollow": "Você já está seguindo esta entrada",
    "entries.entry.tooltip.alreadyLiked": "Você já gosta desta entrada",
    "entries.entry.tooltip.copied": "É bom que você queira sonhar com esta entrada também. Sua foto de perfil foi adicionada a esta entrada.",
    "entries.entry.tooltip.filterComments": "Clique aqui para mostrar apenas Comentários no Entryline",
    "entries.entry.tooltip.filterFollows": "Clique aqui para mostrar somente Follows no Entryline",
    "entries.entry.tooltip.filterLikes": "Clique aqui para mostrar apenas os gostos na linha de entrada",
    "entries.entry.tooltip.filterShares": "Clique aqui para mostrar apenas as ações na linha de entrada",
    "entries.entry.tooltip.follow": "Clique aqui para seguir esta entrada",
    "entries.entry.tooltip.like": "Clique aqui para dar a esta entrada um Like",
    "entries.entry.tooltip.noFollow": "Seguir só funciona para as entradas de outras pessoas",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Você deve estar logado para seguir esta entrada",
    "entries.entry.tooltip.noLike": "Como se só funcionasse para as entradas de outras pessoas",
    "entries.entry.ui.access": "Somente o seguinte pode ver esta entrada",
    "entries.entry.ui.added": "...foi adicionado.",
    "entries.entry.ui.addEntry": "Adicionar entradas",
    "entries.entry.ui.addOpening": "Adicionar tempo de abertura",
    "entries.entry.ui.address": "Dados de endereço",
    "entries.entry.ui.addStep": "Adicionar um passo",
    "entries.entry.ui.agreementHours": "Aberto por acordo",
    "entries.entry.ui.allAccess": "Todos podem ver esta entrada",
    "entries.entry.ui.assignedCategories": "categorias designadas",
    "entries.entry.ui.assignedCertificates": "Minhas certificações",
    "entries.entry.ui.assignedProducts": "Produtos designados",
    "entries.entry.ui.businessContact": "Dados de contato da empresa",
    "entries.entry.ui.categories": "Categorias para esta entrada",
    "entries.entry.ui.categoryFields": "Mais informações",
    "entries.entry.ui.commercialTitle": "Informações da empresa sobre a entrada",
    "entries.entry.ui.companyTitle": "Detalhes da minha empresa",
    "entries.entry.ui.contactPerson": "Meus dados de contato",
    "entries.entry.ui.cssClass": "Classe CSS para o conteúdo",
    "entries.entry.ui.deliveryInfo": "Informações sobre o serviço de entrega",
    "entries.entry.ui.descriptionInfo": "Dica: Quando as palavras são marcadas, os estilos podem ser atribuídos.",
    "entries.entry.ui.descriptionPlaceholder": "Descrição com HTML. Clicar duas vezes no texto da barra de funções HTML (máximo 600 caracteres).",
    "entries.entry.ui.editEntry": "Editar entrada",
    "entries.entry.ui.editEntryNew": "Criar entrada",
    "entries.entry.ui.exclusionHours": "Tempos de exclusão",
    "entries.entry.ui.galleryImages": "Galeria de fotos (2 ou 3 fotos, dependendo do arranjo)",
    "entries.entry.ui.globalTitle": "Nome",
    "entries.entry.ui.hasKiosk": "Eu tenho uma banca",
    "entries.entry.ui.imagesHorizontal": "Horizontal",
    "entries.entry.ui.imagesInfo": "As imagens podem ser editadas em \"Imagens/Vídeos/Documentos\".",
    "entries.entry.ui.imagesOrientation": "Alinhamento da imagem",
    "entries.entry.ui.imagesSquared": "Praça",
    "entries.entry.ui.imagesVertical": "Vertical",
    "entries.entry.ui.isListImage": "Esta imagem é a imagem da lista",
    "entries.entry.ui.isMainImage": "Esta imagem é a imagem principal",
    "entries.entry.ui.isMainImageAndListImage": "Esta imagem aparece como a imagem principal da entrada",
    "entries.entry.ui.jobApply": "Inscreva-se agora",
    "entries.entry.ui.kioskDescription": "Descrição do seu estande de vendas",
    "entries.entry.ui.listImage": "Lista foto",
    "entries.entry.ui.logo": "Logotipo",
    "entries.entry.ui.longDescription": "Texto descritivo longo",
    "entries.entry.ui.mainImage": "Foto principal",
    "entries.entry.ui.makeListImage": "Defina esta imagem como a imagem de lista",
    "entries.entry.ui.makeListImageTooltip": "Os resultados da busca são exibidos como uma lista com a figura da lista. Se nenhuma foto da lista for selecionada, a foto principal é utilizada.",
    "entries.entry.ui.makeMainImage": "Defina esta imagem como a imagem principal",
    "entries.entry.ui.makeMainImageTooltip": "A imagem principal é exibida na parte superior do perfil. As imagens que não são selecionadas como imagem principal ou de lista são exibidas na galeria de imagens.",
    "entries.entry.ui.metaDescription": "Breve descrição",
    "entries.entry.ui.metaDescriptionPlaceholder": "Breve descrição com um máximo de 200 caracteres",
    "entries.entry.ui.multiCode": "Código adicional para esta etapa",
    "entries.entry.ui.multiDescription": "Descrição desta etapa",
    "entries.entry.ui.multiImages": "Imagens para esta etapa",
    "entries.entry.ui.multiProgress": "Progresso",
    "entries.entry.ui.multiTitle": "Título para esta etapa",
    "entries.entry.ui.multiTitlePlaceholder": "Título para esta etapa (máximo 50 caracteres)",
    "entries.entry.ui.noDescriptions": "A entrada ainda não tem texto descritivo.",
    "entries.entry.ui.noEntries": "Nenhuma entrada encontrada.",
    "entries.entry.ui.onlyVisibleForCustomers": "Esta entrada é visível apenas para clientes registrados",
    "entries.entry.ui.openAt": "Aberto",
    "entries.entry.ui.openingHours": "Horário de abertura",
    "entries.entry.ui.openingInfo": "Notas sobre o horário de abertura",
    "entries.entry.ui.openingReligious": "Endereço na paróquia predominantemente católica",
    "entries.entry.ui.overallProgress": "Esta entrada é {{value}}% realizado",
    "entries.entry.ui.parking": "Estacionamentos",
    "entries.entry.ui.parkingLots": "Número",
    "entries.entry.ui.parkingType": "Arte",
    "entries.entry.ui.personImage": "Foto da pessoa de contato",
    "entries.entry.ui.relatedEvents": "Eventos associados",
    "entries.entry.ui.relatedJobs": "Trabalhos interligados",
    "entries.entry.ui.relatedArticles": "Artigos associados",
    "entries.entry.ui.relatedCampaigns": "Campanhas associadas",
    "entries.entry.ui.rentKiosk": "Eu gostaria de alugar um estande de vendas",
    "entries.entry.ui.resolveAddress": "Determinar coordenadas",
    "entries.entry.ui.resolveAddressInfo": "Favor determinar o geoponto (posição no mapa) a partir de seu endereço. Sem o geoponto correto, eles podem não ser encontrados corretamente.",
    "entries.entry.ui.resolveGeolocation": "Obter endereço",
    "entries.entry.ui.routingPlaceholder": "Digite aqui suas instruções com HTML.",
    "entries.entry.ui.selectEvent": "Selecione o evento",
    "entries.entry.ui.selectJob": "Selecione o trabalho",
    "entries.entry.ui.selectArticle": "Selecione o artigo",
    "entries.entry.ui.selectCampaign": "Selecione a campanha",
    "entries.entry.ui.sharing": "Esta página em {{sharing}} share",
    "entries.entry.ui.shareMail": "Compartilhe esta página pelo correio",
    "entries.entry.ui.shortDescriptionInfo": "É, ao mesmo tempo, a breve descrição",
    "entries.entry.ui.skillsNeeded": "Eu poderia fazer bom uso dela para minha entrada....",
    "entries.entry.ui.sortword": "Títulos alternativos para a triagem",
    "entries.entry.ui.step": "Etapa",
    "entries.entry.ui.stepInDream": "Em que fase da entrada se encontra esta etapa?",
    "entries.entry.ui.stepInDreamAfter": "após a realização",
    "entries.entry.ui.stepInDreamBefore": "antes da realização",
    "entries.entry.ui.stepInDreamDuring": "durante a realização",
    "entries.entry.ui.subtitle": "Subtítulo descritivo",
    "entries.entry.ui.subtitlePlaceholder": "Digite o subtítulo (máximo 60 caracteres)",
    "entries.entry.ui.tabCategories": "Categorias",
    "entries.entry.ui.tabCategoriesMobile": "Gato.",
    "entries.entry.ui.tabCompany": "Dados da empresa",
    "entries.entry.ui.tabDescription": "Descrição",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Detalhes do expositor",
    "entries.entry.ui.tabImages": "Imagens/Vídeos/Documentos",
    "entries.entry.ui.tabImagesMobile": "Imagens/Vídeos/Arquivos",
    "entries.entry.ui.tabRelations": "Conexões",
    "entries.entry.ui.tags": "Etiquetas",
    "entries.entry.ui.title": "A entrada aparece com o seguinte nome",
    "entries.entry.ui.titleError": "A entrada deve ser nomeada antes de salvar",
    "entries.entry.ui.titlePlaceholder": "Digite o título (máximo 50 caracteres)",
    "entries.entry.ui.visible": "Esta entrada é visível",
    "entries.entry.ui.promotion": "Participação em ações para este registro",
    "entries.entry.ui.regions": "Categorias de região para esta entrada",
    "entries.job.ui.promotion": "Participação em ações para este trabalho",
    "entries.event.edit": "Evento <strong>{{title}}</strong> editar",
    "entries.event.ui.addEvent": "Adicionar evento",
    "entries.event.ui.cooperation": "Parceiro de cooperação",
    "entries.event.ui.directions": "Instruções",
    "entries.event.ui.editEvent": "Editar evento",
    "entries.event.ui.editEventNew": "Criar evento",
    "entries.event.ui.endDate": "Fim do evento (dia)",
    "entries.event.ui.eventDone": "Relatório da feira de negócios",
    "entries.event.ui.noEvents": "Nenhum evento encontrado",
    "entries.event.ui.openEvent": "Evento de chamada",
    "entries.event.ui.opening": "Horário de abertura",
    "entries.event.ui.startDate": "Início do evento (dia)",
    "entries.event.ui.tabCategories": "Categorias",
    "entries.event.ui.tabDescription": "Descrição",
    "entries.event.ui.tabOpening": "Data e horários",
    "entries.event.ui.tabRelations": "Conexões",
    "entries.event.ui.title": "O evento aparece com os seguintes nomes",
    "entries.event.ui.upcoming": "Em poucas palavras",
    "entries.event.ui.current": "Atualmente ocorrendo",
    "entries.event.ui.history": "Já foi realizado",
    "entries.open": "Abrir a entrada no frontend",
    "entries.person.ui.company": "Empresa",
    "entries.person.ui.female": "Sra.",
    "entries.person.ui.diverse": "Sem",
    "entries.person.ui.fullName": "Nome e sobrenome",
    "entries.person.ui.fullSize": "Tamanho completo",
    "entries.person.ui.gender": "Gênero",
    "entries.person.ui.greeting": "Saudação",
    "entries.person.ui.male": "Sr.",
    "entries.person.ui.open": "Diretamente para a entrada desta pessoa",
    "entries.person.ui.personAddress": "Endereço da pessoa",
    "entries.person.ui.personContact": "Dados pessoais de contato",
    "entries.person.ui.personImage": "Foto pessoal",
    "entries.person.ui.position": "Posição",
    "entries.person.ui.quote": "Citação/captação",
    "entries.person.ui.signature": "Imagem da assinatura",
    "entries.poi.ui.poiEvents": "Eventos neste local",
    "entries.poi.ui.title": "O local aparece com os seguintes nomes",
    "entries.product.edit": "Produto <strong>{{title}}</strong> editar",
    "entries.product.fields.engine": "Motor",
    "entries.product.fields.horsePower": "Energia",
    "entries.product.menu.allAttachments": "Todos os anexos em um relance",
    "entries.product.menu.allCarriers": "Todos os veículos de transporte na visão geral",
    "entries.product.menu.attachments": "Anexos",
    "entries.product.menu.carriers": "Veículos transportadores",
    "entries.product.tags.eco": "eco",
    "entries.product.tags.neu": "novo",
    "entries.product.ui.addProduct": "Adicionar produto",
    "entries.product.ui.choose": "selecione",
    "entries.product.ui.discount": "Desconto",
    "entries.product.ui.discountSelect": "Vantagem do desconto de uso",
    "entries.product.ui.equipmentVariants": "Variantes do equipamento",
    "entries.product.ui.variants": "Variantes",
    "entries.product.ui.gauge": "Largura em cm",
    "entries.product.ui.gaugeMin": "Largura mínima da via em cm",
    "entries.product.ui.inclusive": "Incluindo",
    "entries.product.ui.live": "{{name}} experiência ao vivo",
    "entries.product.ui.netPrize": "incluindo {{tax}}% IVA, mais",
    "entries.product.ui.netPrizeDefault": "incluindo o imposto sobre o valor agregado estatutário, mais",
    "entries.product.ui.deliveryTerms": "Custos de envio",
    "entries.product.ui.noProducts": "Nenhum produto encontrado",
    "entries.product.ui.noPurchaseOnThisPage": "Não pode ser encomendado nesta página, chame o produto para isso.",
    "entries.product.ui.onlyBasicVariant": "Somente em equipamentos básicos",
    "entries.product.ui.onlyWithCarrier": "Disponível apenas com a compra de uma unidade básica",
    "entries.product.ui.order": "Adicionar ao carrinho de compras",
    "entries.product.ui.notOrderable": "Por favor, selecione uma variante.",
    "entries.entry.ui.products.delivery.red": "Produto atualmente não disponível",
    "entries.entry.ui.products.delivery.yellow": "Produto em estoque. Possivelmente, um prazo de entrega mais longo.",
    "entries.entry.ui.products.delivery.green": "Produto em estoque em quantidade suficiente",
    "entries.products.ui.combinationNotAvailable": "Produto não disponível nesta combinação",
    "product.ui.content": "Conteúdo",
    "product.ui.ingredients": "Conteúdo",
    "product.ui.ingredientsFood": "Ingredientes",
    "entries.product.ui.overview": "Visão geral",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "de",
    "entries.product.ui.noPrize": "Consulte a pessoa de contato para saber o preço.",
    "entries.product.ui.item": "Peça",
    "entries.product.ui.prizePerItem": "Preço por peça",
    "entries.product.ui.size": "Tamanho",
    "entries.product.ui.required": "Somente com",
    "entries.product.ui.suitableFor": "Adequado para",
    "entries.product.ui.suitableForAttachments": "Adequado para os seguintes anexos",
    "entries.product.ui.suitableForCarriers": "Adequado para os seguintes veículos de transporte",
    "entries.product.ui.title": "Produto",
    "entries.product.ui.variant": "Variante/Bundle",
    "entries.product.ui.variantNotOrderable": "A variante selecionada não pode ser encomendada",
    "entries.product.ui.inVariants": "para variantes de produtos",
    "entries.product.ui.bundleparts": "Composto por",
    "entries.progress.title": "Como melhorar sua entrada",
    "entries.tabbar.basics": "Dados básicos",
    "entries.tabbar.entry": "Dados de entrada",
    "entries.tabbar.gallery": "Imagens",
    "entries.tabbar.layout": "Layout",
    "entries.tabbar.openingHours": "Horário de abertura",
    "entries.tabbar.order": "Informações para pedidos",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Os dados de entrada só podem ser editados depois que a entrada tiver sido salva com um título.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Os dados de layout só podem ser editados depois que a entrada tiver sido salva com um título.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Padrão",
    "entries.ui.groupDetailName": "Nome para as entradas de grupo",
    "entries.ui.groupName": "Nome para os grupos (região)",
    "entryTypes.entry": "Entrada",
    "entryTypes.event": "Evento",
    "entryTypes.poi": "Local",
    "errors.blank": "{{description}} deve ser preenchido",
    "errors.description": "Este campo",
    "errors.email": "Por favor, digite um {{description}}-endereço",
    "errors.empty": "{{description}} não deve estar vazio",
    "errors.exclusion": "Este {{description}} já é tomada",
    "errors.inclusion": "Este {{description}} não está incluído na seleção",
    "errors.invalid": "{{description}} é inválido",
    "errors.mandatory": "{{field}} deve ser preenchido",
    "errors.mandatoryCheckbox": "Deve ser confirmado.",
    "errors.mandatorySubmit": "Você só pode enviar o formulário quando todos os campos obrigatórios tiverem sido preenchidos.",
    "errors.slug": "A lesma pode conter apenas letras minúsculas, números e \"-\".",
    "forms.ui.deleteFile": "Excluir arquivo",
    "forms.ui.formErr": "Erro ao enviar o formulário",
    "forms.ui.formSent": "Formulário enviado",
    "forms.ui.noForm": "Nenhum formulário designado",
    "forms.ui.invalidMail": "Por favor, digite um endereço de e-mail válido.",
    "forms.ui.isSubmitted": "O formulário foi enviado. Entraremos em contato com você o mais rápido possível.",
    "forms.ui.missingFields": "Os seguintes campos devem ser preenchidos:",
    "forms.ui.uploadSuccess": "{{file}} foi carregado",
    "forms.ui.fieldBuilder.street": "Rua",
    "forms.ui.fieldBuilder.houseNumber": "Não.",
    "forms.ui.fieldBuilder.zip": "POSTCODE",
    "forms.ui.fieldBuilder.city": "Cidade",
    "forms.ui.fieldBuilder.country": "País",
    "forms.ui.fieldBuilder.firstName": "Primeiro nome",
    "forms.ui.fieldBuilder.lastName": "Sobrenome",
    "forms.ui.privacyRead": "Tomei nota da política de privacidade",
    "forms.ui.privacy": "Política de privacidade",
    "jobs.ui.employmentType": "Tipo de trabalho",
    "jobs.ui.employmentType.FULL_TIME": "Em tempo integral",
    "jobs.ui.employmentType.PART_TIME": "Part-time",
    "jobs.ui.employmentType.APPRENTICE": "Treinamento",
    "jobs.ui.employmentType.INTERN": "Estágio",
    "jobs.ui.employmentType.CONTRACTOR": "Trabalho autônomo",
    "jobs.ui.employmentType.TRAINEE": "Estagiário, estudo em tempo parcial",
    "jobs.ui.employmentType.VOLUNTEER": "Voluntário",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Vagas disponíveis em",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "A partir de agora",
    "jobs.ui.startFrom": "De",
    "media.documents.search.noResult": "Favor inserir um número de série ou selecionar uma categoria.",
    "media.documents.search.placeholder": "Pesquisa por número de série da unidade ou número do tipo",
    "media.documents.search.reset": "Reiniciar resultado da busca",
    "media.ui.copyright": "Direitos autorais",
    "media.ui.cropFormat": "Especificação do formato",
    "media.ui.cropImage": "Corte de cabelo",
    "media.ui.cropImageDuplicate": "Duplicar e cortar",
    "media.ui.description": "Descrição",
    "media.ui.filename": "Nome do arquivo",
    "media.ui.imageSaved": "Imagem salva",
    "media.ui.processingImage": "A imagem está sendo cortada, por favor aguarde",
    "media.ui.resetCrop": "Reposição do corte",
    "media.ui.resetImage": "Voltar para o original",
    "media.ui.rotateImage": "Rodar a imagem 90° no sentido horário",
    "media.ui.title": "Título",
    "name": "Nome",
    "openingHours.additionalInfo": "Informações sobre o horário de abertura",
    "openingHours.addOpening": "Adicionar tempo de abertura",
    "openingHours.date.day.di": "Ter",
    "openingHours.date.day.do": "Faça",
    "openingHours.date.day.fr": "Sex",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Sábado",
    "openingHours.date.day.so": "Então",
    "openingHours.dateS": "Data",
    "openingHours.delete": "Excluir",
    "openingHours.from": "de",
    "openingHours.options": "Área",
    "openingHours.time": "Hora",
    "openingHours.to": "para",
    "openingHours.type": "Tipo de tempo de abertura",
    "opt-in.error-can-not-update-member": "As mudanças nos dados dos membros para {{email}} não poderia ser realizado.",
    "opt-in.error-list-does-not-exist": "Nenhuma lista foi encontrada para as datas indicadas.",
    "opt-in.error-list-does-not-need-validation": "A lista especificada não tem opt-int.",
    "opt-in.error-member-not-in-list": "Nenhum membro de uma lista foi encontrado para as datas dadas.",
    "opt-in.success-member-verified": "O {{email}} foi adicionado ao {{listname}} adicionados à lista.",
    "opt-out.error-can-not-update-member": "As mudanças nos dados dos membros para {{email}} não poderia ser realizado.",
    "opt-out.error-list-does-not-exist": "Nenhuma lista foi encontrada para as datas indicadas.",
    "opt-out.error-list-does-not-have-opt-out": "A lista especificada não tem um opt-out.",
    "opt-out.error-member-not-in-list": "Nenhum membro de uma lista foi encontrado para as datas dadas.",
    "opt-out.success-membership-terminated": "O {{email}} foi removido do {{listname}} removido.",
    "password.errors.not-secure-enough": "Recomendamos uma senha com um comprimento mínimo de 8 caracteres, letras maiúsculas e minúsculas, números e caracteres especiais ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "As duas senhas devem ser idênticas.",
    "password.quality.0": "Muito ruim",
    "password.quality.1": "Ruim",
    "password.quality.2": "Suficiente",
    "password.quality.3": "Bom",
    "password.quality.4": "Muito Bom",
    "profile.entries.copycats": "Entradas que {{userName}} também quer fazer",
    "profile.entries.donates": "Entradas que {{userName}} apoia",
    "profile.entries.entries": "Entradas de {{userName}}",
    "profile.entries.follows": "Entradas que {{userName}} segue",
    "profile.entries.joins": "Entradas nas quais {{userName}} participa",
    "profile.marketplace.fields.energyRequired": "Demanda de eletricidade",
    "profile.marketplace.fields.livingAnimals": "Levando animais vivos",
    "profile.marketplace.fields.loadingUnloading": "Necessário para a chegada/partida",
    "profile.marketplace.fields.maxRange": "Pela minha oferta já estou a tantos km de carro",
    "profile.marketplace.fields.placeRequired": "Requisitos de inclinação",
    "profile.marketplace.fields.securityRequired": "Necessidade de segurança",
    "profile.marketplace.fields.shows": "Oferta de programa",
    "profile.marketplace.fields.spaceRequired": "Necessidade de espaço em m²",
    "profile.marketplace.fields.waterRequired": "Demanda de água em m³",
    "profile.marketplace.new": "Nova oferta ou necessidade",
    "profile.marketplace.offer.delete": "Excluir",
    "profile.marketplace.offer.description": "Descrição",
    "profile.marketplace.offer.descriptionPlaceholder": "Descrição significativa, para que outro usuário possa fazer algo com ela.",
    "profile.marketplace.offer.new": "Criar nova oferta",
    "profile.marketplace.offer.relatedEntry": "Entrada designada",
    "profile.marketplace.offer.tags": "Etiquetas para oferta/pedido (serão encontradas melhor)",
    "profile.marketplace.offer.title": "Eu ofereço",
    "profile.marketplace.request.delete": "Excluir solicitação",
    "profile.marketplace.request.new": "Criar nova solicitação",
    "profile.marketplace.request.missingCredentials": "Você só pode iniciar uma consulta quando tiver criado pelo menos um local e um ponto de medição.",
    "profile.marketplace.request.title": "Eu preciso de",
    "profile.messages.approve": "Confirme",
    "profile.messages.as": "como",
    "profile.messages.commentPre": "tem",
    "profile.messages.commentSuff": "comentários",
    "profile.messages.decided": "decidiu",
    "profile.messages.deletedUser": "Usuário excluído",
    "profile.messages.donateDream": "Muito obrigado por ajudar este participante a realizar sua entrada. Ele receberá sua mensagem imediatamente e esperançosamente entrará em contato com você e aceitará sua ajuda.",
    "profile.messages.dreamer": "Participante",
    "profile.messages.dreamToo": "também para sonhar",
    "profile.messages.enter": "juntou-se a",
    "profile.messages.favoriteDream": "Você está acompanhando esta entrada agora.",
    "profile.messages.follow": "segue",
    "profile.messages.has": "tem",
    "profile.messages.is": "é",
    "profile.messages.join": "ENCONTRE",
    "profile.messages.joinDream": "Você quer sonhar junto com esta entrada.",
    "profile.messages.likeDream": "Você gosta desta entrada agora.",
    "profile.messages.sendText": "Sua mensagem foi enviada.",
    "profile.messages.shareDream": "Você compartilhou com sucesso esta entrada.",
    "profile.messages.supporter": "Apoiadores",
    "profile.messages.want": "gostaria de",
    "profile.messages.youAre": "Você é",
    "profile.messages.youWant": "Você gostaria de",
    "profile.newsletter.notSubscribed": "subscreva",
    "profile.newsletter.notSubscribedAny": "Você ainda não se inscreveu em uma lista de newsletter.",
    "profile.newsletter.privacyInformation": "Ao clicar em \"assinar\" você concorda que nós lhe enviaremos futuros boletins informativos desta assinatura (opt-in). Você pode cancelar a assinatura do boletim a qualquer momento clicando em \"assinar\" novamente (opt-out).",
    "profile.newsletter.subscribed": "subscrito",
    "profile.press.articleDetail": "Detalhe do artigo",
    "profile.press.downloadImage": "Baixar imagem",
    "profile.press.downloadImages": "Baixar imagens",
    "profile.press.downloadText": "Exportar texto",
    "profile.press.filterAuthor": "Filtrar por autor",
    "profile.press.hide": "esconder",
    "profile.press.show": "desbotar em",
    "profile.progress.title": "Como melhorar seu perfil",
    "register.ui.addressData": "Dados de endereço",
    "register.ui.addressInfo": "Eles não têm que entrar em seu endereço pessoal. Entretanto, isto leva a cálculos corretos de rota e distância.",
    "register.ui.companyData": "Detalhes de sua empresa",
    "register.ui.companyDataMandatory": "Você deve digitar o nome de sua empresa",
    "register.ui.emptyFirstName": "Você deve digitar seu primeiro nome",
    "register.ui.emptyLastName": "Você deve digitar seu sobrenome",
    "register.ui.emptyMail": "Você deve fornecer um endereço de e-mail válido",
    "register.ui.emptyUserName": "Você deve especificar um nome de usuário",
    "register.ui.language": "Idioma",
    "register.ui.loginData": "Dados de login",
    "register.ui.password": "Senha",
    "register.ui.passwordRepeat": "Repita a senha",
    "register.ui.personalData": "Seus dados pessoais",
    "register.ui.privacyStatement": "Eu autorizo que meus dados pessoais sejam armazenados. Meus dados pessoais não serão repassados a terceiros. Posso revogar este consentimento de proteção de dados a qualquer momento, com efeito para o futuro.",
    "register.ui.takenEmail": "Este e-mail já foi especificado por um usuário.",
    "register.ui.takenUserName": "Este nome de usuário já está ocupado",
    "register.ui.timezone": "Fuso horário",
    "register.ui.typeMinimum": "Você deve selecionar pelo menos um tipo",
    "register.ui.usernameInfo": "Importante: Este é seu nome de usuário para o login",
    "register.ui.oauthInfo": "Você será redirecionado para a página de login da FRM para entrar com seus dados de usuário e depois retornar ao site.",
    "search.allDistances": "Todas as distâncias",
    "search.andFilter": "e configurações de filtros",
    "search.closeTags": "Ocultar etiquetas",
    "search.for": "para",
    "search.zipCountries": "Códigos postais de",
    "search.resetPosition": "Redefinir posição",
    "search.geoPositionBrowser": "Determinar a posição através do navegador",
    "search.geoPositionFault": "A determinação da posição pelo navegador não é possível",
    "search.geoPositionTitle": "Defina a posição para uma busca de proximidade",
    "search.selectedFilters": "Filtros selecionados",
    "search.selectFilters": "Filtrar busca por categorias",
    "search.hits": "Acesse",
    "search.openTags": "Mostrar etiquetas",
    "search.removeDatefilter": "Remover o filtro de data",
    "search.resulted": "revelado",
    "search.resultType.category": "Categoria",
    "search.resultType.entry": "Entrada",
    "search.resultType.event": "Evento",
    "search.resultType.group": "Grupo",
    "search.resultType.page": "Página",
    "search.resultType.person": "Pessoa",
    "search.resultType.post": "Correio",
    "search.resultType.product": "Produto",
    "search.resultType.article": "Artigo",
    "search.resultType.job": "Emprego",
    "search.resultType.recipe": "Receita",
    "search.search": "Busca",
    "search.result": "Resultado",
    "search.results": "Resultados",
    "search.searchBarPlaceholder": "Pesquisar...",
    "search.searchBarPlaceholderEntries": "Pesquisar por nome ou endereço do revendedor.",
    "search.searchBarPlaceholderEntriesPosition": "Busca perimetral com localização própria (por exemplo, cidade).",
    "search.searchBarPlaceholderEntriesPositionZip": "...ou código postal",
    "search.searchBarPlaceholderGeoSearch": "Digite seu endereço...",
    "search.searchBarPlaceholderMobile": "Pesquisar...",
    "search.searchBarPlaceholderProducts": "Buscar produtos",
    "search.searchBarPositionTooltip": "Determinar posição ou digitar local com endereço",
    "search.showAll": "Mostrar tudo",
    "search.globalSearch": "Página de busca",
    "social.comment": "Comentário",
    "social.Comment": "Comentário",
    "social.CopycatJoiner": "Sonhe com sua entrada",
    "social.dream.donateAmount": "Apoio com uma soma de dinheiro",
    "social.dream.donateDreampoints": "Apoio com Dreampoints",
    "social.dream.donateInfo": "A mensagem é privada e só pode ser lida pelo proprietário da entrada.",
    "social.dream.donateTip": "Apoio com conselhos e ações",
    "social.dream.done": "FEITO",
    "social.dream.edit": "Editar entrada",
    "social.dream.findDreams": "Encontre aqui entradas pequenas e grandes e torne sua entrada real e/ou ajude outros a realizar suas entradas.",
    "social.dream.followedDreams": "As entradas que você segue",
    "social.dream.giveEmail": "Compartilhar meu endereço de e-mail",
    "social.dream.givePhone": "Compartilhar meu número de telefone",
    "social.dream.joinerApproved": "Você está participando desta entrada",
    "social.dream.joinerButton": "Junte-se a esta entrada",
    "social.dream.joinerButtonMobile": "Participe",
    "social.dream.joinerDream": "O sonho do grupo",
    "social.dream.joinerInfo": "Se o proprietário da entrada confirmar sua participação, esta entrada se torna uma sala de grupo onde você tem mais direitos.",
    "social.dream.joinerMessage": "Digite aqui sua mensagem para o proprietário da entrada:",
    "social.dream.joinerPending": "O proprietário da entrada ainda tem que confirmar sua participação.",
    "social.dream.joinerSupport": "Você apóia esta entrada",
    "social.dream.joinerSupportButton": "Apoiar esta entrada",
    "social.dream.joinerSupportButtonMobile": "Apoio",
    "social.dream.joinerSupportInfo": "Estamos constantemente trabalhando em melhorias, tais como o apoio com dinheiro ou pontos de entrada. Fique atento.",
    "social.dream.joinerSupportPending": "O proprietário da entrada ainda deve permitir seu apoio",
    "social.dream.member": "Membro",
    "social.dream.members": "Membros",
    "social.dream.meToo": "Eu também quero fazer isso",
    "social.dream.meTooInfo": "Mostre ao proprietário da entrada que você acha que a entrada é ótima e que você quer fazer esta entrada.",
    "social.dream.noFollow": "Você não está seguindo nenhuma entrada!",
    "social.dream.noJoiner": "Você ainda não é um membro de um sonho de grupo",
    "social.dream.noLikes": "Você ainda não gosta de nenhuma entrada!",
    "social.dream.noSupport": "Você não apóia uma entrada",
    "social.dream.registerFirstText": "Para poder compartilhar uma entrada, para apoiar a entrada ou para indicar que você também gostaria de fazer esta entrada, você deve estar logado. O registro com o compartilhamento de sua entrada é rápido e gratuito. Basta clicar no botão \"Register\" (registrar).",
    "social.dream.registerFirstTitle": "Sonhando junto, apoiando, até mesmo fazendo?",
    "social.dream.settings": "Configurações de entrada",
    "social.dream.shareTitle": "Compartilhe o sonho",
    "social.dream.similar": "Entradas similares",
    "social.dreamline.addImageToDream": "Adicione esta imagem à sua entrada",
    "social.dreamline.addMedia": "Adicionar imagem / vídeo",
    "social.dreamline.allActivities": "Todas as atividades da Dreamline",
    "social.dreamline.anonymous": "Anônimo",
    "social.dreamline.commentButton": "Comentário",
    "social.dreamline.comments": "escreve:",
    "social.dreamline.depublished": "Este comentário foi retirado por comportamento inapropriado.",
    "social.dreamline.dreampointsInfo": "Estes são os pontos de sonho da entrada. Você pode encontrar mais sobre Dreampoints em seu perfil.",
    "social.dreamline.fewDreampoints": "tem apenas alguns pontos de sonho. Comente, goste ou compartilhe a entrada.",
    "social.dreamline.filterBySteps": "Filtrar por etapas",
    "social.dreamline.filterComment": "Comentários",
    "social.dreamline.filteredBy": "Atividades filtradas por",
    "social.dreamline.filteredBySteps": "Filtrado por etapas",
    "social.dreamline.filterFavorite": "Segue",
    "social.dreamline.filterLike": "Gosta",
    "social.dreamline.filterShare": "Ações",
    "social.dreamline.filterStep": "Passos",
    "social.dreamline.flagged": "Este posto foi relatado",
    "social.dreamline.isComment": "comentários",
    "social.dreamline.isCopycatJoiner": "também sonho",
    "social.dreamline.isFirstUpdate": "criado",
    "social.dreamline.isShare": "compartilhado",
    "social.dreamline.isStep": "deu um novo passo",
    "social.dreamline.isUpdate": "atualizado",
    "social.dreamline.lastEntry": "Última atualização de entrada",
    "social.dreamline.locked": "Este conteúdo só é visível para os outros sonhadores",
    "social.dreamline.lockedCTA": "Junte-se a esta entrada agora",
    "social.dreamline.noMessages": "Esta entrada ainda não tem atividade. Escreva o primeiro comentário agora.",
    "social.dreamline.noMessagesFiltered": "Nenhuma atividade com estas configurações de filtro para esta entrada.",
    "social.dreamline.nowFollower": "segue esta entrada!",
    "social.dreamline.nowJoiner": "é agora um apoiante",
    "social.dreamline.nowLikes": "como esta entrada agora!",
    "social.dreamline.nowSharing": "compartilharam esta entrada!",
    "social.dreamline.onlyJoinerVisible": "Somente visível para os companheiros sonhadores/apoiadores",
    "social.dreamline.prefixComment": "tem esta entrada antes",
    "social.dreamline.prefixCopycatJoiner": "gostaria de acrescentar esta entrada uma vez que",
    "social.dreamline.prefixFavorite": "tem seguido esta entrada desde",
    "social.dreamline.prefixJoiner": "apóia esta entrada desde",
    "social.dreamline.prefixLike": "gosta desta entrada desde",
    "social.dreamline.prefixShare": "tem esta entrada antes",
    "social.dreamline.prefixStep": "tem antes",
    "social.dreamline.prefixUpdate": "tem esta entrada antes",
    "social.dreamline.profile": "Perfil",
    "social.dreamline.provideImage": "Eu coloco esta foto à disposição do proprietário da entrada",
    "social.dreamline.resetFilter": "Reiniciar o filtro",
    "social.dreamline.sendComment": "Enviar",
    "social.dreamline.sharePart": "Compartilhe",
    "social.dreamline.toggleStream": "Comentários",
    "social.dreamline.toggleStreamSingle": "Comentário",
    "social.dreamline.writeComment": "Escreva um comentário...",
    "social.Favorite": "Siga",
    "social.follow": "Siga",
    "social.Follow": "Siga",
    "social.hasLiked": "Você já gosta desta entrada.",
    "social.join": "Junte-se a",
    "social.Join": "Junte-se a",
    "social.Joiner": "Sonhando junto",
    "social.like": "Como",
    "social.Like": "Como",
    "social.report.headlinePrefix": "Você está lá",
    "social.report.headlineSuffix": "para relatar.",
    "social.report.login": "Você deve estar logado para informar o posto.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Outros conteúdos ofensivos",
    "social.report.reportScam": "Desvirtuamento, bullying.",
    "social.report.reportSex": "Conteúdo sexual",
    "social.report.reportSpam": "Isto é spam.",
    "social.report.reportViolence": "Representação da violência",
    "social.report.send": "Enviar mensagem",
    "social.report.title": "Relatório",
    "social.share": "Compartilhe",
    "social.Share": "Compartilhe",
    "syd.ui.dreamerCommercial": "Padrão B2B",
    "syd.ui.userCommercial": "Sonhador B2B",
    "user.company.addUser": "Criar usuários adicionais para",
    "user.company.inviteUser": "Enviar convite por correio",
    "user.company.userIsInvited": "E-mail de convite foi enviado",
    "user.company.userIsActive": "O usuário está ativo",
    "user.company.userIsNotActive": "O usuário ainda não está ativado",
    "user.company.additionalWebsite": "Outro website da empresa (somente domínio sem https://)",
    "user.company.companyName": "Nome da empresa",
    "user.company.contactFor": "Detalhes de contato para",
    "user.company.contactForDefault": "Contato geral",
    "user.company.copyContact": "Assumir os dados de contato da empresa para a entrada",
    "user.company.email": "E-mail de contato",
    "user.company.facebook": "Conta no Facebook da empresa (apenas nome da conta)",
    "user.company.fax": "Número de fax para contato por escrito",
    "user.company.google": "Conta Google+ da empresa (apenas nome da conta)",
    "user.company.instagram": "Instagram conta da empresa (apenas nome da conta)",
    "user.company.linkedin": "LinkedIn conta da empresa (apenas nome da conta)",
    "user.company.newCustomerAccount": "Criar uma conta comercial",
    "user.company.noCustomerValidation": "Sua conta comercial ainda não foi ativada.",
    "user.company.noCustomerYet": "Seu registro ainda não foi atribuído a uma conta comercial.",
    "user.company.notAllowed": "Você não pode fazer nenhuma mudança porque seu status de usuário ({{type}}) não permite isso.",
    "user.company.phone": "Número de telefone de contato",
    "user.company.pinterest": "Conta de juros da empresa (somente nome da conta)",
    "user.company.remarks": "Notas",
    "user.company.registerNumber": "Número de registro comercial",
    "user.company.registerAuth": "Tribunal local",
    "user.company.acerCode": "Código ACER do seu fornecimento de energia",
    "user.company.taxNumber": "Número do imposto",
    "user.company.type.default": "Padrão",
    "user.company.type.procurator": "Signatário autorizado",
    "user.company.type.manager": "Diretor Administrativo",
    "user.company.twitter": "Conta da empresa no Twitter (somente nome da conta sem @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Favor fornecer um número de identificação VAT válido<br>(se disponível)</br>",
    "user.company.website": "Site da empresa (somente domínio sem https://)",
    "user.company.xing": "Xing conta da empresa (apenas nome da conta)",
    "user.company.youtube": "Conta da empresa no Youtube (apenas nome da conta)",
    "user.message.after-save-error": "Suas mudanças não puderam ser salvas.",
    "user.message.after-save-success": "Suas mudanças foram salvas.",
    "user.message.can-not-login": "Não podemos fazer o login com estas entradas de usuário e senha! O nome de usuário está escrito corretamente? Você digitou a senha corretamente? Algo pode ter dado errado durante o registro.",
    "user.message.can-not-logout": "Nós não podíamos assinar a sua saída!",
    "user.message.conflict": "Este nome de usuário já existe, escolha outro!",
    "user.message.fileExistsError": "O upload falhou. O arquivo já existe",
    "user.message.fileSizeError": "O upload falhou. O arquivo é muito grande",
    "user.message.loginFailureBottom": "Talvez você ainda não tenha uma conta Entryer?",
    "user.message.loginFailureTop": "O seu nome de usuário está escrito corretamente? Você digitou sua senha corretamente?",
    "user.message.registration-error": "Ocorreu um erro durante seu registro!",
    "user.message.registration-success": "Você deve receber de nós um e-mail de confirmação em breve.",
    "user.message.unknownError": "Erro desconhecido ao fazer o upload do arquivo",
    "user.message.upload-success": "Upload bem-sucedido",
    "user.message.verify-error": "Seu endereço de e-mail não pôde ser verificado. Favor entrar em contato com o administrador.",
    "user.message.verify-success": "Seu endereço de e-mail foi confirmado. Agora você pode se registrar:",
    "user.ui.accountGreeting": "Olá,",
    "user.ui.accountMenu": "Página inicial e informações",
    "user.ui.accountMenuActivities": "Atividades",
    "user.ui.accountMenuCompanySettings": "Informações sobre a empresa",
    "user.ui.accountMenuEntries": "Minhas entradas",
    "user.ui.accountMenuEntryPre": "Inscrições,",
    "user.ui.accountMenuEvents": "Meus eventos",
    "user.ui.accountMenuFavorites": "Meus favoritos",
    "user.ui.accountMenuFollows": "Entradas que eu sigo",
    "user.ui.accountMenuImages": "Imagens/Vídeos/Documentos",
    "user.ui.accountMenuJoin": "Os sonhos do meu grupo",
    "user.ui.accountMenuLikes": "Entradas que eu gosto",
    "user.ui.accountMenuMeasuring": "Meus locais e dados de medição",
    "user.ui.accountMenuMessages": "Notícias",
    "user.ui.accountMenuNewsletter": "Minhas assinaturas de newsletter",
    "user.ui.accountMenuPoi": "Meus locais",
    "user.ui.accountMenuPress": "Meus artigos",
    "user.ui.accountMenuProducts": "Meus produtos",
    "user.ui.accountMenuJobs": "Meu emprego oferece",
    "user.ui.accountMenuProfile": "Perfil do usuário",
    "user.ui.accountMenuRatings": "Revisões",
    "user.ui.accountMenuRegistrationData": "Dados de login",
    "user.ui.accountMenuReviews": "Revisões",
    "user.ui.accountMenuSettings": "Configurações",
    "user.ui.accountMenuSkillsOffer": "Eu ofereço...",
    "user.ui.accountMenuSkillsRequest": "Eu preciso...",
    "user.ui.accountMenuOffer": "Minhas ofertas",
    "user.ui.accountMenuRequest": "Meus pedidos",
    "user.ui.accountMenuSkillsSearch": "Pedidos e ofertas",
    "user.ui.accountMenuSubscriptions": "Assinaturas",
    "user.ui.accountMenuSubscritions": "Minha assinatura",
    "user.ui.accountMenuSupport": "Entradas apoiadas",
    "user.ui.accountMenuSupports": "Inscrições que eu apoio",
    "user.ui.accountMenuTender": "Licitações",
    "user.ui.accountMenuOrders": "Minhas ordens",
    "user.ui.accountNoMessages": "Você ainda não recebeu nenhuma mensagem.",
    "user.ui.accountProgressbar": "Seu perfil é {{value}}% completa",
    "user.ui.accountProgressbarEntry": "Sua entrada é {{value}}% completa",
    "user.ui.accountSettings": "Mais configurações",
    "user.ui.accountCookieSettings": "Clique aqui para ajustar suas configurações.",
    "user.ui.accountCookieEssential": "Essencial",
    "user.ui.accountCookieAnalysis": "Dados de análise",
    "user.ui.accountCookieSocial": "Mídias sociais",
    "user.ui.accountCookieReviews": "Revisões",
    "user.ui.accountToolbarLogin": "Log in",
    "user.ui.accountToolbarLoginTooltip": "Cadastre-se gratuitamente e comece a trabalhar",
    "user.ui.accountToolbarLogout": "Sair",
    "user.ui.adminPreview": "Pré-visualização",
    "user.ui.backToLogin": "Voltar para a página de registro",
    "user.ui.birthday": "Data de nascimento",
    "user.ui.cannotLogin": "Não consegue entrar no sistema? Clique aqui para redefinir sua senha.",
    "user.ui.changePassword": "Eu gostaria de mudar minha senha",
    "user.ui.changeUserGroup": "Outras assinaturas",
    "user.ui.city": "Cidade",
    "user.ui.companyName": "Nome da empresa",
    "user.ui.companyNameTooltip": "Importante: Sua entrada também aparecerá sob estes nomes de empresa",
    "user.ui.country": "País",
    "user.ui.createNewAccount": "Criar uma nova conta de usuário",
    "user.ui.dataDelete": "outras entradas. Você quer apagar o arquivo permanentemente? Onde quer que este arquivo seja utilizado, ele é substituído por um espaço reservado.",
    "user.ui.dataUsage": "O arquivo é salvo em",
    "user.ui.dataUsageNone": "O arquivo ainda não está em uso. Você quer apagar o arquivo permanentemente?",
    "user.ui.deleteImage": "Apagar imagem",
    "user.ui.description": "Digite aqui uma descrição geral da pessoa ou empresa",
    "user.ui.documents": "Documentos",
    "user.ui.documentSearch": "Busca de documentos",
    "user.ui.download": "{{title}} baixar",
    "user.ui.email": "Endereço de e-mail",
    "user.ui.fillOutForm": "Para se registrar, todos os campos obrigatórios devem ser preenchidos completamente",
    "user.ui.firstName": "Primeiro nome",
    "user.ui.academicTitle": "Título acadêmico",
    "user.ui.gender": "M/F",
    "user.ui.genderFemale": "feminino",
    "user.ui.genderFemaleSalutation": "Sra.",
    "user.ui.genderMale": "masculino",
    "user.ui.genderMaleSalutation": "Sr.",
    "user.ui.genderDiverse": "diversos",
    "user.ui.genderThird": "Não especificado",
    "user.ui.housenumber": "Não.",
    "user.ui.iAmCustomer": "Eu gostaria de criar uma conta B2B",
    "user.ui.internalInfo": "Há mensagens para você. Clique aqui.",
    "user.ui.lastLogin": "Último registro em",
    "user.ui.languageAvailable": "Disponível em seu idioma",
    "user.ui.languageMissing": "Disponível apenas em",
    "user.ui.lastName": "Sobrenome",
    "user.ui.loginButton": "Log in",
    "user.ui.loginTitle": "Login do usuário",
    "user.ui.memberSince": "Membro desde",
    "user.ui.mobileNumber": "Número de celular",
    "user.ui.myAddedStorage": "Fotos fornecidas",
    "user.ui.myStorage": "Meu diretório",
    "user.ui.noRegisterMessage": "Para uma conta de usuário, favor entrar em contato com os endereços de contato indicados. Você não pode se registrar como usuário.",
    "user.ui.oldPassword": "Senha anterior",
    "user.ui.password": "Senha",
    "user.ui.phoneType": "Telefone",
    "user.ui.phoneTypeMobile": "Móvel",
    "user.ui.phoneTypeLandline": "Telefone fixo",
    "user.ui.pleaseSelect": "Favor selecionar",
    "user.ui.pleaseSelectSalutation": "Por favor, selecione a saudação",
    "user.ui.registerButton": "Configurar conta de usuário agora",
    "user.ui.registerMessage": "Registre-se agora e comece imediatamente. É rápido e fácil.",
    "user.ui.registerTitle": "Novo entre nós?",
    "user.ui.requestPasswordReset": "Solicitar nova senha e excluir senha antiga",
    "user.ui.requestPasswordSuccess": "Você deve receber um e-mail com um link de reset em breve.",
    "user.ui.requestPasswordError": "Erro desconhecido ao redefinir a senha.",
    "user.ui.resetPassword": "Redefinir senha",
    "user.ui.setPassword": "Definir senha",
    "user.ui.resetPasswordPage": "Redefinir sua senha",
    "user.ui.setPasswordPage": "Defina sua senha",
    "user.ui.resetPasswordSuccess": "Sua senha foi redefinida, agora você pode entrar no sistema",
    "user.ui.setPasswordSuccess": "Sua senha foi definida, agora você pode entrar no sistema",
    "user.ui.selectCustomerType": "Você está ... favor selecionar",
    "user.ui.stayLoggedin": "Permanecer logado",
    "user.ui.stayLoggedinInfo": "Se este for um dispositivo acessível ao público, por favor, desative esta opção e faça logout novamente para terminar.",
    "user.ui.street": "Rua",
    "user.ui.salutation": "Saudação",
    "user.ui.title": "Título",
    "user.ui.thankYouForSubscribing": "Obrigado por subscrever nossa newsletter.",
    "user.ui.errorAtSubscribing": "Erro ao assinar o boletim informativo",
    "user.ui.unsubscribe": "sair da sessão",
    "user.ui.unsubscribePage": "Você realmente quer cancelar a assinatura de nosso boletim informativo?",
    "user.ui.uploadDrop": "Para carregar, arraste um arquivo aqui ou",
    "user.ui.uploadSelectFile": "selecione um arquivo",
    "user.ui.uploadToMyStorage": "Carregar novas imagens para meu diretório",
    "user.ui.uploadUserImage": "Selecione a foto do usuário",
    "user.ui.username": "Endereço de e-mail",
    "user.ui.userName": "Nome de usuário",
    "user.ui.visibilityInfo": "Dica: Você decide o que é exibido publicamente a partir de seu perfil! Clique sobre o símbolo do olho para definir a visibilidade de suas entradas:",
    "user.ui.zip": "Código postal",
    "user.ui.phone": "Número de telefone",
    "user.user": "Participante",
    "user.users": "Inscrições",
    "widgets.shoppingCart.wishlist": "Memo e lista de desejos",
    "widgets.shoppingCart.wishlist.empty": "Você ainda não tem nenhum produto em seu bloco de notas e lista de desejos",
    "widgets.shoppingCart.cart": "Carrinho de compras",
    "user.product.addedToFavorites": "O item foi adicionado a seus favoritos",
    "order.status.zero": "Ordem recebida",
    "order.status.one": "Em andamento",
    "order.status.two": "O pedido foi enviado",
    "order.status.three": "Acessado em",
    "order.status.four": "Retornar",
    "order.status.default": "Estado de ocupação desconhecido",
    "country.germany": "Alemanha",
    "country.de": "Alemanha",
    "country.austria": "Áustria",
    "country.at": "Áustria",
    "country.belgium": "Bélgica",
    "country.denmark": "Dinamarca",
    "country.bulgaria": "Bulgária",
    "country.croatia": "Croácia",
    "country.cyprus": "Chipre",
    "country.czechia": "República Tcheca",
    "country.estonia": "Estônia",
    "country.finland": "Finlândia",
    "country.france": "França",
    "country.fr": "França",
    "country.greece": "Grécia",
    "country.hungary": "Hungria",
    "country.ireland": "Irlanda",
    "country.italy": "Itália",
    "country.it": "Itália",
    "country.latvia": "Letônia",
    "country.lithuania": "Lituânia",
    "country.luxembourg": "Luxemburgo",
    "country.lu": "Luxemburgo",
    "country.malta": "Malta",
    "country.netherlands": "Países Baixos",
    "country.poland": "Polônia",
    "country.portugal": "Portugal",
    "country.romania": "Romênia",
    "country.slovakia": "Eslováquia",
    "country.slovenia": "Eslovênia",
    "country.spain": "Espanha",
    "country.sweden": "Suécia",
    "country.norway": "Noruega",
    "country.switzerland": "Suíça",
    "country.ch": "Suíça",
    "country.turkey": "Turquia",
    "country.unitedKingdom": "Reino Unido",
    "country.gb": "Grã-Bretanha",
    "openingHours.tNowClosed": "Agora fechado",
    "openingHours.tOpeningHoursNotKnown": "Horário de abertura adicional não conhecido",
    "openingHours.tNowOpen": "Abrir agora",
    "openingHours.tNowStillOpen": "Agora ainda aberto",
    "openingHours.tNextOpeningHourUnknown": "próxima data de abertura desconhecida",
    "openingHours.tSoonOpenFrom": "em breve aberto novamente a partir de",
    "openingHours.tFrom": "de",
    "openingHours.tUntil": "para",
    "openingHours.tOpenAgainFrom": "abrir novamente a partir de",
    "openingHours.tOpenAgainFrom2": "Abrir novamente em",
    "comparison.fields.measurements": "Missa",
    "comparison.fields.weight": "Peso",
    "application.tag.neu": "Novo",
    "application.tag.sale": "Venda",
    "application.tag.eco": "Eco",
    "application.tag.expired": "expirado",
    "application.tag.sonderrabatt": "Desconto especial",
    "job.application.selectLocation": "Selecione sua localização",
    "job.application.location": "Disponível nas seguintes regiões"
  };
});