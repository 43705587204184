define("webapp/locales/zh/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "为你的作品设置一个主图像。",
    "account.ui.profileBannerImageNew": "为你的个人资料设置一个主图像。",
    "account.ui.register": "注册",
    "account.ui.registeredAs": "您目前注册为",
    "account.ui.personate": "改变用户身份",
    "account.ui.userNameNotEditableTooltip": "你的用户名。你不能改变你所输入的用户名。要改变你的用户名，请联系你的联系人。重要提示：更改后，你必须用新的用户名重新登录。",
    "account.ui.additionalDeliveryAddresses": "你输入了不同的送货地址。",
    "account.ui.editDeliveryAddresses": "编辑送货地址",
    "account.ui.editInvoiceAddress": "编辑公司地址",
    "account.ui.noDeliveryAddresses": "你还没有输入不同的送货地址。",
    "account.ui.addDeliveryAddresses": "添加一个 {{further}}添加一个不同的送货地址",
    "account.ui.addDeliveryAddressTitle": "添加送货地址",
    "account.ui.editDeliveryAddressTitle": "编辑送货地址",
    "account.ui.isDefaultAddress": "这是标准的送货地址",
    "account.ui.saveToApplyChanges": "你必须保存更改，然后你可以，比如说，指定不同的送货地址。",
    "accountTypes.aussteller": "参展商",
    "accountTypes.gastgeber": "宿主",
    "accountTypes.veranstalter": "组织者",
    "application.beta.infotext": "没有创建任何订阅。",
    "application.breadcrumbsHome": "首页",
    "application.month": "每月",
    "application.newsletter.alreadySubscribed": "你已经是《中国新闻周刊》的订阅者了。 {{list}}通讯。你可以在你的账户中访问新闻简报的订阅。",
    "application.newsletter.alreadySubscribedNoList": "你已经注册了通讯",
    "application.newsletter.goToList": "呼叫邮件列表",
    "application.newsletter.subscribeButton": "输入您的电子邮件地址以订阅新闻简报",
    "application.newsletter.subscribeButtonMobile": "电子邮件",
    "application.newsletter.noAccess": "所提供的新闻简报不能用你的权限来订阅。",
    "application.newsletter.subscribe": "订阅",
    "application.newsletter.subscribeList": "您正在订阅本通讯。",
    "application.newsletter.subscribeThanks": "谢谢你订阅我们的通讯。你很快就会收到最新的通讯。",
    "application.newsletter.subscribeThanksOptIn": "感谢你的通讯订阅。你将会收到一封确认邮件，以确认你的选择。",
    "application.newsletter.subscribeWithAccount": "用你当前登录的用户订阅。",
    "application.noCosts": "免费的",
    "application.placeholderImageText": "没有插图",
    "application.searchAllGroups": "在所有",
    "application.searchAllRegions": "在所有的地方",
    "application.ui.access": "访问",
    "application.ui.action": "行动",
    "application.ui.addDocument": "添加文件",
    "application.ui.addRelation": "添加连接",
    "application.ui.addImage": "添加图片",
    "application.ui.addImages": "添加图片",
    "application.ui.addMedia": "添加图片/视频",
    "application.ui.addVideo": "添加视频",
    "application.ui.advancedEdit": "延长",
    "application.ui.all": "全部",
    "application.ui.alreadyFavorite": "这个项目在你的观察清单上",
    "application.ui.makeFavorite": "将此项目添加到您的观察清单中",
    "application.ui.also": "另外",
    "application.ui.alphabetical": "按字母顺序排列",
    "application.ui.and": "和",
    "application.ui.at": "于",
    "application.ui.in": "在",
    "application.ui.atHour": "于",
    "application.ui.hour": "时钟",
    "application.ui.buy": "购买",
    "application.ui.certificate": "证书",
    "application.ui.confirmDeleteEntry": "删除该条目 {{title}}不可撤销地删除？",
    "application.ui.contact": "联系我们",
    "application.ui.count": "数量",
    "application.ui.currentlyActive": "正在运行",
    "application.ui.deleteEntry": "删除",
    "application.ui.discardUnsavedChanges": "放弃修改并继续",
    "application.ui.discardUnsavedChangesMobile": "弃置",
    "application.ui.distance": "距离",
    "application.ui.distant": "移除",
    "application.ui.dreampoints": "梦想点",
    "application.ui.dreampointsAllDreams": "你的作品的梦想点",
    "application.ui.dreampointsDonate": "捐赠的梦想积分",
    "application.ui.dreampointsTooltip": "你可以通过你的参赛作品收集Dreamampoints",
    "application.ui.edit": "变化",
    "application.ui.editDescription": "编辑描述",
    "application.ui.editEntry": "编辑",
    "application.ui.editEvent": "编辑事件",
    "application.ui.editImage": "编辑图片",
    "application.ui.editPoi": "编辑位置",
    "application.ui.editProduct": "编辑产品",
    "application.ui.editProfile": "编辑我的资料",
    "application.ui.editShownProfile": "改变我的个人资料显示",
    "application.ui.entriesFocus": "焦点》中的部分条目。",
    "application.ui.entriesLatest": "焦点》的最新条目。",
    "application.ui.email": "电子邮件",
    "application.ui.for": "为",
    "application.ui.from": "从",
    "application.ui.fullView": "一般观点",
    "application.ui.gallery": "图片",
    "application.ui.galleryImages": "到画廊...",
    "application.ui.gavePoints": "已经给了你 {{points}}点。",
    "application.ui.goToEntry": "呼叫输入",
    "application.ui.goToEvent": "呼叫事件",
    "application.ui.goToPoi": "呼叫地点",
    "application.ui.goToMeasuring": "测量点",
    "application.ui.goToProduct": "呼叫产品",
    "application.ui.hasBeen": "是",
    "application.ui.here": "附近的",
    "application.ui.invalidEmbed": "这个URL不能被嵌入。",
    "application.ui.imprintLink": "对本子页的内容负责并承担法律责任 {{entry}}- 版本说明",
    "application.ui.isActive": "活跃",
    "application.ui.isNotActive": "失能",
    "application.ui.knowMore": "全部 {{aboutPre}}{{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "当前选择的语言",
    "application.ui.language.switchTo": "将语言更改为 ",
    "application.ui.mailBodyPre": "你好，我发现了一些有趣的东西。",
    "application.ui.mailSubject": "我发现了一个有趣的链接。",
    "application.ui.mandatory": "必须填写的字段",
    "application.ui.memberSince": "会员以来",
    "application.ui.mobileRotate": "旋转以获得全屏视图",
    "application.ui.more": "更多",
    "application.ui.name": "命名",
    "application.ui.navigateOneLevelUp": "回到上级文件夹",
    "application.ui.new": "新的",
    "application.ui.newEntry": "创建新条目",
    "application.ui.newEvent": "创建新事件",
    "application.ui.newPoi": "创建新地点",
    "application.ui.newProduct": "创建新产品",
    "application.ui.noEntry": "你还没有创建一个条目。现在就创建你的第一个条目...",
    "application.ui.noEntryProfile": "尚未创建条目。",
    "application.ui.noEvent": "你还没有创建一个事件。现在就创建你的第一个活动...",
    "application.ui.noPoi": "你还没有创建一个位置。现在就创建你的第一个地点...",
    "application.ui.noJob": "你还没有创建一个工作。现在创建你的第一份工作...",
    "application.ui.noProduct": "你还没有创建一个产品。现在就创建你的第一个产品...",
    "application.ui.notEditable": "不能改变",
    "application.ui.nothingFound": "我们无法找到该搜索的任何结果。",
    "application.ui.offer": "机会",
    "application.ui.offer.offers": "机会",
    "application.ui.offer.acceptOffer": "接受报价",
    "application.ui.offer.rejectOffer": "拒绝报价",
    "application.ui.offer.noOffers": "没有报价",
    "application.ui.offer.expiresAt": "优惠持续到",
    "application.ui.offer.offerExpires": "优惠过期",
    "application.ui.offer.setOtherExpirationDate": "确定另一个日期",
    "application.ui.offer.inXDays": "在30天内",
    "application.ui.only": "仅限",
    "application.ui.openDocument": "打开文件",
    "application.ui.or": "或",
    "application.ui.playVideo": "播放视频",
    "application.ui.readMore": "阅读更多",
    "application.ui.region": "地区",
    "application.ui.relevance": "相关性",
    "application.ui.reset": "重置所有过滤器",
    "application.ui.routingDescription": "路线图",
    "application.ui.saveShownProfile": "保存个人资料更改",
    "application.ui.saveUnsavedChanges": "拯救",
    "application.ui.select": "请选择",
    "application.ui.shop": "淘宝店",
    "application.ui.showAllEntries": "显示所有条目",
    "application.ui.showAllSearch": "显示所有搜索结果",
    "application.ui.showMoreSearch": "显示更多搜索结果",
    "application.ui.resetSearch": "重置选择并再次搜索",
    "application.ui.sort": "分拣",
    "application.ui.stockPhotoLinks": "与图像数据库的链接",
    "application.ui.suitableFor": "推荐给",
    "application.ui.total": "共计",
    "application.ui.totalDreampoints": "你所有作品的梦想点",
    "application.ui.trySearch": "你是否想尝试其他的搜索词，比如说",
    "application.ui.type": "类型",
    "application.ui.unsavedChanges": "未保存的修改",
    "application.ui.updated": "最新的",
    "application.ui.uploadedFileFor": "上传的文件为。",
    "application.ui.userName": "帐号",
    "application.ui.viewAsList": "列表查看",
    "application.ui.viewAsTable": "表视图",
    "application.ui.visibleForAll": "对所有用户都是可见的",
    "application.ui.visibleForFollower": "对追随者来说是可见的",
    "application.ui.visibleForJoiner": "对连接者来说是可见的",
    "application.ui.visibleForNone": "任何人都无法看到",
    "application.ui.visibleForSupporter": "对支持者来说是可见的",
    "application.ui.years": "年",
    "application.ui.yearsFew": "婴儿年龄",
    "application.widgets.highestRatedEntries": "最多Dreampoints",
    "application.widgets.inFocus": "聚焦",
    "application.widgets.newestEntries": "最新的 {{size}}栏目介绍",
    "application.widgets.noNewestEntries": "没有发现新条目...",
    "application.widgets.showCategories": "显示类别过滤器",
    "application.widgets.showFilter": "显示更多的过滤器",
    "application.ui.writeUs": "写信给我们...",
    "blog.ui.category": "类别",
    "blog.ui.categoryHeading": "来自类别",
    "blog.ui.lastUpdated": "最后更新",
    "blog.ui.openSinglePost": "阅读更多",
    "blog.ui.publishedIn": "发表于",
    "blog.ui.subCategories": "子类别",
    "blog.ui.written": "书面",
    "blog.ui.writtenBy": "撰写人",
    "buttons.alreadyMeToo": "我很高兴你也在梦想这篇作品。",
    "buttons.alreadyMeTooMobile": "我很高兴你也在梦想这篇作品。",
    "buttons.cancel": "取消",
    "buttons.confirm": "确认",
    "buttons.createAccount": "现在设置EntryerAccount",
    "buttons.delete": "删除",
    "buttons.deleteFavorite": "不再跟随",
    "buttons.deleteLike": "没有更多的类似",
    "buttons.meToo": "我也想这么做",
    "buttons.newPassword": "申请新密码",
    "buttons.ok": "好的",
    "buttons.ready": "准备就绪",
    "buttons.save": "保存更改",
    "buttons.saveMobile": "拯救",
    "buttons.selectThis": "选择这个",
    "buttons.tryAgain": "请再试一次",
    "category.ui.hideCategories": "隐藏子类别",
    "category.ui.oneLevelUp": "回到上级类别",
    "category.ui.showCategories": "显示子类别",
    "categoryFields.attachmentWidth": "实施宽度",
    "categoryFields.clutch": "联轴器",
    "categoryFields.companyName": "公司名称",
    "categoryFields.dealerInfo": "经销商信息",
    "categoryFields.diameter": "直径",
    "categoryFields.displayNetPrice": "净价格",
    "categoryFields.electricalPower": "电力",
    "categoryFields.engine": "电机",
    "categoryFields.engineDescription": "发动机设备",
    "categoryFields.ERPname": "商品管理中的名称",
    "categoryFields.horsePower": "权力",
    "categoryFields.netWidth": "不含轮胎的轨道宽度",
    "categoryFields.packingUnit": "包装单位",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "拓宽了轨道",
    "categoryFields.vendorAcronym": "短期指定",
    "categoryFields.volume": "卷宗",
    "categoryFields.weight": "重量",
    "categoryFields.wheelLock": "站轮锁",
    "checkout.cart.title": "我的购物车",
    "checkout.cart.item": "文章",
    "checkout.cart.amount": "数量",
    "checkout.cart.edit": "变化",
    "checkout.cart.price": "价格",
    "checkout.cart.singlePrice": "单位价格",
    "checkout.cart.product.isNonPhysical": "产品未发运（可能会被下载）。",
    "checkout.cart.product.requiresExpressDelivery": "新鲜产品，快递运输。只有在D内。",
    "checkout.cart.product.deliveryProblem": "选定的产品可能会出现交货延迟的情况。",
    "checkout.cart.subtotal": "小计",
    "checkout.cart.deliveryCosts": "运输费用",
    "checkout.cart.total": "共计",
    "checkout.cart.help": "你对订购过程有疑问吗？我们已经为你汇编了重要的信息。",
    "checkout.cart.subtotalInfo": "包括增值税",
    "checkout.cart.subtotalShipment": "加上偏离的运输费用",
    "checkout.cart.checkout": "结账",
    "checkout.cart.checkoutWithoutUser": "无客户账户的结账",
    "checkout.cart.checkoutFast": "快速结账",
    "checkout.cart.deliveryInfo": "购物车中物品的预计交货日期",
    "checkout.cart.orderSuccess": "您的订单已成功。",
    "checkout.cart.orderCommitting": "您的订单正在处理中 - 请等待",
    "checkout.cart.myOrder": "我的订单",
    "checkout.cart.leftover": "左边",
    "checkout.checkout.orderNumber": "订单号",
    "checkout.checkout.account": "客户账户",
    "checkout.checkout.andOrder": "和命令",
    "checkout.checkout.delivery": "交付和交付地址",
    "checkout.checkout.delivery.type": "调度是通过",
    "checkout.checkout.delivery.street": "地址行1",
    "checkout.checkout.delivery.houseNumber": "没有。",
    "checkout.checkout.delivery.co": "地址后缀(c/o)",
    "checkout.checkout.delivery.city": "城市",
    "checkout.checkout.delivery.selectCountry": "请选择国家",
    "checkout.checkout.delivery.addressBoth": "账单和交货地址",
    "checkout.checkout.delivery.addressInvoice": "发票地址",
    "checkout.checkout.delivery.addressDelivery": "送货地址",
    "checkout.checkout.delivery.default": "将此地址设为我的默认送货地址",
    "checkout.checkout.delivery.otherAddress": "送货地址与帐单地址不同",
    "checkout.checkout.delivery.dhlNr": "您的DHL邮政编号",
    "checkout.checkout.delivery.packingStation": "包装站编号",
    "checkout.checkout.delivery.showPackingStations": "显示我的地址附近的包装站",
    "checkout.checkout.delivery.wantExpressShipping": "快递",
    "checkout.checkout.delivery.isPickUp": "现场取货",
    "checkout.checkout.delivery.specialCountryInfo": "你的国家不能被选中？",
    "checkout.checkout.delivery.specialCountry": "您的国家不在我们的标准运输范围内",
    "checkout.checkout.delivery.specialCountryMessage": "你的购物车中的个别产品不能运往所有国家。请从您的订单中删除一些产品，直到您的国家能够被选中，或者联系我们。",
    "checkout.checkout.payment": "付款方式",
    "checkout.checkout.paymentProblem": "执行支付时发生错误",
    "checkout.checkout.backToPayment": "回到付款设置。",
    "checkout.checkout.paymentTitle": "你想如何支付？",
    "checkout.checkout.paymentSelected": "选择的支付方式",
    "checkout.checkout.payment.cc": "信用卡",
    "checkout.checkout.payment.ccInfo": "对于欧盟境内的银行卡支付，每次在线支付时都必须确认你的身份。下完订单后，你将被引导完成银行提供的确认程序。",
    "checkout.checkout.payment.complimentary": "免费的",
    "checkout.checkout.payment.paypal": "贝宝",
    "checkout.checkout.payment.paypalInfo": "你将被转到PayPal，以完成付款过程。你的PayPal账户不会被扣款，直到你在下一步确认订单。",
    "checkout.checkout.payment.sepa": "直接扣款（SEPA）。",
    "checkout.checkout.payment.sepaLegal": "我授权 {{siteOwner}}{{siteOwnerAddress}}(债权人ID: {{siteOwnerCreditorID}})以直接借记方式从我的账户中收取款项。同时，我指示我的银行从以下方面进行直接借贷 {{siteOwner}}到我的账户。",
    "checkout.checkout.payment.noSepa": "你的帐单或送货地址在欧盟以外。因此，我们不能为您提供直接借记的付款方式。",
    "checkout.checkout.payment.sepaInfo": "快速而不复杂。当完成您的订单时（下一步），您将被要求输入您的银行详细信息（IBAN）。请准备好这些。转账完成后，你会收到一份确认书。",
    "checkout.checkout.payment.prepayment": "预付款（银行转账）",
    "checkout.checkout.payment.prepaymentInfo": "在您下订单后，我们将通过电子邮件向您发送银行转账信息。我们只能在您的订单中保留最多七天的物品。请保留这个银行转账的时间窗口。一旦我们收到付款，订单就会被运走。",
    "checkout.checkout.payment.invoice": "发票",
    "checkout.checkout.payment.invoiceInfo": "发票金额应与电子邮件发送确认中所述的付款期限一起支付。",
    "checkout.checkout.payment.noInvoice": "不幸的是，我们不能为这个订单提供发票付款。请选择其他付款方式之一。",
    "checkout.checkout.payment.sofort": "即时银行转账",
    "checkout.checkout.payment.sofortInfo": "当完成您的订单时（下一步），您将被要求输入您的银行详细信息（IBAN）。请准备好这些。转账完成后，你会收到一份确认书。",
    "checkout.checkout.payment.accountName": "账户持有人",
    "checkout.checkout.payment.accountEmail": "确认的电子邮件地址",
    "checkout.checkout.sepaAndOrder": "直接借记授权为 {{totalAmount}}欧元确认并订购了费用",
    "checkout.checkout.sofortAndOrder": "转让任务为 {{totalAmount}}欧元确认并订购了费用",
    "checkout.checkout.title": "结账",
    "checkout.checkout.next": "更多",
    "checkout.checkout.order": "现在购买",
    "checkout.checkout.orderConsentPre": "通过下订单，您同意我们",
    "checkout.checkout.orderConsentPost": "同意。",
    "checkout.checkout.noAccount": "不要创建一个客户账户",
    "checkout.redirect.countryPriceChange": "你所在国家的价格与标准价格不同。请确认价格变化。",
    "comments.blockComments": "对这个帖子的评论已经结束。",
    "comments.comment.anonymous": "佚名",
    "comments.comment.button": "写一个新的评论",
    "comments.comment.buttonReply": "答案",
    "comments.comment.cancel": "取消",
    "comments.comment.content": "您的评论",
    "comments.comment.hasCommented": "评论",
    "comments.comment.login": "登录后可发表评论",
    "comments.comment.past": "有",
    "comments.comment.replyFrom": "回应",
    "comments.comment.showReplies": "显示答案",
    "comments.comment.showReply": "显示答案",
    "comments.comment.submit": "提交",
    "comments.comment.title": "评论标题",
    "comments.comment.userName": "您的用户名",
    "comments.heading": "建议、说明、评论",
    "comments.hierarchy.flat": "平坦",
    "comments.hierarchy.nested": "遗传基因",
    "comments.hierarchy.title": "评论观点。",
    "comments.message.afterSaveError": "您的评论目前无法保存!",
    "comments.message.afterSaveSuccess": "您的评论已被保存。",
    "comments.message.afterSaveUnlock": "你的评论已被保存，但仍需要管理员检查。",
    "comments.noComments": "还没有人提出意见",
    "comments.reply": "AN。",
    "comments.show": "显示评论",
    "comments.title": "评论",
    "entries.edit": "入职 <strong>{{title}}</strong>编辑",
    "entries.entries": "栏目介绍",
    "entries.product.ui.chooseVariant": "选择一个变体",
    "entries.product.ui.chooseVariantMobile": "选择变体",
    "entries.product.ui.combinationNotAvailable": "不可能合并",
    "entries.entry.fields.address.city": "城市",
    "entries.entry.fields.address.country": "国家",
    "entries.entry.fields.address.houseNumber": "门牌号",
    "entries.entry.fields.address.street": "街道",
    "entries.entry.fields.address.zip": "邮政编号",
    "entries.entry.fields.language": "语言",
    "entries.entry.fields.prefixMissing": "以下字段没有填写。",
    "entries.entry.profile.edit": "编辑个人资料",
    "entries.entry.profile.publicProfile": "到我的公开资料页面",
    "entries.entry.tooltip.alreadyCopied": "你已经在做梦了，这个条目。",
    "entries.entry.tooltip.alreadyFollow": "你已经在关注这个条目",
    "entries.entry.tooltip.alreadyLiked": "你已经喜欢这个条目",
    "entries.entry.tooltip.copied": "你也想做这个梦，真好。您的个人照片已被添加到此条目中。",
    "entries.entry.tooltip.filterComments": "点击这里，在条目栏中只显示评论。",
    "entries.entry.tooltip.filterFollows": "点击这里，只显示条目中的 \"跟随\"。",
    "entries.entry.tooltip.filterLikes": "点击这里，只显示条目中的喜欢。",
    "entries.entry.tooltip.filterShares": "点击这里，只显示进场线的股票",
    "entries.entry.tooltip.follow": "点击这里关注此条目",
    "entries.entry.tooltip.like": "点击这里给这个作品点个赞",
    "entries.entry.tooltip.noFollow": "关注只对其他人的作品有效",
    "entries.entry.tooltip.noFollowNotLoggedIn": "您必须登录后才能关注此条目",
    "entries.entry.tooltip.noLike": "喜欢只对别人的作品有效",
    "entries.entry.ui.access": "只有以下人士可以看到此条目",
    "entries.entry.ui.added": "...被添加。",
    "entries.entry.ui.addEntry": "添加条目",
    "entries.entry.ui.addOpening": "增加开放时间",
    "entries.entry.ui.address": "地址数据",
    "entries.entry.ui.addStep": "增加一个步骤",
    "entries.entry.ui.agreementHours": "根据安排开放",
    "entries.entry.ui.allAccess": "所有人都可以看到这个条目",
    "entries.entry.ui.assignedCategories": "指定类别",
    "entries.entry.ui.assignedCertificates": "我的证书",
    "entries.entry.ui.assignedProducts": "分配的产品",
    "entries.entry.ui.businessContact": "公司联系数据",
    "entries.entry.ui.categories": "此条目的类别",
    "entries.entry.ui.categoryFields": "更多信息",
    "entries.entry.ui.commercialTitle": "条目上的公司信息",
    "entries.entry.ui.companyTitle": "我公司的详细资料",
    "entries.entry.ui.contactPerson": "我的联系方式",
    "entries.entry.ui.cssClass": "内容的CSS类",
    "entries.entry.ui.deliveryInfo": "送货服务信息",
    "entries.entry.ui.descriptionInfo": "提示：当单词被标记后，可以分配样式。",
    "entries.entry.ui.descriptionPlaceholder": "用HTML描述。双击HTML功能栏的文本（最多600个字符）。",
    "entries.entry.ui.editEntry": "编辑条目",
    "entries.entry.ui.editEntryNew": "创建条目",
    "entries.entry.ui.exclusionHours": "排除时间",
    "entries.entry.ui.galleryImages": "图片库（2或3张图片，取决于安排）。",
    "entries.entry.ui.globalTitle": "命名",
    "entries.entry.ui.hasKiosk": "我有一个摊位",
    "entries.entry.ui.imagesHorizontal": "横向",
    "entries.entry.ui.imagesInfo": "图像可以在 \"图像/视频/文件 \"下进行编辑。",
    "entries.entry.ui.imagesOrientation": "图像对齐",
    "entries.entry.ui.imagesSquared": "方形",
    "entries.entry.ui.imagesVertical": "纵向",
    "entries.entry.ui.isListImage": "这张图片是列表图片",
    "entries.entry.ui.isMainImage": "该图像是主图像",
    "entries.entry.ui.isMainImageAndListImage": "该图像作为条目的主图像出现。",
    "entries.entry.ui.jobApply": "现在申请",
    "entries.entry.ui.kioskDescription": "对你的销售摊位的描述",
    "entries.entry.ui.listImage": "列表图片",
    "entries.entry.ui.logo": "徽标",
    "entries.entry.ui.longDescription": "长描述文本",
    "entries.entry.ui.mainImage": "主要图片",
    "entries.entry.ui.makeListImage": "设置此图像为列表图像",
    "entries.entry.ui.makeListImageTooltip": "搜索结果以列表形式显示，并配有列表图片。如果没有选择列表图片，就会使用主图片。",
    "entries.entry.ui.makeMainImage": "将此图像设置为主图像",
    "entries.entry.ui.makeMainImageTooltip": "主要图片显示在个人资料的顶部。没有被选为主图像或列表图像的图像将显示在图像库中。",
    "entries.entry.ui.metaDescription": "简要说明",
    "entries.entry.ui.metaDescriptionPlaceholder": "简短的描述，最多200个字符",
    "entries.entry.ui.multiCode": "本步骤的附加代码",
    "entries.entry.ui.multiDescription": "本步骤的描述",
    "entries.entry.ui.multiImages": "本步骤的图像",
    "entries.entry.ui.multiProgress": "进展",
    "entries.entry.ui.multiTitle": "本步骤的标题",
    "entries.entry.ui.multiTitlePlaceholder": "本步骤的标题（最多50个字符）",
    "entries.entry.ui.noDescriptions": "该条目尚无描述性文本。",
    "entries.entry.ui.noEntries": "没有发现任何条目。",
    "entries.entry.ui.onlyVisibleForCustomers": "此条目仅对注册客户可见",
    "entries.entry.ui.openAt": "开放式",
    "entries.entry.ui.openingHours": "开放时间",
    "entries.entry.ui.openingInfo": "关于开放时间的说明",
    "entries.entry.ui.openingReligious": "在以天主教为主的教区的地址",
    "entries.entry.ui.overallProgress": "这个条目是 {{value}}实现的百分比",
    "entries.entry.ui.parking": "停车设施",
    "entries.entry.ui.parkingLots": "数量",
    "entries.entry.ui.parkingType": "艺术",
    "entries.entry.ui.personImage": "联系人的照片",
    "entries.entry.ui.relatedEvents": "相关的活动",
    "entries.entry.ui.relatedJobs": "连接的工作",
    "entries.entry.ui.relatedArticles": "相关的文章",
    "entries.entry.ui.relatedCampaigns": "相关的运动",
    "entries.entry.ui.rentKiosk": "我想租一个销售摊位",
    "entries.entry.ui.resolveAddress": "确定坐标",
    "entries.entry.ui.resolveAddressInfo": "请根据你的地址确定地理点（在地图上的位置）。如果没有正确的地理点，它们可能无法被正确找到。",
    "entries.entry.ui.resolveGeolocation": "获取地址",
    "entries.entry.ui.routingPlaceholder": "在这里用HTML输入你的方向。",
    "entries.entry.ui.selectEvent": "选择事件",
    "entries.entry.ui.selectJob": "选择工作",
    "entries.entry.ui.selectArticle": "选择文章",
    "entries.entry.ui.selectCampaign": "选择运动",
    "entries.entry.ui.sharing": "本页关于 {{sharing}}分享",
    "entries.entry.ui.shareMail": "通过邮件分享此页面",
    "entries.entry.ui.shortDescriptionInfo": "是在同一时间的简短描述",
    "entries.entry.ui.skillsNeeded": "我可以很好地利用它来做我的入口....。",
    "entries.entry.ui.sortword": "分类的替代标题",
    "entries.entry.ui.step": "步骤",
    "entries.entry.ui.stepInDream": "这一步是在参赛的哪个阶段？",
    "entries.entry.ui.stepInDreamAfter": "实现后",
    "entries.entry.ui.stepInDreamBefore": "变现前",
    "entries.entry.ui.stepInDreamDuring": "在实现的过程中",
    "entries.entry.ui.subtitle": "描述性的副标题",
    "entries.entry.ui.subtitlePlaceholder": "输入副标题（最多60个字符）",
    "entries.entry.ui.tabCategories": "类别",
    "entries.entry.ui.tabCategoriesMobile": "猫。",
    "entries.entry.ui.tabCompany": "公司数据",
    "entries.entry.ui.tabDescription": "描述",
    "entries.entry.ui.tabDescriptionMobile": "读者",
    "entries.entry.ui.tabExhibitor": "参展商详情",
    "entries.entry.ui.tabImages": "图片/视频/文件",
    "entries.entry.ui.tabImagesMobile": "图片/视频/文件",
    "entries.entry.ui.tabRelations": "连接",
    "entries.entry.ui.tags": "标签",
    "entries.entry.ui.title": "该条目出现在以下名称下",
    "entries.entry.ui.titleError": "在保存前必须对条目进行命名",
    "entries.entry.ui.titlePlaceholder": "输入标题（最多50个字符）",
    "entries.entry.ui.visible": "此条目是可见的",
    "entries.entry.ui.promotion": "参与本条目的行动",
    "entries.entry.ui.regions": "此条目的区域类别",
    "entries.job.ui.promotion": "参与本职工作的行动",
    "entries.event.edit": "活动 <strong>{{title}}</strong>编辑",
    "entries.event.ui.addEvent": "添加事件",
    "entries.event.ui.cooperation": "合作伙伴",
    "entries.event.ui.directions": "路线图",
    "entries.event.ui.editEvent": "编辑事件",
    "entries.event.ui.editEventNew": "创建活动",
    "entries.event.ui.endDate": "活动结束（一天）",
    "entries.event.ui.eventDone": "贸易展览会报告",
    "entries.event.ui.noEvents": "没有发现任何活动",
    "entries.event.ui.openEvent": "呼叫事件",
    "entries.event.ui.opening": "开放时间",
    "entries.event.ui.startDate": "活动开始(日)",
    "entries.event.ui.tabCategories": "类别",
    "entries.event.ui.tabDescription": "描述",
    "entries.event.ui.tabOpening": "日期和时间",
    "entries.event.ui.tabRelations": "连接",
    "entries.event.ui.title": "该事件以下列名称出现",
    "entries.event.ui.upcoming": "概括地说",
    "entries.event.ui.current": "目前正在进行",
    "entries.event.ui.history": "已经发生了",
    "entries.open": "在前台调出条目",
    "entries.person.ui.company": "公司",
    "entries.person.ui.female": "女士",
    "entries.person.ui.diverse": "没有",
    "entries.person.ui.fullName": "名和姓",
    "entries.person.ui.fullSize": "全尺寸",
    "entries.person.ui.gender": "性别",
    "entries.person.ui.greeting": "问候语",
    "entries.person.ui.male": "冯先生",
    "entries.person.ui.open": "直接进入这个人的条目",
    "entries.person.ui.personAddress": "人的地址",
    "entries.person.ui.personContact": "个人联系信息",
    "entries.person.ui.personImage": "个人照片",
    "entries.person.ui.position": "职务",
    "entries.person.ui.quote": "引用/说明",
    "entries.person.ui.signature": "签名图片",
    "entries.poi.ui.poiEvents": "在这个地方的活动",
    "entries.poi.ui.title": "该地以下列名称出现",
    "entries.product.edit": "产品 <strong>{{title}}</strong>编辑",
    "entries.product.fields.engine": "电机",
    "entries.product.fields.horsePower": "权力",
    "entries.product.menu.allAttachments": "所有附件一目了然",
    "entries.product.menu.allCarriers": "概述中的所有承运车辆",
    "entries.product.menu.attachments": "附件",
    "entries.product.menu.carriers": "承运车辆",
    "entries.product.tags.eco": "生态",
    "entries.product.tags.neu": "新",
    "entries.product.ui.addProduct": "添加产品",
    "entries.product.ui.choose": "选择",
    "entries.product.ui.discount": "折扣",
    "entries.product.ui.discountSelect": "使用%的折扣优势",
    "entries.product.ui.equipmentVariants": "设备变体",
    "entries.product.ui.variants": "变体",
    "entries.product.ui.gauge": "宽度（厘米",
    "entries.product.ui.gaugeMin": "最小轨道宽度（厘米",
    "entries.product.ui.inclusive": "包括",
    "entries.product.ui.live": "{{name}}体验生活",
    "entries.product.ui.netPrize": "包括 {{tax}}%的增值税，加上",
    "entries.product.ui.netPrizeDefault": "包括法定增值税，加上",
    "entries.product.ui.deliveryTerms": "运输费用",
    "entries.product.ui.noProducts": "没有找到产品",
    "entries.product.ui.noPurchaseOnThisPage": "不能在此页面上订购，请调用该产品。",
    "entries.product.ui.onlyBasicVariant": "仅在基本设备中",
    "entries.product.ui.onlyWithCarrier": "只在购买基本单元时提供",
    "entries.product.ui.order": "添加到购物车",
    "entries.product.ui.notOrderable": "请选择一个变体。",
    "entries.entry.ui.products.delivery.red": "目前不提供的产品",
    "entries.entry.ui.products.delivery.yellow": "产品有库存。可能会有更长的交货时间。",
    "entries.entry.ui.products.delivery.green": "产品有足够数量的库存",
    "entries.products.ui.combinationNotAvailable": "此组合中不存在的产品",
    "product.ui.content": "内容",
    "product.ui.ingredients": "内容",
    "product.ui.ingredientsFood": "营养成分",
    "entries.product.ui.overview": "概述",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "从",
    "entries.product.ui.item": "碎片",
    "entries.product.ui.prizePerItem": "每件价格",
    "entries.product.ui.noPrize": "请向您的联系人了解价格。",
    "entries.product.ui.size": "尺寸",
    "entries.product.ui.required": "只有与",
    "entries.product.ui.suitableFor": "适用于",
    "entries.product.ui.suitableForAttachments": "适用于以下附件",
    "entries.product.ui.suitableForCarriers": "适用于以下运载工具",
    "entries.product.ui.title": "产品",
    "entries.product.ui.variant": "变体/捆绑",
    "entries.product.ui.variantNotOrderable": "无法订购所选的变体",
    "entries.product.ui.inVariants": "用于产品变体",
    "entries.product.ui.bundleparts": "由以下部分组成",
    "entries.progress.title": "如何改进你的作品",
    "entries.tabbar.basics": "基本数据",
    "entries.tabbar.entry": "输入数据",
    "entries.tabbar.gallery": "图片",
    "entries.tabbar.layout": "布局",
    "entries.tabbar.openingHours": "开放时间",
    "entries.tabbar.order": "订购信息",
    "entries.tabbar.tooltip.entryNotSavedDetail": "条目数据只有在条目被保存为标题后才能被编辑。",
    "entries.tabbar.tooltip.entryNotSavedLayout": "布局数据只有在条目被保存为标题后才能被编辑。",
    "entries.types.poi": "蒲公英",
    "entries.types.standard": "标准",
    "entries.ui.groupDetailName": "小组条目的名称",
    "entries.ui.groupName": "群体（地区）的名称",
    "entryTypes.entry": "入职",
    "entryTypes.event": "活动",
    "entryTypes.poi": "场地",
    "errors.blank": "{{description}}必须填写",
    "errors.description": "这个领域",
    "errors.email": "请输入一个有效的 {{description}}-地址",
    "errors.empty": "{{description}}不得为空",
    "errors.exclusion": "这 {{description}}已经采取了",
    "errors.inclusion": "这 {{description}}不包括在选择范围内",
    "errors.invalid": "{{description}}是无效的",
    "errors.mandatory": "{{field}}必须填写",
    "errors.mandatoryCheckbox": "必须确认。",
    "errors.mandatorySubmit": "只有当所有必须填写的字段都填写完毕后，你才能提交表格。",
    "errors.slug": "Slug只能包含小写字母、数字和\"-\"。",
    "forms.ui.deleteFile": "删除文件",
    "forms.ui.formErr": "发送表格时出错",
    "forms.ui.formSent": "发送的表格",
    "forms.ui.noForm": "没有指定表格",
    "forms.ui.invalidMail": "请输入一个有效的电子邮件地址。",
    "forms.ui.isSubmitted": "该表格已被发送。我们会尽快与你联系。",
    "forms.ui.missingFields": "必须填写以下字段。",
    "forms.ui.uploadSuccess": "{{file}}已上传",
    "forms.ui.fieldBuilder.street": "街道",
    "forms.ui.fieldBuilder.houseNumber": "没有。",
    "forms.ui.fieldBuilder.zip": "邮政编号",
    "forms.ui.fieldBuilder.city": "城市",
    "forms.ui.fieldBuilder.country": "国家",
    "forms.ui.fieldBuilder.firstName": "名",
    "forms.ui.fieldBuilder.lastName": "姓氏",
    "forms.ui.privacyRead": "我已经注意到了隐私政策",
    "forms.ui.privacy": "隐私政策",
    "jobs.ui.employmentType": "工作类型",
    "jobs.ui.employmentType.FULL_TIME": "全日制",
    "jobs.ui.employmentType.PART_TIME": "非全日制",
    "jobs.ui.employmentType.APPRENTICE": "培训",
    "jobs.ui.employmentType.INTERN": "实习生",
    "jobs.ui.employmentType.CONTRACTOR": "自由职业者",
    "jobs.ui.employmentType.TRAINEE": "培训生，兼职学习",
    "jobs.ui.employmentType.VOLUNTEER": "自愿",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "工作地点",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "截至目前",
    "jobs.ui.startFrom": "来自",
    "media.documents.search.noResult": "请输入一个序列号或选择一个类别。",
    "media.documents.search.placeholder": "按单位序列号或类型号搜索",
    "media.documents.search.reset": "重置搜索结果",
    "media.ui.copyright": "版权声明",
    "media.ui.cropFormat": "格式规范",
    "media.ui.cropImage": "修剪",
    "media.ui.cropImageDuplicate": "复制和切割",
    "media.ui.description": "描述",
    "media.ui.filename": "文件名称",
    "media.ui.imageSaved": "保存的图像",
    "media.ui.processingImage": "图片正在裁剪中，请等待",
    "media.ui.resetCrop": "重置切割",
    "media.ui.resetImage": "回归原点",
    "media.ui.rotateImage": "将图像顺时针旋转90°。",
    "media.ui.title": "标题",
    "name": "命名",
    "openingHours.additionalInfo": "开放时间的信息",
    "openingHours.addOpening": "增加开放时间",
    "openingHours.date.day.di": "星期二",
    "openingHours.date.day.do": "做",
    "openingHours.date.day.fr": "礼拜五",
    "openingHours.date.day.mi": "米",
    "openingHours.date.day.mo": "莫",
    "openingHours.date.day.sa": "卫星",
    "openingHours.date.day.so": "所以",
    "openingHours.dateS": "日期",
    "openingHours.delete": "删除",
    "openingHours.from": "从",
    "openingHours.options": "地区",
    "openingHours.time": "时间",
    "openingHours.to": "至",
    "openingHours.type": "开放时间的类型",
    "opt-in.error-can-not-update-member": "的成员数据的变化。 {{email}}无法进行。",
    "opt-in.error-list-does-not-exist": "没有找到所给日期的清单。",
    "opt-in.error-list-does-not-need-validation": "指定的列表没有opt-int。",
    "opt-in.error-member-not-in-list": "在所给的日期中没有发现名单上的成员。",
    "opt-in.success-member-verified": "ǞǞǞ {{email}}被加入到 {{listname}}添加到列表中。",
    "opt-out.error-can-not-update-member": "的成员数据的变化。 {{email}}无法进行。",
    "opt-out.error-list-does-not-exist": "没有找到所给日期的清单。",
    "opt-out.error-list-does-not-have-opt-out": "指定的名单没有选择退出。",
    "opt-out.error-member-not-in-list": "在所给的日期中没有发现名单上的成员。",
    "opt-out.success-membership-terminated": "ǞǞǞ {{email}}被从 {{listname}}移除。",
    "password.errors.not-secure-enough": "我们建议密码的长度至少为8个字符，大小写字母、数字和特殊字符（$@$！%*#?&amp;）。",
    "password.errors.password-and-conf-do-not-match": "这两个密码必须是相同的。",
    "password.quality.0": "非常糟糕",
    "password.quality.1": "坏的",
    "password.quality.2": "足够的",
    "password.quality.3": "良好",
    "password.quality.4": "非常好",
    "profile.entries.copycats": "属于下列情况的条目 {{userName}}也想让",
    "profile.entries.donates": "属于下列情况的条目 {{userName}}支持",
    "profile.entries.entries": "参赛作品来自 {{userName}}",
    "profile.entries.follows": "具备以下条件的条目 {{userName}}如下",
    "profile.entries.joins": "其中的条目 {{userName}}参与",
    "profile.marketplace.fields.energyRequired": "电力需求",
    "profile.marketplace.fields.livingAnimals": "携带活体动物",
    "profile.marketplace.fields.loadingUnloading": "抵达/离开时的必要条件",
    "profile.marketplace.fields.maxRange": "对于我的报价，我已经开了这么多公里了",
    "profile.marketplace.fields.placeRequired": "场地要求",
    "profile.marketplace.fields.securityRequired": "对安全的需求",
    "profile.marketplace.fields.shows": "方案报价",
    "profile.marketplace.fields.spaceRequired": "空间需求，单位：m²",
    "profile.marketplace.fields.waterRequired": "水的需求量，单位：m³",
    "profile.marketplace.new": "新的提议或需求",
    "profile.marketplace.offer.delete": "删除",
    "profile.marketplace.offer.description": "描述",
    "profile.marketplace.offer.descriptionPlaceholder": "有意义的描述，以便另一个用户可以用它做一些事情。",
    "profile.marketplace.offer.new": "创建新的报价",
    "profile.marketplace.offer.relatedEntry": "分配的条目",
    "profile.marketplace.offer.tags": "提供/要求的标签（将被更好地找到）。",
    "profile.marketplace.offer.title": "我提供",
    "profile.marketplace.request.delete": "删除请求",
    "profile.marketplace.request.new": "创建新的请求",
    "profile.marketplace.request.missingCredentials": "只有当你至少创建了一个地点和一个测量点时，你才能开始查询。",
    "profile.marketplace.request.title": "我需要",
    "profile.messages.approve": "确认",
    "profile.messages.as": "作为",
    "profile.messages.commentPre": "有",
    "profile.messages.commentSuff": "评论",
    "profile.messages.decided": "决定",
    "profile.messages.deletedUser": "被删除的用户",
    "profile.messages.donateDream": "非常感谢你们帮助这位参赛者实现了他的参赛作品。他将立即收到你的信息，并希望能与你联系，接受你的帮助。",
    "profile.messages.dreamer": "入境者",
    "profile.messages.dreamToo": "也是为了梦想",
    "profile.messages.enter": "加入了",
    "profile.messages.favoriteDream": "您现在关注的是该条目。",
    "profile.messages.follow": "如下",
    "profile.messages.has": "有",
    "profile.messages.is": "是",
    "profile.messages.join": "加入",
    "profile.messages.joinDream": "你想和这个条目一起做梦。",
    "profile.messages.likeDream": "你现在喜欢这个条目。",
    "profile.messages.sendText": "您的信息已被发送。",
    "profile.messages.shareDream": "您已成功分享此条目。",
    "profile.messages.supporter": "支持者",
    "profile.messages.want": "希望",
    "profile.messages.youAre": "你是",
    "profile.messages.youWant": "你会喜欢",
    "profile.newsletter.notSubscribed": "订阅",
    "profile.newsletter.notSubscribedAny": "你还没有订阅通讯列表。",
    "profile.newsletter.privacyInformation": "通过点击 \"订阅\"，您同意我们在未来向您发送该订阅的新闻简报（选择加入）。您可以在任何时候通过再次点击 \"订阅\"（选择退出）来取消对通讯的订阅。",
    "profile.newsletter.subscribed": "已订阅",
    "profile.press.articleDetail": "文章细节",
    "profile.press.downloadImage": "下载图片",
    "profile.press.downloadImages": "下载图片",
    "profile.press.downloadText": "出口文本",
    "profile.press.filterAuthor": "按作者过滤",
    "profile.press.hide": "隐藏",
    "profile.press.show": "逐渐消失",
    "profile.progress.title": "如何改善你的个人资料",
    "register.ui.addressData": "地址数据",
    "register.ui.addressInfo": "他们不需要输入个人地址。然而，这导致了正确的路线和距离计算。",
    "register.ui.companyData": "您公司的详细资料",
    "register.ui.companyDataMandatory": "你必须输入你的公司名称",
    "register.ui.emptyFirstName": "你必须输入你的名字",
    "register.ui.emptyLastName": "你必须输入你的姓氏",
    "register.ui.emptyMail": "你必须提供一个有效的电子邮件地址",
    "register.ui.emptyUserName": "你必须指定一个用户名",
    "register.ui.language": "语言",
    "register.ui.loginData": "登录数据",
    "register.ui.password": "密码",
    "register.ui.passwordRepeat": "重复密码",
    "register.ui.personalData": "您的个人数据",
    "register.ui.privacyStatement": "我同意储存我的个人数据。我的个人数据不会被传递给第三方。我可以在任何时候撤销这一数据保护同意，并对未来有效。",
    "register.ui.takenEmail": "这个电子邮件已经被一个用户指定。",
    "register.ui.takenUserName": "这个用户名已经被占用了",
    "register.ui.timezone": "时区",
    "register.ui.typeMinimum": "你必须至少选择一种类型",
    "register.ui.usernameInfo": "重要提示：这是你登录时的用户名",
    "register.ui.oauthInfo": "你将被转到FRM登录页面，用你的用户信息登录，然后返回网站。",
    "search.allDistances": "所有的距离",
    "search.andFilter": "和过滤器设置",
    "search.closeTags": "隐藏标签",
    "search.for": "至",
    "search.resetPosition": "重置位置",
    "search.geoPositionBrowser": "通过浏览器确定位置",
    "search.geoPositionFault": "浏览器不可能确定位置",
    "search.geoPositionTitle": "为近距离搜索设置位置",
    "search.selectedFilters": "选定的过滤器",
    "search.selectFilters": "按类别过滤搜索",
    "search.hits": "撞击",
    "search.openTags": "显示标签",
    "search.removeDatefilter": "移除日期过滤器",
    "search.resulted": "揭示",
    "search.resultType.category": "类别",
    "search.resultType.entry": "入职",
    "search.resultType.event": "活动",
    "search.resultType.group": "集团",
    "search.resultType.page": "页码",
    "search.resultType.person": "个人",
    "search.resultType.post": "岗位",
    "search.resultType.product": "产品",
    "search.resultType.article": "文章",
    "search.resultType.job": "工作",
    "search.resultType.recipe": "烹饪食谱",
    "search.search": "搜索",
    "search.result": "结果",
    "search.results": "结果",
    "search.searchBarPlaceholder": "搜索...",
    "search.searchBarPlaceholderEntries": "按经销商名称或经销商地址搜索。",
    "search.searchBarPlaceholderEntriesPosition": "用自己的位置（如城市）进行周边搜索...",
    "search.searchBarPlaceholderEntriesPositionZip": "...或邮政编码",
    "search.searchBarPlaceholderGeoSearch": "输入你的地址...",
    "search.searchBarPlaceholderMobile": "搜索...",
    "search.searchBarPlaceholderProducts": "搜索产品",
    "search.searchBarPositionTooltip": "确定位置或用地址输入位置",
    "search.showAll": "显示所有",
    "search.globalSearch": "搜索页面",
    "social.comment": "评论",
    "social.Comment": "评论",
    "social.CopycatJoiner": "梦想你的作品",
    "social.dream.donateAmount": "用一笔钱支持",
    "social.dream.donateDreampoints": "支持Dreamampoints",
    "social.dream.donateInfo": "该信息是私密的，只有条目所有者可以阅读。",
    "social.dream.donateTip": "以建议和行动提供支持",
    "social.dream.done": "已完成",
    "social.dream.edit": "编辑条目",
    "social.dream.findDreams": "在这里找到大大小小的作品，并使你的作品成为现实和/或帮助他人实现他们的作品。",
    "social.dream.followedDreams": "您关注的条目",
    "social.dream.giveEmail": "分享我的电子邮件地址",
    "social.dream.givePhone": "分享我的电话号码",
    "social.dream.joinerApproved": "你加入这个项目",
    "social.dream.joinerButton": "加入此条目",
    "social.dream.joinerButtonMobile": "参加活动",
    "social.dream.joinerDream": "团体梦想",
    "social.dream.joinerInfo": "如果条目所有者确认你的参与，这个条目就会成为一个团体房间，你有进一步的权利。",
    "social.dream.joinerMessage": "在此输入你给参赛者的信息。",
    "social.dream.joinerPending": "作品的主人仍需确认你的参与。",
    "social.dream.joinerSupport": "您支持此条目",
    "social.dream.joinerSupportButton": "支持此条目",
    "social.dream.joinerSupportButtonMobile": "支持",
    "social.dream.joinerSupportInfo": "我们一直在努力改进，例如用钱或入口处进行支持。请继续关注。",
    "social.dream.joinerSupportPending": "条目所有者必须仍然允许你的支持",
    "social.dream.member": "会员",
    "social.dream.members": "成员",
    "social.dream.meToo": "我也想这么做",
    "social.dream.meTooInfo": "向参赛者表明，你认为这个作品很好，你想做这个作品。",
    "social.dream.noFollow": "你没有关注任何条目!",
    "social.dream.noJoiner": "你还不是一个团体的成员，你的梦想是什么？",
    "social.dream.noLikes": "你还不喜欢任何条目!",
    "social.dream.noSupport": "你不支持一个条目",
    "social.dream.registerFirstText": "为了能够分享一个条目，支持该条目或表明你也想做这个条目，你必须登录。注册并分享您的作品是快速和免费的。只需点击 \"注册 \"按钮。",
    "social.dream.registerFirstTitle": "沿着梦想，支持，甚至做？",
    "social.dream.settings": "入境设置",
    "social.dream.shareTitle": "分享梦想",
    "social.dream.similar": "相关条目",
    "social.dreamline.addImageToDream": "将此图片添加到您的作品中",
    "social.dreamline.addMedia": "添加图片/视频",
    "social.dreamline.allActivities": "所有Dreamline活动",
    "social.dreamline.anonymous": "佚名",
    "social.dreamline.commentButton": "评论",
    "social.dreamline.comments": "写道。",
    "social.dreamline.depublished": "该评论因行为不当而被删除。",
    "social.dreamline.dreampointsInfo": "这些是条目中的Dreampoints。你可以在你的个人资料中找到更多关于Dreampoints的信息。",
    "social.dreamline.fewDreampoints": "只有几个Dreamampoints。评论、喜欢或分享该作品。",
    "social.dreamline.filterBySteps": "按步骤过滤",
    "social.dreamline.filterComment": "评论",
    "social.dreamline.filteredBy": "经过滤的活动",
    "social.dreamline.filteredBySteps": "按步骤过滤",
    "social.dreamline.filterFavorite": "遵循",
    "social.dreamline.filterLike": "喜欢",
    "social.dreamline.filterShare": "股票",
    "social.dreamline.filterStep": "阶梯",
    "social.dreamline.flagged": "这个帖子被报道了",
    "social.dreamline.isComment": "评论",
    "social.dreamline.isCopycatJoiner": "亦梦",
    "social.dreamline.isFirstUpdate": "创建的",
    "social.dreamline.isShare": "共享",
    "social.dreamline.isStep": "迈出了新的一步",
    "social.dreamline.isUpdate": "最新的",
    "social.dreamline.lastEntry": "最后的条目更新",
    "social.dreamline.locked": "此内容只有做梦的同伴才能看到",
    "social.dreamline.lockedCTA": "现在加入这个项目",
    "social.dreamline.noMessages": "此条目尚未有任何活动。现在就写第一个评论。",
    "social.dreamline.noMessagesFiltered": "该条目在这些过滤设置下没有活动。",
    "social.dreamline.nowFollower": "跟随这个条目!",
    "social.dreamline.nowJoiner": "现在是一名支持者",
    "social.dreamline.nowLikes": "现在就喜欢这个作品!",
    "social.dreamline.nowSharing": "分享了这个作品!",
    "social.dreamline.onlyJoinerVisible": "只有做梦的同伴/支持者才能看到",
    "social.dreamline.prefixComment": "之前有这个条目",
    "social.dreamline.prefixCopycatJoiner": "想加入这个条目，因为",
    "social.dreamline.prefixFavorite": "一直在关注这个条目，因为",
    "social.dreamline.prefixJoiner": "支持这个条目，因为",
    "social.dreamline.prefixLike": "喜欢这个条目，因为",
    "social.dreamline.prefixShare": "之前有这个条目",
    "social.dreamline.prefixStep": "曾有过",
    "social.dreamline.prefixUpdate": "之前有这个条目",
    "social.dreamline.profile": "形象",
    "social.dreamline.provideImage": "我把这张照片交给参赛者处理。",
    "social.dreamline.resetFilter": "重置过滤器",
    "social.dreamline.sendComment": "发送",
    "social.dreamline.sharePart": "分享",
    "social.dreamline.toggleStream": "评论",
    "social.dreamline.toggleStreamSingle": "评论",
    "social.dreamline.writeComment": "写评论...",
    "social.Favorite": "遵循",
    "social.follow": "遵循",
    "social.Follow": "遵循",
    "social.hasLiked": "你已经喜欢这个条目。",
    "social.join": "加盟",
    "social.Join": "加盟",
    "social.Joiner": "一路走来的梦想",
    "social.like": "喜欢",
    "social.Like": "喜欢",
    "social.report.headlinePrefix": "你在那里",
    "social.report.headlineSuffix": "来报告。",
    "social.report.login": "你必须登录后才能报告该帖子。",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "其他攻击性内容",
    "social.report.reportScam": "虚报，欺凌。",
    "social.report.reportSex": "性内容",
    "social.report.reportSpam": "这是个垃圾邮件",
    "social.report.reportViolence": "对暴力的描述",
    "social.report.send": "发送消息",
    "social.report.title": "报告",
    "social.share": "分享",
    "social.Share": "分享",
    "syd.ui.dreamerCommercial": "B2B标准",
    "syd.ui.userCommercial": "B2B梦想家",
    "user.company.addUser": "创建额外的用户，用于",
    "user.company.inviteUser": "发送邀请邮件",
    "user.company.userIsInvited": "发送了邀请函的电子邮件",
    "user.company.userIsActive": "用户是活跃的",
    "user.company.userIsNotActive": "用户尚未被激活",
    "user.company.additionalWebsite": "公司的其他网站（只有域名没有https://）",
    "user.company.companyName": "公司名称",
    "user.company.contactFor": "联系方式为",
    "user.company.contactForDefault": "一般联系",
    "user.company.copyContact": "从公司的详细资料中接管联系数据，以便输入",
    "user.company.email": "联系电子邮件",
    "user.company.facebook": "公司的Facebook账户（仅账户名称）",
    "user.company.fax": "用于书面联系的传真号码",
    "user.company.google": "公司的Google+账户（仅账户名称）",
    "user.company.instagram": "公司的Instagram账户（仅限账户名称）",
    "user.company.linkedin": "公司的LinkedIn账户（仅账户名称）",
    "user.company.newCustomerAccount": "创建一个商业账户",
    "user.company.noCustomerValidation": "您的企业账户尚未激活。",
    "user.company.noCustomerYet": "您的注册还没有被分配到一个商业账户。",
    "user.company.notAllowed": "你不能做任何改变，因为你的用户状态({{type}})不允许这样做。",
    "user.company.phone": "联系电话",
    "user.company.pinterest": "公司的Pinterest账户（仅账户名称）",
    "user.company.remarks": "笔记",
    "user.company.registerNumber": "商业登记号",
    "user.company.registerAuth": "当地法院",
    "user.company.acerCode": "您的能源供应的ACER代码",
    "user.company.taxNumber": "税号",
    "user.company.type.default": "标准",
    "user.company.type.procurator": "授权签字人",
    "user.company.type.manager": "常务董事",
    "user.company.twitter": "公司的Twitter账户（只有账户名称，没有@）。",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "请提供一个有效的增值税识别号码",
    "user.company.website": "公司网站（只有域名没有https://）",
    "user.company.xing": "公司的Xing账户（仅有账户名称）",
    "user.company.youtube": "公司的Youtube账户（仅账户名称）",
    "user.message.after-save-error": "你的修改无法保存。",
    "user.message.after-save-success": "你的修改已经被保存。",
    "user.message.can-not-login": "我们无法用这些用户和密码条目来登录你的系统!用户名的拼写是否正确？你输入的密码是否正确？在注册时可能出了问题。",
    "user.message.can-not-logout": "我们不能让你签出!",
    "user.message.conflict": "这个用户名已经存在，请选择另一个!",
    "user.message.fileExistsError": "上传失败。文件已经存在",
    "user.message.fileSizeError": "上传失败。文件太大",
    "user.message.loginFailureBottom": "也许你还没有一个Entryer账户？",
    "user.message.loginFailureTop": "你的用户名拼写正确吗？你输入的密码是否正确？",
    "user.message.registration-error": "您的注册过程中发生了一个错误!",
    "user.message.registration-success": "你应该很快收到我们的确认邮件。",
    "user.message.unknownError": "上传文件时出现未知错误",
    "user.message.upload-success": "上传成功",
    "user.message.verify-error": "您的电子邮件地址无法被验证。请联系管理员。",
    "user.message.verify-success": "您的电子邮件地址已被确认。你现在可以注册了。",
    "user.ui.accountGreeting": "你好。",
    "user.ui.accountMenu": "主页和信息",
    "user.ui.accountMenuActivities": "活动",
    "user.ui.accountMenuCompanySettings": "公司信息",
    "user.ui.accountMenuEntries": "我的作品",
    "user.ui.accountMenuEntryPre": "参赛作品。",
    "user.ui.accountMenuEvents": "我的活动",
    "user.ui.accountMenuFavorites": "我的最爱",
    "user.ui.accountMenuFollows": "我关注的条目",
    "user.ui.accountMenuImages": "图片/视频/文件",
    "user.ui.accountMenuJoin": "我的团体梦想",
    "user.ui.accountMenuLikes": "我喜欢的作品",
    "user.ui.accountMenuMeasuring": "我的测量位置和数据",
    "user.ui.accountMenuMessages": "新闻",
    "user.ui.accountMenuNewsletter": "我的通讯订阅",
    "user.ui.accountMenuPoi": "我的位置",
    "user.ui.accountMenuPress": "我的文章",
    "user.ui.accountMenuProducts": "我的产品",
    "user.ui.accountMenuJobs": "我的工作提供",
    "user.ui.accountMenuProfile": "用户资料",
    "user.ui.accountMenuRatings": "评论",
    "user.ui.accountMenuRegistrationData": "登录数据",
    "user.ui.accountMenuReviews": "评论",
    "user.ui.accountMenuSettings": "设置",
    "user.ui.accountMenuSkillsOffer": "我提供...",
    "user.ui.accountMenuSkillsRequest": "我需要...",
    "user.ui.accountMenuOffer": "我的提议",
    "user.ui.accountMenuRequest": "我的要求",
    "user.ui.accountMenuSkillsSearch": "要求和提议",
    "user.ui.accountMenuSubscriptions": "订阅",
    "user.ui.accountMenuSubscritions": "我的订阅",
    "user.ui.accountMenuSupport": "支持的条目",
    "user.ui.accountMenuSupports": "我支持的参赛作品",
    "user.ui.accountMenuTender": "招标",
    "user.ui.accountMenuOrders": "我的订单",
    "user.ui.accountNoMessages": "你还没有收到任何信息。",
    "user.ui.accountProgressbar": "你的资料是 {{value}}完成百分比",
    "user.ui.accountProgressbarEntry": "你的作品是 {{value}}完成百分比",
    "user.ui.accountSettings": "更多设置",
    "user.ui.accountCookieSettings": "点击这里来调整你的设置。",
    "user.ui.accountCookieEssential": "基本的",
    "user.ui.accountCookieAnalysis": "分析数据",
    "user.ui.accountCookieSocial": "社交媒体",
    "user.ui.accountCookieReviews": "评论",
    "user.ui.accountToolbarLogin": "登录",
    "user.ui.accountToolbarLoginTooltip": "免费注册并开始工作",
    "user.ui.accountToolbarLogout": "登出",
    "user.ui.adminPreview": "预览",
    "user.ui.backToLogin": "返回到注册页面",
    "user.ui.birthday": "生日",
    "user.ui.cannotLogin": "无法登录？点击这里重置密码。",
    "user.ui.changePassword": "我想改变我的密码",
    "user.ui.changeUserGroup": "其他订阅",
    "user.ui.city": "城市",
    "user.ui.companyName": "公司名称",
    "user.ui.companyNameTooltip": "重要提示：你的条目也将出现在这些公司名称下",
    "user.ui.country": "国家",
    "user.ui.createNewAccount": "创建一个新的用户账户",
    "user.ui.dataDelete": "其他条目。你想永久地删除该文件吗？无论在哪里使用这个文件，它都会被一个占位符取代。",
    "user.ui.dataUsage": "该文件被保存在",
    "user.ui.dataUsageNone": "该文件还没有被使用。你想永久地删除该文件吗？",
    "user.ui.deleteImage": "删除图片",
    "user.ui.description": "在此输入个人或公司的一般描述",
    "user.ui.documents": "文件",
    "user.ui.documentSearch": "文件搜索",
    "user.ui.download": "{{title}}下载",
    "user.ui.email": "电子邮件地址",
    "user.ui.fillOutForm": "注册时，必须完整填写所有必须填写的内容",
    "user.ui.firstName": "名字",
    "user.ui.academicTitle": "学术职称",
    "user.ui.gender": "男/女",
    "user.ui.genderFemale": "女性",
    "user.ui.genderFemaleSalutation": "女士",
    "user.ui.genderMale": "男性",
    "user.ui.genderMaleSalutation": "冯先生",
    "user.ui.genderDiverse": "各种各样的",
    "user.ui.genderThird": "未说明",
    "user.ui.housenumber": "没有。",
    "user.ui.iAmCustomer": "我想创建一个B2B账户",
    "user.ui.internalInfo": "有信息给你。点击这里。",
    "user.ui.lastLogin": "最后一次登记是在",
    "user.ui.languageAvailable": "有你的语言版本",
    "user.ui.languageMissing": "只有在",
    "user.ui.lastName": "姓氏",
    "user.ui.loginButton": "登录",
    "user.ui.loginTitle": "用户登录",
    "user.ui.memberSince": "会员以来",
    "user.ui.mobileNumber": "手机号码",
    "user.ui.myAddedStorage": "提供的图片",
    "user.ui.myStorage": "我的目录",
    "user.ui.noRegisterMessage": "关于用户账户，请联系指定的联系地址。你不能把自己注册为一个用户。",
    "user.ui.oldPassword": "以前的密码",
    "user.ui.password": "密码",
    "user.ui.phoneType": "电话",
    "user.ui.phoneTypeMobile": "手机",
    "user.ui.phoneTypeLandline": "固定电话",
    "user.ui.pleaseSelect": "请选择",
    "user.ui.pleaseSelectSalutation": "请选择敬语",
    "user.ui.registerButton": "现在设置用户账户",
    "user.ui.registerMessage": "现在注册并立即开始。这是很快速和简单的。",
    "user.ui.registerTitle": "新加入我们？",
    "user.ui.requestPasswordReset": "申请新密码并删除旧密码",
    "user.ui.requestPasswordSuccess": "你很快就会收到一封带有重置链接的电子邮件。",
    "user.ui.requestPasswordError": "重置密码时出现未知错误。",
    "user.ui.resetPassword": "重置密码",
    "user.ui.setPassword": "设置密码",
    "user.ui.resetPasswordPage": "重置您的密码",
    "user.ui.setPasswordPage": "设置你的密码",
    "user.ui.resetPasswordSuccess": "您的密码已被重置，您现在可以登录了。",
    "user.ui.setPasswordSuccess": "你的密码已经设置好了，你现在可以登录了。",
    "user.ui.selectCustomerType": "你是...请选择",
    "user.ui.stayLoggedin": "保持登录状态",
    "user.ui.stayLoggedinInfo": "如果这是一个可公开访问的设备，请停用该选项并再次注销以完成。",
    "user.ui.street": "街道",
    "user.ui.salutation": "问候语",
    "user.ui.title": "标题",
    "user.ui.thankYouForSubscribing": "谢谢你订阅我们的通讯。",
    "user.ui.errorAtSubscribing": "订阅通讯时出错",
    "user.ui.unsubscribe": "登出",
    "user.ui.unsubscribePage": "你真的想取消订阅我们的通讯吗？",
    "user.ui.uploadDrop": "要上传，请将文件拖到这里或",
    "user.ui.uploadSelectFile": "选择一个文件",
    "user.ui.uploadToMyStorage": "上传新图片到我的目录",
    "user.ui.uploadUserImage": "选择用户图片",
    "user.ui.username": "电子邮件地址",
    "user.ui.userName": "帐号",
    "user.ui.visibilityInfo": "提示：你决定从你的个人资料中公开显示什么点击眼睛符号来设置你的条目的可见性。",
    "user.ui.zip": "邮政编号",
    "user.ui.phone": "电话",
    "user.user": "入境者",
    "user.users": "栏目介绍",
    "widgets.shoppingCart.wishlist": "备忘录和愿望清单",
    "widgets.shoppingCart.wishlist.empty": "你的记事本和愿望清单上还没有任何产品",
    "widgets.shoppingCart.cart": "购物车",
    "user.product.addedToFavorites": "该项目已被添加到您的收藏夹中",
    "order.status.zero": "收到的订单",
    "order.status.one": "正在进行中",
    "order.status.two": "订单已发运",
    "order.status.three": "已访问",
    "order.status.four": "返回",
    "order.status.default": "不明职业的状态",
    "country.germany": "德国",
    "country.austria": "奥地利",
    "country.belgium": "比利时",
    "country.denmark": "丹麦",
    "country.bulgaria": "保加利亚",
    "country.croatia": "克罗地亚",
    "country.cyprus": "塞浦路斯",
    "country.czechia": "捷克共和国",
    "country.estonia": "爱沙尼亚",
    "country.finland": "芬兰",
    "country.france": "法国",
    "country.greece": "希腊",
    "country.hungary": "匈牙利",
    "country.ireland": "辽宁省",
    "country.italy": "意大利",
    "country.latvia": "拉脱维亚",
    "country.lithuania": "立陶宛",
    "country.luxembourg": "卢森堡",
    "country.lu": "卢森堡",
    "country.malta": "马耳他",
    "country.netherlands": "荷兰",
    "country.poland": "波兰",
    "country.portugal": "葡国",
    "country.romania": "罗马尼亚",
    "country.slovakia": "斯洛伐克",
    "country.slovenia": "斯洛文尼亚",
    "country.spain": "西班牙",
    "country.sweden": "瑞典",
    "country.norway": "挪威",
    "country.switzerland": "瑞士",
    "country.turkey": "土耳其",
    "country.unitedKingdom": "英国",
    "openingHours.tNowClosed": "现在关闭",
    "openingHours.tOpeningHoursNotKnown": "进一步的开放时间不详",
    "openingHours.tNowOpen": "现在开放",
    "openingHours.tNowStillOpen": "现在仍然开放",
    "openingHours.tNextOpeningHourUnknown": "下次开放日期未知",
    "openingHours.tSoonOpenFrom": "很快又从",
    "openingHours.tFrom": "从",
    "openingHours.tUntil": "至",
    "openingHours.tOpenAgainFrom": "再次打开从",
    "openingHours.tOpenAgainFrom2": "再次打开",
    "comparison.fields.measurements": "群体",
    "comparison.fields.weight": "重量",
    "application.tag.neu": "新的",
    "application.tag.sale": "销售",
    "application.tag.eco": "生态",
    "application.tag.expired": "過期",
    "application.tag.sonderrabatt": "特别折扣",
    "job.application.selectLocation": "选择你的位置",
    "job.application.location": "在以下地区可用"
  };
});