define("webapp/locales/cs/translations-base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "account.ui.entryBannerImageNew": "Nastavte hlavní obrázek pro svůj záznam.",
    "account.ui.profileBannerImageNew": "Nastavte hlavní obrázek svého profilu.",
    "account.ui.register": "Registrace",
    "account.ui.registeredAs": "V současné době jste zaregistrováni jako",
    "account.ui.personate": "Změna identity uživatele",
    "account.ui.userNameNotEditableTooltip": "Vaše uživatelské jméno. Zadané uživatelské jméno nelze změnit. Chcete-li změnit své uživatelské jméno, obraťte se na kontaktní osobu. Důležité: Po změně se musíte znovu přihlásit pod novým uživatelským jménem.",
    "account.ui.additionalDeliveryAddresses": "Zadali jste různé doručovací adresy.",
    "account.ui.editDeliveryAddresses": "Upravit doručovací adresu",
    "account.ui.editInvoiceAddress": "Upravit adresu společnosti",
    "account.ui.noDeliveryAddresses": "Zatím jste nezadali jinou doručovací adresu.",
    "account.ui.addDeliveryAddresses": "Přidat {{further}} Přidání jiné doručovací adresy",
    "account.ui.addDeliveryAddressTitle": "Přidání doručovací adresy",
    "account.ui.editDeliveryAddressTitle": "Upravit doručovací adresu",
    "account.ui.isDefaultAddress": "Toto je standardní doručovací adresa",
    "account.ui.saveToApplyChanges": "Změnu je třeba uložit, pak můžete například zadat jiné doručovací adresy.",
    "accountTypes.aussteller": "Vystavovatel",
    "accountTypes.gastgeber": "Hostitel",
    "accountTypes.veranstalter": "Organizátor",
    "application.beta.infotext": "Nebyly vytvořeny žádné odběry.",
    "application.breadcrumbsHome": "Home",
    "application.month": "měsíčně",
    "application.newsletter.alreadySubscribed": "Jste již předplatitelem {{list}} newsletter. Přístup k odběru newsletteru máte ve svém účtu.",
    "application.newsletter.alreadySubscribedNoList": "Již jste se zaregistrovali k odběru newsletteru",
    "application.newsletter.goToList": "Seznam adresátů",
    "application.newsletter.noAccess": "Nabízený newsletter nelze odebírat s vašimi oprávněními.",
    "application.newsletter.subscribeButton": "Zadejte svou e-mailovou adresu a přihlaste se k odběru newsletteru",
    "application.newsletter.subscribeButtonMobile": "E-mail",
    "application.newsletter.subscribe": "Přihlaste se k odběru na",
    "application.newsletter.subscribeList": "Přihlašujete se k odběru tohoto zpravodaje:",
    "application.newsletter.subscribeThanks": "Děkujeme, že jste se přihlásili k odběru našeho zpravodaje. Brzy obdržíte nejnovější zpravodaj.",
    "application.newsletter.subscribeThanksOptIn": "Děkujeme vám za odběr newsletteru. Obdržíte potvrzovací e-mail pro přihlášení.",
    "application.newsletter.subscribeWithAccount": "Přihlaste se pomocí aktuálně přihlášeného uživatele.",
    "application.noCosts": "Zdarma",
    "application.placeholderImageText": "Bez ilustrace",
    "application.searchAllGroups": "ve všech",
    "application.searchAllRegions": "na všech místech",
    "application.ui.access": "přístup",
    "application.ui.action": "Akce",
    "application.ui.addDocument": "Přidat dokument",
    "application.ui.addImage": "Přidat obrázek",
    "application.ui.addImages": "Přidat obrázky",
    "application.ui.addMedia": "Přidání obrázků/videí",
    "application.ui.addVideo": "Přidat video",
    "application.ui.advancedEdit": "Rozšířená stránka",
    "application.ui.all": "Všechny",
    "application.ui.alreadyFavorite": "Tato položka je na vašem seznamu sledovaných položek",
    "application.ui.makeFavorite": "Přidat tuto položku do seznamu sledovaných položek",
    "application.ui.also": "Také",
    "application.ui.alphabetical": "Abecední seznam",
    "application.ui.and": "a",
    "application.ui.at": "na adrese",
    "application.ui.in": "na adrese",
    "application.ui.atHour": "na adrese",
    "application.ui.hour": "Hodiny",
    "application.ui.buy": "koupit",
    "application.ui.certificate": "Certifikáty",
    "application.ui.confirmDeleteEntry": "Odstranění položky {{title}} nenávratně smazat?",
    "application.ui.contact": "Kontakt",
    "application.ui.count": "Číslo",
    "application.ui.currentlyActive": "Právě teď běží",
    "application.ui.deleteEntry": "smazat",
    "application.ui.discardUnsavedChanges": "Zahoďte změny a pokračujte",
    "application.ui.discardUnsavedChangesMobile": "Vyřazení",
    "application.ui.distance": "Vzdálenost",
    "application.ui.distant": "odstraněno",
    "application.ui.dreampoints": "Body snů",
    "application.ui.dreampointsAllDreams": "Body snů vašich záznamů",
    "application.ui.dreampointsDonate": "Body snů k darování",
    "application.ui.dreampointsTooltip": "Za svůj vstup můžete sbírat body snů",
    "application.ui.edit": "změna",
    "application.ui.editDescription": "Upravit popis",
    "application.ui.editEntry": "editovat",
    "application.ui.editEvent": "Upravit událost",
    "application.ui.editImage": "Upravit obrázek",
    "application.ui.editPoi": "Upravit umístění",
    "application.ui.editProduct": "Upravit produkt",
    "application.ui.editProfile": "Upravit můj profil",
    "application.ui.editShownProfile": "Změna zobrazení mého profilu",
    "application.ui.entriesFocus": "Vybrané položky v sekci Zaostřeno:",
    "application.ui.entriesLatest": "Nejnovější příspěvky v sekci Zaostřeno:",
    "application.ui.email": "E-mail",
    "application.ui.for": "pro",
    "application.ui.from": "z",
    "application.ui.fullView": "Obecný pohled",
    "application.ui.gallery": "Obrázky",
    "application.ui.galleryImages": "do galerie...",
    "application.ui.gavePoints": "vám dal {{points}} bodů.",
    "application.ui.goToEntry": "Zadání výzvy",
    "application.ui.goToEvent": "Událost volání",
    "application.ui.goToPoi": "Místo volání",
    "application.ui.goToMeasuring": "Měřící body",
    "application.ui.goToProduct": "Produkt na zavolání",
    "application.ui.hasBeen": "byl",
    "application.ui.here": "Poblíž",
    "application.ui.invalidEmbed": "Tuto adresu URL nelze vložit.",
    "application.ui.imprintLink": "Odpovědnost a právní odpovědnost za obsah této podstránky {{entry}} - Otisk",
    "application.ui.isActive": "Aktivní",
    "application.ui.isNotActive": "Bezbariérový",
    "application.ui.knowMore": "Všechny {{aboutPre}} {{about}}",
    "application.ui.language.de": "Deutsch",
    "application.ui.language.en": "English",
    "application.ui.language.fr": "Français",
    "application.ui.language.it": "Italiano",
    "application.ui.language.nl": "Nederlands",
    "application.ui.language.pl": "Polski",
    "application.ui.language.es": "Español",
    "application.ui.language.sv": "Svensk",
    "application.ui.language.tr": "Türkçe",
    "application.ui.language.cs": "Česky",
    "application.ui.language.ru": "Русский",
    "application.ui.language.zh": "中国",
    "application.ui.language.pt-br": "Português do Brasil",
    "application.ui.language.ua": "українська",
    "application.ui.language.fi": "Suomalainen",
    "application.ui.language.current": "Aktuálně zvolený jazyk",
    "application.ui.language.switchTo": "Změnit jazyk na ",
    "application.ui.mailBodyPre": "Dobrý den, našel jsem něco zajímavého:",
    "application.ui.mailSubject": "Našel jsem zajímavý odkaz:",
    "application.ui.mandatory": "Povinné pole",
    "application.ui.memberSince": "Členem od",
    "application.ui.mobileRotate": "Otočení pro zobrazení na celou obrazovku",
    "application.ui.more": "více",
    "application.ui.name": "Název",
    "application.ui.navigateOneLevelUp": "Zpět do nadřazené složky",
    "application.ui.new": "Nový",
    "application.ui.newEntry": "Vytvořit nový záznam",
    "application.ui.newEvent": "Vytvořit novou událost",
    "application.ui.newPoi": "Vytvoření nového umístění",
    "application.ui.newProduct": "Vytvoření nového produktu",
    "application.ui.noEntry": "Dosud jste nevytvořili položku. Vytvořte svůj první záznam právě teď...",
    "application.ui.noEntryProfile": "zatím nevytvořil žádný záznam.",
    "application.ui.noEvent": "Zatím jste nevytvořili žádnou událost. Vytvořte svou první událost právě teď...",
    "application.ui.noPoi": "Dosud jste nevytvořili umístění. Vytvořte si své první umístění právě teď...",
    "application.ui.noJob": "Zatím jste nevytvořili pracovní místo. Vytvořte si svou první práci nyní...",
    "application.ui.noProduct": "Produkt jste ještě nevytvořili. Vytvořte svůj první produkt právě teď...",
    "application.ui.notEditable": "nelze změnit",
    "application.ui.nothingFound": "Pro toto hledání jsme nenašli žádné výsledky.",
    "application.ui.offer": "Nabídky",
    "application.ui.offer.offers": "Nabídky",
    "application.ui.offer.acceptOffer": "Přijmout nabídku",
    "application.ui.offer.rejectOffer": "Odmítnutí nabídky",
    "application.ui.offer.noOffers": "Žádné nabídky",
    "application.ui.offer.expiresAt": "Nabídka platí do",
    "application.ui.offer.offerExpires": "Platnost nabídky končí",
    "application.ui.offer.setOtherExpirationDate": "Stanovení dalšího termínu",
    "application.ui.offer.inXDays": "za 30 dní",
    "application.ui.only": "Pouze",
    "application.ui.openDocument": "Otevřít soubor",
    "application.ui.playVideo": "Přehrát video",
    "application.ui.readMore": "Přečtěte si více",
    "application.ui.region": "Region",
    "application.ui.relevance": "Relevance",
    "application.ui.reset": "Obnovení všech filtrů",
    "application.ui.routingDescription": "Instrukce",
    "application.ui.saveShownProfile": "Uložení změn profilu",
    "application.ui.saveUnsavedChanges": "Uložit",
    "application.ui.select": "Vyberte prosím",
    "application.ui.shop": "Obchod",
    "application.ui.showAllEntries": "Zobrazit všechny položky",
    "application.ui.showAllSearch": "Zobrazit všechny výsledky vyhledávání",
    "application.ui.sort": "Třídění",
    "application.ui.stockPhotoLinks": "Odkazy na databáze obrázků",
    "application.ui.suitableFor": "Doporučeno pro",
    "application.ui.total": "Celkem",
    "application.ui.totalDreampoints": "Body snů všech vašich záznamů",
    "application.ui.trySearch": "Chcete zkusit jiné vyhledávací výrazy, např.",
    "application.ui.type": "Typ",
    "application.ui.unsavedChanges": "Neuložené změny",
    "application.ui.updated": "aktualizováno",
    "application.ui.uploadedFileFor": "Nahraný soubor pro:",
    "application.ui.userName": "Uživatelské jméno",
    "application.ui.viewAsList": "Zobrazení seznamu",
    "application.ui.viewAsTable": "Zobrazení tabulky",
    "application.ui.visibleForAll": "Viditelné pro všechny uživatele",
    "application.ui.visibleForFollower": "Viditelný pro následovníky",
    "application.ui.visibleForJoiner": "Viditelné pro truhláře",
    "application.ui.visibleForNone": "Není pro nikoho viditelný",
    "application.ui.visibleForSupporter": "Viditelný pro příznivce",
    "application.ui.years": "Roky",
    "application.ui.yearsFew": "Dětský věk",
    "application.widgets.highestRatedEntries": "Nejvíce bodů snů",
    "application.widgets.inFocus": "V centru pozornosti",
    "application.widgets.newestEntries": "Nejnovější {{size}} Záznamy",
    "application.widgets.noNewestEntries": "Nebyly nalezeny žádné nové záznamy...",
    "application.widgets.showCategories": "Zobrazit filtr kategorií",
    "application.widgets.showFilter": "Zobrazit další filtry",
    "application.ui.writeUs": "Napište nám...",
    "blog.ui.category": "Kategorie",
    "blog.ui.categoryHeading": "Z kategorie",
    "blog.ui.lastUpdated": "Poslední aktualizace",
    "blog.ui.openSinglePost": "Přečtěte si více",
    "blog.ui.publishedIn": "Zveřejněno v",
    "blog.ui.subCategories": "Podkategorie",
    "blog.ui.written": "Napsáno na",
    "blog.ui.writtenBy": "Napsal",
    "buttons.alreadyMeToo": "Jsem ráda, že se vám o tomto příspěvku také zdá.",
    "buttons.alreadyMeTooMobile": "Jsem ráda, že se vám o tomto příspěvku také zdá.",
    "buttons.cancel": "Zrušit",
    "buttons.confirm": "Potvrďte",
    "buttons.createAccount": "Nastavte si nyní účet EntryerAccount",
    "buttons.delete": "smazat",
    "buttons.deleteFavorite": "Již nesledovat",
    "buttons.deleteLike": "Žádné další podobenství",
    "buttons.meToo": "Rád bych to udělal také",
    "buttons.newPassword": "Žádost o nové heslo",
    "buttons.ok": "Ok",
    "buttons.ready": "Připraveno",
    "buttons.save": "Uložení změn",
    "buttons.saveMobile": "Uložit",
    "buttons.selectThis": "Vyberte tuto možnost",
    "buttons.tryAgain": "Zkuste to prosím znovu",
    "category.ui.hideCategories": "Skrýt podkategorie",
    "category.ui.oneLevelUp": "Zpět do nadřazené kategorie",
    "category.ui.showCategories": "Zobrazit podkategorie",
    "categoryFields.attachmentWidth": "Šířka implementace",
    "categoryFields.clutch": "Spojka",
    "categoryFields.companyName": "Název společnosti",
    "categoryFields.dealerInfo": "Informace o prodejci",
    "categoryFields.diameter": "Průměr",
    "categoryFields.displayNetPrice": "Čistá cena",
    "categoryFields.electricalPower": "Elektrická energie",
    "categoryFields.engine": "Motor",
    "categoryFields.engineDescription": "Vybavení motoru",
    "categoryFields.ERPname": "Název ve správě zboží",
    "categoryFields.horsePower": "Power",
    "categoryFields.netWidth": "Rozchod kol bez pneumatik",
    "categoryFields.packingUnit": "Balicí jednotka",
    "categoryFields.PTO": "PTO",
    "categoryFields.relativeGauge": "Rozšiřuje dráhu o",
    "categoryFields.vendorAcronym": "Krátké označení",
    "categoryFields.volume": "Svazek",
    "categoryFields.weight": "Hmotnost",
    "categoryFields.wheelLock": "Zámek stojanového kola",
    "checkout.cart.title": "Můj nákupní košík",
    "checkout.cart.item": "Článek",
    "checkout.cart.amount": "Číslo",
    "checkout.cart.edit": "Změna",
    "checkout.cart.price": "Cena",
    "checkout.cart.singlePrice": "Jednotková cena",
    "checkout.cart.product.isNonPhysical": "Produkt není dodáván (může být k dispozici ke stažení)",
    "checkout.cart.product.requiresExpressDelivery": "Čerstvý produkt s EXPRESNÍ dopravou. Pouze v rámci D.",
    "checkout.cart.product.deliveryProblem": "U vybraného produktu může dojít ke zpoždění dodávky.",
    "checkout.cart.subtotal": "Mezisoučet",
    "checkout.cart.deliveryCosts": "Náklady na dopravu",
    "checkout.cart.total": "Celkem",
    "checkout.cart.help": "Máte dotazy k procesu objednávání? Připravili jsme pro vás důležité informace.",
    "checkout.cart.subtotalInfo": "včetně DPH",
    "checkout.cart.subtotalShipment": "plus odchylné náklady na dopravu",
    "checkout.cart.checkout": "Pokladna",
    "checkout.cart.checkoutWithoutUser": "Pokladna bez zákaznického účtu",
    "checkout.cart.checkoutFast": "Rychlé odhlášení",
    "checkout.cart.deliveryInfo": "Odhadované datum dodání položek v nákupním košíku",
    "checkout.cart.orderSuccess": "Vaše objednávka byla úspěšná.",
    "checkout.cart.orderCommitting": "Vaše objednávka se zpracovává - vyčkejte prosím",
    "checkout.cart.myOrder": "Moje objednávka",
    "checkout.cart.leftover": "vlevo",
    "checkout.checkout.orderNumber": "Objednací číslo",
    "checkout.checkout.account": "Účet zákazníka",
    "checkout.checkout.andOrder": "a objednat",
    "checkout.checkout.delivery": "Dodání a dodací adresa",
    "checkout.checkout.delivery.type": "Odesílání probíhá pomocí",
    "checkout.checkout.delivery.street": "Adresní řádek 1",
    "checkout.checkout.delivery.houseNumber": "Ne.",
    "checkout.checkout.delivery.co": "Přípona adresy (c/o)",
    "checkout.checkout.delivery.city": "Město",
    "checkout.checkout.delivery.selectCountry": "Vyberte prosím zemi",
    "checkout.checkout.delivery.addressBoth": "Fakturační a dodací adresa",
    "checkout.checkout.delivery.addressInvoice": "Fakturační adresa",
    "checkout.checkout.delivery.addressDelivery": "Dodací adresa",
    "checkout.checkout.delivery.default": "Nastavení této adresy jako výchozí doručovací adresy",
    "checkout.checkout.delivery.otherAddress": "Doručovací adresa se liší od fakturační adresy.",
    "checkout.checkout.delivery.dhlNr": "Vaše číslo pošty DHL",
    "checkout.checkout.delivery.packingStation": "Číslo balicí stanice",
    "checkout.checkout.delivery.showPackingStations": "Zobrazit balicí stanice v okolí mé adresy",
    "checkout.checkout.delivery.wantExpressShipping": "Expresní doručení",
    "checkout.checkout.delivery.isPickUp": "Vyzvednutí na místě",
    "checkout.checkout.delivery.specialCountryInfo": "Vaši zemi nelze vybrat?",
    "checkout.checkout.delivery.specialCountry": "Vaše země je mimo naši standardní přepravu",
    "checkout.checkout.delivery.specialCountryMessage": "Jednotlivé produkty v nákupním košíku nelze zaslat do všech zemí. Odstraňte prosím některé produkty z objednávky, dokud nebude možné vybrat vaši zemi, nebo nás kontaktujte.",
    "checkout.checkout.payment": "Způsob platby",
    "checkout.checkout.paymentProblem": "Při provádění platby došlo k chybě",
    "checkout.checkout.backToPayment": "Zpět k nastavení plateb.",
    "checkout.checkout.paymentTitle": "Jak chcete platit?",
    "checkout.checkout.paymentSelected": "Zvolený způsob platby",
    "checkout.checkout.payment.cc": "Kreditní karta",
    "checkout.checkout.payment.ccInfo": "Při platbách kartou v rámci EU musí být vaše totožnost potvrzena při každé platbě online. Po zadání příkazu budete provedeni procesem potvrzení, který vám poskytne vaše banka.",
    "checkout.checkout.payment.complimentary": "zdarma",
    "checkout.checkout.payment.paypal": "PayPal",
    "checkout.checkout.payment.paypalInfo": "Budete přesměrováni na službu PayPal, kde dokončíte proces platby. Z vašeho účtu PayPal bude částka stržena až po potvrzení objednávky v dalším kroku.",
    "checkout.checkout.payment.sepa": "Přímé inkaso (SEPA)",
    "checkout.checkout.payment.sepaLegal": "Povoluji {{siteOwner}} {{siteOwnerAddress}} (ID věřitele: {{siteOwnerCreditorID}}) k inkasu plateb z mého účtu přímým inkasem. Současně dávám své bance pokyn, aby inkasa z účtu uznala. {{siteOwner}} na můj účet.",
    "checkout.checkout.payment.noSepa": "Vaše fakturační nebo dodací adresa je mimo EU. Proto vám nemůžeme nabídnout platbu přímým inkasem.",
    "checkout.checkout.payment.sepaInfo": "Rychlé a nekomplikované. Při dokončování objednávky (další krok) budete vyzváni k zadání bankovních údajů (IBAN). Připravte si je, prosím. Po dokončení převodu obdržíte potvrzení.",
    "checkout.checkout.payment.prepayment": "Platba předem (bankovním převodem)",
    "checkout.checkout.payment.prepaymentInfo": "Po zadání objednávky vám e-mailem zašleme informace o bankovním převodu. Položky ve vaší objednávce můžeme rezervovat maximálně na 7 dní. Dodržujte prosím toto časové okno pro bankovní převod. Jakmile obdržíme platbu, objednávka bude odeslána.",
    "checkout.checkout.payment.invoice": "Faktura",
    "checkout.checkout.payment.invoiceInfo": "Faktura je splatná v den platby uvedený v potvrzení o odeslání e-mailem.",
    "checkout.checkout.payment.noInvoice": "U této objednávky vám bohužel nemůžeme nabídnout platbu na fakturu. Zvolte prosím jeden z dalších způsobů platby.",
    "checkout.checkout.payment.sofort": "Okamžitý bankovní převod",
    "checkout.checkout.payment.sofortInfo": "Při dokončování objednávky (další krok) budete vyzváni k zadání bankovních údajů (IBAN). Připravte si je, prosím. Po dokončení převodu obdržíte potvrzení.",
    "checkout.checkout.payment.accountName": "Držitel účtu",
    "checkout.checkout.payment.accountEmail": "Potvrzovací e-mailová adresa",
    "checkout.checkout.sepaAndOrder": "Mandát k inkasu pro {{totalAmount}} € potvrdit a objednat s náklady",
    "checkout.checkout.sofortAndOrder": "Mandát k převodu pro {{totalAmount}} € potvrdit a objednat s náklady",
    "checkout.checkout.title": "Pokladna",
    "checkout.checkout.next": "více",
    "checkout.checkout.order": "Koupit nyní",
    "checkout.checkout.orderConsentPre": "Odesláním objednávky souhlasíte s našimi",
    "checkout.checkout.orderConsentPost": "souhlasit.",
    "checkout.checkout.noAccount": "Nevytvářejte zákaznický účet",
    "checkout.redirect.countryPriceChange": "Ceny pro vaši zemi se liší od standardních cen. Potvrďte prosím změnu ceny.",
    "comments.blockComments": "Komentování tohoto příspěvku bylo ukončeno.",
    "comments.comment.anonymous": "Anonymní",
    "comments.comment.button": "Napsat nový komentář",
    "comments.comment.buttonReply": "Odpovědi",
    "comments.comment.cancel": "Zrušit",
    "comments.comment.content": "Váš komentář",
    "comments.comment.hasCommented": "komentáře",
    "comments.comment.login": "Přihlaste se a napište komentář",
    "comments.comment.past": "má adresu",
    "comments.comment.replyFrom": "Reakce na",
    "comments.comment.showReplies": "Zobrazit odpovědi",
    "comments.comment.showReply": "Zobrazit odpověď",
    "comments.comment.submit": "Odeslat",
    "comments.comment.title": "Název komentáře",
    "comments.comment.userName": "Vaše uživatelské jméno",
    "comments.heading": "Návrhy, poznámky, připomínky",
    "comments.hierarchy.flat": "Ploché",
    "comments.hierarchy.nested": "Genested",
    "comments.hierarchy.title": "Zobrazení komentáře:",
    "comments.message.afterSaveError": "Váš komentář nelze v tuto chvíli uložit!",
    "comments.message.afterSaveSuccess": "Váš komentář byl uložen.",
    "comments.message.afterSaveUnlock": "Váš komentář byl uložen, ale musí být ještě zkontrolován správcem.",
    "comments.noComments": "Zatím nebyly vzneseny žádné připomínky",
    "comments.reply": "AN:",
    "comments.show": "Zobrazit komentáře",
    "comments.title": "Komentáře",
    "entries.edit": "Vstup <strong>{{title}}</strong> editovat",
    "entries.entries": "Záznamy",
    "entries.product.ui.chooseVariant": "Vyberte variantu",
    "entries.product.ui.chooseVariantMobile": "Vyberte variantu",
    "entries.product.ui.combinationNotAvailable": "Kombinace není možná",
    "entries.entry.fields.address.city": "Město",
    "entries.entry.fields.address.country": "Země",
    "entries.entry.fields.address.houseNumber": "Číslo domu",
    "entries.entry.fields.address.street": "Ulice",
    "entries.entry.fields.address.zip": "POSTCODE",
    "entries.entry.fields.language": "Jazyk",
    "entries.entry.fields.prefixMissing": "Následující pole nejsou vyplněna:",
    "entries.entry.profile.edit": "Upravit profil",
    "entries.entry.profile.publicProfile": "na mou veřejnou profilovou stránku",
    "entries.entry.tooltip.alreadyCopied": "O tomto záznamu se vám již zdá.",
    "entries.entry.tooltip.alreadyFollow": "Již sledujete tento záznam",
    "entries.entry.tooltip.alreadyLiked": "Tento záznam se vám již líbí",
    "entries.entry.tooltip.copied": "Je hezké, že chcete snít i o tomto příspěvku. K tomuto záznamu byl přidán váš profilový obrázek.",
    "entries.entry.tooltip.filterComments": "Klikněte zde pro zobrazení pouze Komentáře v řádku Entryline",
    "entries.entry.tooltip.filterFollows": "Kliknutím sem zobrazíte v řádku Entryline pouze položky Follows.",
    "entries.entry.tooltip.filterLikes": "Klikněte zde pro zobrazení pouze lajků v řádku pro zadávání příspěvků",
    "entries.entry.tooltip.filterShares": "Klikněte zde pro zobrazení pouze akcií v řádku pro zadání.",
    "entries.entry.tooltip.follow": "Klikněte zde pro sledování tohoto záznamu",
    "entries.entry.tooltip.like": "Klikněte zde a dejte tomuto příspěvku Like",
    "entries.entry.tooltip.noFollow": "Sledování funguje pouze pro příspěvky ostatních uživatelů.",
    "entries.entry.tooltip.noFollowNotLoggedIn": "Pro sledování tohoto záznamu musíte být přihlášeni",
    "entries.entry.tooltip.noLike": "Líbí se mi to pouze u záznamů jiných lidí.",
    "entries.entry.ui.access": "Tento záznam mohou zobrazit pouze následující osoby",
    "entries.entry.ui.added": "...byl přidán.",
    "entries.entry.ui.addEntry": "Přidávání záznamů",
    "entries.entry.ui.addOpening": "Přidat otevírací dobu",
    "entries.entry.ui.address": "Údaje o adrese",
    "entries.entry.ui.addStep": "Přidání kroku",
    "entries.entry.ui.agreementHours": "Otevřeno po dohodě",
    "entries.entry.ui.allAccess": "Všichni mohou vidět tento záznam",
    "entries.entry.ui.assignedCategories": "přiřazené kategorie",
    "entries.entry.ui.assignedCertificates": "Moje certifikace",
    "entries.entry.ui.assignedProducts": "Přiřazené produkty",
    "entries.entry.ui.businessContact": "Kontaktní údaje společnosti",
    "entries.entry.ui.categories": "Kategorie pro tento záznam",
    "entries.entry.ui.categoryFields": "Další informace",
    "entries.entry.ui.commercialTitle": "Informace o společnosti v záznamu",
    "entries.entry.ui.companyTitle": "Údaje o mé společnosti",
    "entries.entry.ui.contactPerson": "Moje kontaktní údaje",
    "entries.entry.ui.cssClass": "Třída CSS pro obsah",
    "entries.entry.ui.deliveryInfo": "Informace o doručovací službě",
    "entries.entry.ui.descriptionInfo": "Tip: Po označení slov lze přiřadit styly.",
    "entries.entry.ui.descriptionPlaceholder": "Popis pomocí HTML. Dvakrát klikněte na text funkčního panelu HTML (maximálně 600 znaků).",
    "entries.entry.ui.editEntry": "Upravit položku",
    "entries.entry.ui.editEntryNew": "Vytvořit položku",
    "entries.entry.ui.exclusionHours": "Doba vyloučení",
    "entries.entry.ui.galleryImages": "Galerie obrázků (2 nebo 3 obrázky v závislosti na uspořádání)",
    "entries.entry.ui.globalTitle": "Název",
    "entries.entry.ui.hasKiosk": "Mám stánek",
    "entries.entry.ui.imagesHorizontal": "Horizontální",
    "entries.entry.ui.imagesInfo": "Obrázky lze upravovat v části \"Obrázky/Videa/Dokumenty\".",
    "entries.entry.ui.imagesOrientation": "Zarovnání obrazu",
    "entries.entry.ui.imagesSquared": "Čtverec",
    "entries.entry.ui.imagesVertical": "Vertikální",
    "entries.entry.ui.isListImage": "Tento obrázek je obrázek seznamu",
    "entries.entry.ui.isMainImage": "Tento obrázek je hlavní obrázek",
    "entries.entry.ui.isMainImageAndListImage": "Tento obrázek se zobrazí jako hlavní obrázek záznamu",
    "entries.entry.ui.jobApply": "Podejte si přihlášku nyní",
    "entries.entry.ui.kioskDescription": "Popis vašeho prodejního stánku",
    "entries.entry.ui.listImage": "Seznam obrázků",
    "entries.entry.ui.logo": "Logo",
    "entries.entry.ui.longDescription": "Dlouhý text popisu",
    "entries.entry.ui.mainImage": "Hlavní obrázek",
    "entries.entry.ui.makeListImage": "Nastavení tohoto obrázku jako obrázku seznamu",
    "entries.entry.ui.makeListImageTooltip": "Výsledky hledání se zobrazí jako seznam s obrázkem seznamu. Pokud není vybrán žádný obrázek seznamu, použije se hlavní obrázek.",
    "entries.entry.ui.makeMainImage": "Nastavení tohoto obrázku jako hlavního obrázku",
    "entries.entry.ui.makeMainImageTooltip": "Hlavní obrázek se zobrazuje v horní části profilu. Obrázky, které nejsou vybrány jako hlavní obrázek nebo obrázek seznamu, se zobrazí v galerii obrázků.",
    "entries.entry.ui.metaDescription": "Stručný popis",
    "entries.entry.ui.metaDescriptionPlaceholder": "Krátký popis o maximální délce 200 znaků",
    "entries.entry.ui.multiCode": "Další kód pro tento krok",
    "entries.entry.ui.multiDescription": "Popis tohoto kroku",
    "entries.entry.ui.multiImages": "Obrázky pro tento krok",
    "entries.entry.ui.multiProgress": "Progress",
    "entries.entry.ui.multiTitle": "Název tohoto kroku",
    "entries.entry.ui.multiTitlePlaceholder": "Název tohoto kroku (maximálně 50 znaků)",
    "entries.entry.ui.noDescriptions": "Položka zatím nemá žádný popisný text.",
    "entries.entry.ui.noEntries": "Nebyly nalezeny žádné záznamy.",
    "entries.entry.ui.onlyVisibleForCustomers": "Tato položka je viditelná pouze pro registrované zákazníky",
    "entries.entry.ui.openAt": "Otevřít",
    "entries.entry.ui.openingHours": "Otevírací doba",
    "entries.entry.ui.openingInfo": "Poznámky k otevírací době",
    "entries.entry.ui.openingReligious": "Adresa v převážně katolické farnosti",
    "entries.entry.ui.overallProgress": "Tento záznam je {{value}}% realizováno",
    "entries.entry.ui.parking": "Parkovací zařízení",
    "entries.entry.ui.parkingLots": "Číslo",
    "entries.entry.ui.parkingType": "Art",
    "entries.entry.ui.personImage": "Obrázek kontaktní osoby",
    "entries.entry.ui.relatedEvents": "Související události",
    "entries.entry.ui.relatedJobs": "Připojená pracovní místa",
    "entries.entry.ui.relatedArticles": "Související články",
    "entries.entry.ui.relatedCampaigns": "Související kampaně",
    "entries.entry.ui.rentKiosk": "Rád bych si pronajal prodejní stánek",
    "entries.entry.ui.resolveAddress": "Určení souřadnic",
    "entries.entry.ui.resolveAddressInfo": "Určete geobod (polohu na mapě) podle své adresy. Bez správného geopointu nemusí být správně nalezeny.",
    "entries.entry.ui.resolveGeolocation": "Získat adresu",
    "entries.entry.ui.routingPlaceholder": "Zde zadejte pokyny pomocí HTML.",
    "entries.entry.ui.selectEvent": "Vybrat událost",
    "entries.entry.ui.selectJob": "Vybrat práci",
    "entries.entry.ui.selectArticle": "Vybrat článek",
    "entries.entry.ui.selectCampaign": "Vybrat kampaň",
    "entries.entry.ui.sharing": "Tato stránka na {{sharing}} sdílet",
    "entries.entry.ui.shareMail": "Sdílet tuto stránku poštou",
    "entries.entry.ui.shortDescriptionInfo": "Je zároveň krátký popis",
    "entries.entry.ui.skillsNeeded": "Mohl bych ji dobře využít pro svůj vstup....",
    "entries.entry.ui.sortword": "Alternativní názvy pro třídění",
    "entries.entry.ui.step": "Krok",
    "entries.entry.ui.stepInDream": "Ve které fázi zadávání je tento krok?",
    "entries.entry.ui.stepInDreamAfter": "po realizaci",
    "entries.entry.ui.stepInDreamBefore": "před realizací",
    "entries.entry.ui.stepInDreamDuring": "při realizaci",
    "entries.entry.ui.subtitle": "Popisný podtitul",
    "entries.entry.ui.subtitlePlaceholder": "Zadejte podtitul (maximálně 60 znaků)",
    "entries.entry.ui.tabCategories": "Kategorie",
    "entries.entry.ui.tabCategoriesMobile": "Cat.",
    "entries.entry.ui.tabCompany": "Údaje o společnosti",
    "entries.entry.ui.tabDescription": "Popis",
    "entries.entry.ui.tabDescriptionMobile": "Beschr.",
    "entries.entry.ui.tabExhibitor": "Podrobnosti o vystavovateli",
    "entries.entry.ui.tabImages": "Obrázky/videa/dokumenty",
    "entries.entry.ui.tabImagesMobile": "Obrázky/videa/soubory",
    "entries.entry.ui.tabRelations": "Připojení",
    "entries.entry.ui.tags": "Štítky",
    "entries.entry.ui.title": "Záznam se zobrazí pod tímto názvem",
    "entries.entry.ui.titleError": "Před uložením musí být položka pojmenována",
    "entries.entry.ui.titlePlaceholder": "Zadejte název (maximálně 50 znaků)",
    "entries.entry.ui.visible": "Tento záznam je viditelný",
    "entries.entry.ui.promotion": "Účast na akcích pro tento záznam",
    "entries.entry.ui.regions": "Kategorie regionu pro tento záznam",
    "entries.job.ui.promotion": "Účast na akcích pro toto pracovní místo",
    "entries.event.edit": "Událost <strong>{{title}}</strong> editovat",
    "entries.event.ui.addEvent": "Přidat událost",
    "entries.event.ui.cooperation": "Partner pro spolupráci",
    "entries.event.ui.directions": "Instrukce",
    "entries.event.ui.editEvent": "Upravit událost",
    "entries.event.ui.editEventNew": "Vytvořit událost",
    "entries.event.ui.endDate": "Konec akce (den)",
    "entries.event.ui.eventDone": "Zpráva z veletrhu",
    "entries.event.ui.noEvents": "Nebyly nalezeny žádné události",
    "entries.event.ui.openEvent": "Událost volání",
    "entries.event.ui.opening": "Otevírací doba",
    "entries.event.ui.startDate": "Začátek akce (den)",
    "entries.event.ui.tabCategories": "Kategorie",
    "entries.event.ui.tabDescription": "Popis",
    "entries.event.ui.tabOpening": "Datum a časy",
    "entries.event.ui.tabRelations": "Připojení",
    "entries.event.ui.title": "Událost se zobrazuje pod těmito názvy",
    "entries.event.ui.upcoming": "Stručně řečeno",
    "entries.event.ui.current": "V současné době probíhá",
    "entries.event.ui.history": "Již proběhlo",
    "entries.open": "Vyvolání záznamu ve frontendu",
    "entries.person.ui.company": "Společnost",
    "entries.person.ui.female": "Paní",
    "entries.person.ui.diverse": "Bez",
    "entries.person.ui.fullName": "Jméno a příjmení",
    "entries.person.ui.fullSize": "Plná velikost",
    "entries.person.ui.gender": "Pohlaví",
    "entries.person.ui.greeting": "Pozdrav",
    "entries.person.ui.male": "Pan",
    "entries.person.ui.open": "Přímo na vstup této osoby",
    "entries.person.ui.personAddress": "Adresa osoby",
    "entries.person.ui.personContact": "Osobní kontaktní údaje",
    "entries.person.ui.personImage": "Osobní fotografie",
    "entries.person.ui.position": "Pozice",
    "entries.person.ui.quote": "Citace/název",
    "entries.person.ui.signature": "Podpisový obrázek",
    "entries.poi.ui.poiEvents": "Události na tomto místě",
    "entries.poi.ui.title": "Místo se objevuje pod těmito názvy",
    "entries.product.edit": "Produkt <strong>{{title}}</strong> editovat",
    "entries.product.fields.engine": "Motor",
    "entries.product.fields.horsePower": "Power",
    "entries.product.menu.allAttachments": "Přehled všech příloh",
    "entries.product.menu.allCarriers": "Všechna přepravní vozidla v přehledu",
    "entries.product.menu.attachments": "Přílohy",
    "entries.product.menu.carriers": "Přepravní vozidla",
    "entries.product.tags.eco": "eco",
    "entries.product.tags.neu": "nový",
    "entries.product.ui.addProduct": "Přidat produkt",
    "entries.product.ui.choose": "vybrat",
    "entries.product.ui.discount": "Sleva",
    "entries.product.ui.discountSelect": "Využijte výhodu slevy %",
    "entries.product.ui.equipmentVariants": "Varianty vybavení",
    "entries.product.ui.gauge": "Šířka v cm",
    "entries.product.ui.gaugeMin": "Minimální šířka rozchodu v cm",
    "entries.product.ui.inclusive": "Včetně",
    "entries.product.ui.live": "{{name}} zážitek v přímém přenosu",
    "entries.product.ui.netPrize": "včetně {{tax}}% DPH plus",
    "entries.product.ui.netPrizeDefault": "včetně zákonné daně z přidané hodnoty, plus",
    "entries.product.ui.deliveryTerms": "Náklady na dopravu",
    "entries.product.ui.noProducts": "Nebyly nalezeny žádné produkty",
    "entries.product.ui.noPurchaseOnThisPage": "Nelze objednat na této stránce, zavolejte k produktu.",
    "entries.product.ui.onlyBasicVariant": "Pouze v základním vybavení",
    "entries.product.ui.onlyWithCarrier": "K dispozici pouze při zakoupení základní jednotky",
    "entries.product.ui.order": "Přidat do nákupního košíku",
    "entries.product.ui.notOrderable": "Vyberte variantu.",
    "entries.entry.ui.products.delivery.red": "Produkt není v současné době k dispozici",
    "entries.entry.ui.products.delivery.yellow": "Produkt je skladem. Možná delší dodací lhůta.",
    "entries.entry.ui.products.delivery.green": "Produkt na skladě v dostatečném množství",
    "entries.products.ui.combinationNotAvailable": "Produkt není v této kombinaci k dispozici",
    "product.ui.content": "Obsah",
    "product.ui.ingredients": "Obsah",
    "product.ui.ingredientsFood": "Složení",
    "entries.product.ui.overview": "Přehled",
    "entries.product.ui.prizeDecimalPoint": ",",
    "entries.product.ui.prizeFrom": "z",
    "entries.product.ui.item": "Kus",
    "entries.product.ui.prizePerItem": "Cena za kus",
    "entries.product.ui.noPrize": "Na cenu se zeptejte kontaktní osoby.",
    "entries.product.ui.size": "Velikost",
    "entries.product.ui.required": "Pouze s",
    "entries.product.ui.suitableFor": "Vhodné pro",
    "entries.product.ui.suitableForAttachments": "Vhodné pro následující nástavce",
    "entries.product.ui.suitableForCarriers": "Vhodný pro následující nosná vozidla",
    "entries.product.ui.title": "Produkt",
    "entries.product.ui.variant": "Varianta/svazek",
    "entries.product.ui.variantNotOrderable": "Vybranou variantu nelze objednat",
    "entries.product.ui.inVariants": "pro varianty produktu",
    "entries.product.ui.bundleparts": "Skládá se z",
    "entries.progress.title": "Jak zlepšit svůj vstup",
    "entries.tabbar.basics": "Základní údaje",
    "entries.tabbar.entry": "Vstupní údaje",
    "entries.tabbar.gallery": "Obrázky",
    "entries.tabbar.layout": "Rozložení",
    "entries.tabbar.openingHours": "Otevírací doba",
    "entries.tabbar.order": "Informace pro objednání",
    "entries.tabbar.tooltip.entryNotSavedDetail": "Údaje záznamu lze upravovat až po uložení záznamu s názvem.",
    "entries.tabbar.tooltip.entryNotSavedLayout": "Údaje o rozvržení lze upravovat až po uložení záznamu s názvem.",
    "entries.types.poi": "Poi",
    "entries.types.standard": "Standardní",
    "entries.ui.groupDetailName": "Název pro položky skupiny",
    "entries.ui.groupName": "Název pro skupiny (region)",
    "entryTypes.entry": "Vstup",
    "entryTypes.event": "Událost",
    "entryTypes.poi": "Místo konání",
    "errors.blank": "{{description}} musí být vyplněny",
    "errors.description": "Toto pole",
    "errors.email": "Zadejte prosím platné {{description}}-adresa",
    "errors.empty": "{{description}} nesmí být prázdný",
    "errors.exclusion": "Tento {{description}} je již obsazen",
    "errors.inclusion": "Tento {{description}} není zahrnuta do výběru",
    "errors.invalid": "{{description}} je neplatný",
    "errors.mandatory": "{{field}} musí být vyplněny",
    "errors.mandatoryCheckbox": "Musí být potvrzeno.",
    "errors.mandatorySubmit": "Formulář můžete odeslat až po vyplnění všech povinných polí.",
    "errors.slug": "Slug může obsahovat pouze malá písmena, číslice a znak \"-\".",
    "forms.ui.deleteFile": "Smazat soubor",
    "forms.ui.formErr": "Chyba při odesílání formuláře",
    "forms.ui.formSent": "Odeslaný formulář",
    "forms.ui.noForm": "Nebyl přidělen žádný formulář",
    "forms.ui.invalidMail": "Zadejte prosím platnou e-mailovou adresu.",
    "forms.ui.isSubmitted": "Formulář byl odeslán. Budeme vás co nejdříve kontaktovat.",
    "forms.ui.missingFields": "Je třeba vyplnit následující pole:",
    "forms.ui.uploadSuccess": "{{file}} byl nahrán",
    "forms.ui.fieldBuilder.street": "Ulice",
    "forms.ui.fieldBuilder.houseNumber": "Ne.",
    "forms.ui.fieldBuilder.zip": "POSTCODE",
    "forms.ui.fieldBuilder.city": "Město",
    "forms.ui.fieldBuilder.country": "Země",
    "forms.ui.fieldBuilder.firstName": "Imię i nazwisko",
    "forms.ui.fieldBuilder.lastName": "Nazwisko",
    "forms.ui.privacyRead": "Vzal jsem na vědomí zásady ochrany osobních údajů",
    "forms.ui.privacy": "Zásady ochrany osobních údajů",
    "jobs.ui.employmentType": "Typ práce",
    "jobs.ui.employmentType.FULL_TIME": "Na plný úvazek",
    "jobs.ui.employmentType.PART_TIME": "Na částečný úvazek",
    "jobs.ui.employmentType.APPRENTICE": "Školení",
    "jobs.ui.employmentType.INTERN": "Stáže",
    "jobs.ui.employmentType.CONTRACTOR": "Práce na volné noze",
    "jobs.ui.employmentType.TRAINEE": "Stážisté, mimopracovní studium",
    "jobs.ui.employmentType.VOLUNTEER": "Dobrovolnictví",
    "jobs.ui.employmentType.OTHER": "",
    "jobs.ui.location": "Volná pracovní místa na adrese",
    "jobs.ui.role.male": "M",
    "jobs.ui.role.female": "W",
    "jobs.ui.role.divers": "D",
    "jobs.ui.startDateNow": "Od nynějška",
    "jobs.ui.startFrom": "Z",
    "media.documents.search.noResult": "Zadejte sériové číslo nebo vyberte kategorii.",
    "media.documents.search.placeholder": "Vyhledávání podle sériového nebo typového čísla jednotky",
    "media.documents.search.reset": "Obnovení výsledku vyhledávání",
    "media.ui.copyright": "Autorská práva",
    "media.ui.cropFormat": "Specifikace formátu",
    "media.ui.cropImage": "Ořezávání",
    "media.ui.cropImageDuplicate": "Duplikovat a vystřihnout",
    "media.ui.description": "Popis",
    "media.ui.filename": "Název souboru",
    "media.ui.imageSaved": "Uložený obrázek",
    "media.ui.processingImage": "Obrázek se ořezává, vyčkejte prosím",
    "media.ui.resetCrop": "Obnovení řezání",
    "media.ui.resetImage": "Zpět k původnímu textu",
    "media.ui.rotateImage": "Otočení obrazu o 90° ve směru hodinových ručiček",
    "media.ui.title": "Název",
    "name": "Název",
    "openingHours.additionalInfo": "Informace o otevírací době",
    "openingHours.addOpening": "Přidat otevírací dobu",
    "openingHours.date.day.di": "Út",
    "openingHours.date.day.do": "Do",
    "openingHours.date.day.fr": "Pá",
    "openingHours.date.day.mi": "Mi",
    "openingHours.date.day.mo": "Mo",
    "openingHours.date.day.sa": "Sat",
    "openingHours.date.day.so": "Takže",
    "openingHours.dateS": "Datum",
    "openingHours.delete": "Odstranit",
    "openingHours.from": "z",
    "openingHours.options": "Oblast",
    "openingHours.time": "Čas",
    "openingHours.to": "na",
    "openingHours.type": "Typ otevírací doby",
    "opt-in.error-can-not-update-member": "Změny údajů o členech pro {{email}} nemohla být provedena.",
    "opt-in.error-list-does-not-exist": "Pro uvedená data nebyl nalezen žádný seznam.",
    "opt-in.error-list-does-not-need-validation": "Zadaný seznam nemá žádnou položku opt-int.",
    "opt-in.error-member-not-in-list": "Pro uvedená data nebyl nalezen žádný člen seznamu.",
    "opt-in.success-member-verified": "Na stránkách {{email}} byl přidán do {{listname}} přidán na seznam.",
    "opt-out.error-can-not-update-member": "Změny údajů o členech pro {{email}} nemohla být provedena.",
    "opt-out.error-list-does-not-exist": "Pro uvedená data nebyl nalezen žádný seznam.",
    "opt-out.error-list-does-not-have-opt-out": "Uvedený seznam nemá možnost odhlášení.",
    "opt-out.error-member-not-in-list": "Pro uvedená data nebyl nalezen žádný člen seznamu.",
    "opt-out.success-membership-terminated": "Na stránkách {{email}} byl odstraněn z {{listname}} odstraněny.",
    "password.errors.not-secure-enough": "Doporučujeme heslo o délce alespoň 8 znaků, obsahující velká a malá písmena, číslice a speciální znaky ($@$!%*#?&amp;).",
    "password.errors.password-and-conf-do-not-match": "Obě hesla musí být stejná.",
    "password.quality.0": "Velmi špatné",
    "password.quality.1": "Špatný",
    "password.quality.2": "Dostatečné",
    "password.quality.3": "Dobrý",
    "password.quality.4": "Velmi dobré",
    "profile.entries.copycats": "Záznamy, které {{userName}} také chce, aby",
    "profile.entries.donates": "Záznamy, které {{userName}} podporuje",
    "profile.entries.entries": "Záznamy z {{userName}}",
    "profile.entries.follows": "Záznamy, které {{userName}} následuje",
    "profile.entries.joins": "Záznamy, ve kterých {{userName}} účastní se",
    "profile.marketplace.fields.energyRequired": "Poptávka po elektřině",
    "profile.marketplace.fields.livingAnimals": "Odběr živých zvířat",
    "profile.marketplace.fields.loadingUnloading": "Nutné pro příjezd/odjezd",
    "profile.marketplace.fields.maxRange": "Pro mou nabídku jsem již tolik kilometrů řídit",
    "profile.marketplace.fields.placeRequired": "Požadavky na hřiště",
    "profile.marketplace.fields.securityRequired": "Potřeba zabezpečení",
    "profile.marketplace.fields.shows": "Nabídka programu",
    "profile.marketplace.fields.spaceRequired": "Potřeba prostoru v m²",
    "profile.marketplace.fields.waterRequired": "Potřeba vody v m³",
    "profile.marketplace.new": "Nová nabídka nebo potřeba",
    "profile.marketplace.offer.delete": "Odstranit",
    "profile.marketplace.offer.description": "Popis",
    "profile.marketplace.offer.descriptionPlaceholder": "Smysluplný popis, aby s ním jiný uživatel mohl něco udělat.",
    "profile.marketplace.offer.new": "Vytvořit novou nabídku",
    "profile.marketplace.offer.relatedEntry": "Přiřazená položka",
    "profile.marketplace.offer.tags": "Štítky pro nabídku/požadavek (budou lépe nalezeny)",
    "profile.marketplace.offer.title": "Nabízím",
    "profile.marketplace.request.delete": "Odstranit požadavek",
    "profile.marketplace.request.new": "Vytvořit nový požadavek",
    "profile.marketplace.request.missingCredentials": "Poptávku můžete zahájit až po vytvoření alespoň jednoho místa a jednoho bodu měření.",
    "profile.marketplace.request.title": "Potřebuji",
    "profile.messages.approve": "Potvrďte",
    "profile.messages.as": "jako",
    "profile.messages.commentPre": "má adresu",
    "profile.messages.commentSuff": "komentáře",
    "profile.messages.decided": "se rozhodl",
    "profile.messages.deletedUser": "Smazaný uživatel",
    "profile.messages.donateDream": "Děkujeme vám za pomoc při realizaci tohoto příspěvku. Ihned obdrží vaši zprávu a doufejme, že vás bude kontaktovat a přijme vaši pomoc.",
    "profile.messages.dreamer": "Účastník",
    "profile.messages.dreamToo": "také snít",
    "profile.messages.enter": "připojil se k",
    "profile.messages.favoriteDream": "Nyní sledujete tento záznam.",
    "profile.messages.follow": "následuje",
    "profile.messages.has": "má adresu",
    "profile.messages.is": "je",
    "profile.messages.join": "PŘIPOJIT SE K",
    "profile.messages.joinDream": "Chcete snít spolu s tímto záznamem.",
    "profile.messages.likeDream": "Tento záznam se vám nyní líbí.",
    "profile.messages.sendText": "Vaše zpráva byla odeslána.",
    "profile.messages.shareDream": "Úspěšně jste sdíleli tento záznam.",
    "profile.messages.supporter": "Příznivci",
    "profile.messages.want": "by si přál",
    "profile.messages.youAre": "Jste",
    "profile.messages.youWant": "Chtěli byste",
    "profile.newsletter.notSubscribed": "přihlásit se k odběru",
    "profile.newsletter.notSubscribedAny": "Ještě jste se nepřihlásili k odběru novinek.",
    "profile.newsletter.privacyInformation": "Kliknutím na tlačítko \"Přihlásit se k odběru\" souhlasíte s tím, abychom vám v budoucnu zasílali informační bulletiny o tomto odběru (opt-in). Z odběru newsletteru se můžete kdykoli odhlásit opětovným kliknutím na \"přihlásit se k odběru\" (opt-out).",
    "profile.newsletter.subscribed": "předplatné",
    "profile.press.articleDetail": "Detail článku",
    "profile.press.downloadImage": "Stáhnout obrázek",
    "profile.press.downloadImages": "Stáhnout obrázky",
    "profile.press.downloadText": "Exportovat text",
    "profile.press.filterAuthor": "Filtrovat podle autora",
    "profile.press.hide": "skrýt",
    "profile.press.show": "slábnout",
    "profile.progress.title": "Jak vylepšit svůj profil",
    "register.ui.addressData": "Údaje o adrese",
    "register.ui.addressInfo": "Nemusí zadávat svou osobní adresu. To však vede ke správnému výpočtu trasy a vzdálenosti.",
    "register.ui.companyData": "Údaje o vaší společnosti",
    "register.ui.companyDataMandatory": "Musíte zadat název společnosti",
    "register.ui.emptyFirstName": "Musíte zadat své křestní jméno",
    "register.ui.emptyLastName": "Musíte zadat své příjmení",
    "register.ui.emptyMail": "Musíte zadat platnou e-mailovou adresu",
    "register.ui.emptyUserName": "Je třeba zadat uživatelské jméno",
    "register.ui.language": "Jazyk",
    "register.ui.loginData": "Přihlašovací údaje",
    "register.ui.password": "Heslo",
    "register.ui.passwordRepeat": "Opakování hesla",
    "register.ui.personalData": "Vaše osobní údaje",
    "register.ui.privacyStatement": "Souhlasím s uchováváním svých osobních údajů. Mé osobní údaje nebudou předány třetím stranám. Tento souhlas s ochranou osobních údajů mohu kdykoli odvolat s účinností do budoucna.",
    "register.ui.takenEmail": "Tento e-mail byl již zadán uživatelem.",
    "register.ui.takenUserName": "Toto uživatelské jméno je již obsazeno",
    "register.ui.timezone": "Časové pásmo",
    "register.ui.typeMinimum": "Musíte vybrat alespoň jeden typ",
    "register.ui.usernameInfo": "Důležité: Toto je vaše uživatelské jméno pro přihlášení.",
    "register.ui.oauthInfo": "Budete přesměrováni na přihlašovací stránku FRM, kde se přihlásíte svými uživatelskými údaji, a poté se vrátíte na webové stránky.",
    "search.allDistances": "Všechny vzdálenosti",
    "search.andFilter": "a nastavení filtrů",
    "search.closeTags": "Skrýt značky",
    "search.for": "na",
    "search.resetPosition": "Obnovení polohy",
    "search.geoPositionBrowser": "Určení polohy pomocí prohlížeče",
    "search.geoPositionFault": "Určení polohy prohlížečem není možné",
    "search.geoPositionTitle": "Nastavení polohy pro vyhledávání v blízkosti",
    "search.selectedFilters": "Vybrané filtry",
    "search.selectFilters": "Filtrování vyhledávání podle kategorií",
    "search.hits": "Hit",
    "search.openTags": "Zobrazit tagy",
    "search.removeDatefilter": "Odstranit filtr datum",
    "search.resulted": "odhalil",
    "search.resultType.category": "Kategorie",
    "search.resultType.entry": "Vstup",
    "search.resultType.event": "Událost",
    "search.resultType.group": "Skupina",
    "search.resultType.page": "Stránka",
    "search.resultType.person": "Osoba",
    "search.resultType.post": "Příspěvek",
    "search.resultType.product": "Produkt",
    "search.resultType.article": "Článek",
    "search.resultType.job": "Práce",
    "search.resultType.recipe": "Recept",
    "search.search": "Vyhledávání",
    "search.result": "Výsledek",
    "search.results": "Výsledky",
    "search.searchBarPlaceholder": "Vyhledávání...",
    "search.searchBarPlaceholderEntries": "Hledejte podle jména prodejce nebo adresy prodejce.",
    "search.searchBarPlaceholderEntriesPosition": "Obvodové vyhledávání s vlastní polohou (např. město)...",
    "search.searchBarPlaceholderEntriesPositionZip": "...nebo poštovní směrovací číslo",
    "search.searchBarPlaceholderGeoSearch": "Zadejte svou adresu...",
    "search.searchBarPlaceholderMobile": "Vyhledávání...",
    "search.searchBarPlaceholderProducts": "Vyhledávání produktů",
    "search.searchBarPositionTooltip": "Určení polohy nebo zadání polohy s adresou",
    "search.showAll": "Zobrazit všechny",
    "search.globalSearch": "Vyhledávací stránka",
    "social.comment": "Komentář:",
    "social.Comment": "Komentář:",
    "social.CopycatJoiner": "Vysněte si svůj vstup",
    "social.dream.donateAmount": "Podpora peněžní částkou",
    "social.dream.donateDreampoints": "Podpora pomocí Dreampointů",
    "social.dream.donateInfo": "Zpráva je soukromá a může ji číst pouze vlastník záznamu.",
    "social.dream.donateTip": "Podpora pomocí rad a opatření",
    "social.dream.done": "DONE",
    "social.dream.edit": "Upravit položku",
    "social.dream.findDreams": "Najděte zde malé i velké příspěvky a realizujte svůj příspěvek a/nebo pomozte ostatním realizovat jejich příspěvky.",
    "social.dream.followedDreams": "Vstupy, které sledujete",
    "social.dream.giveEmail": "Sdílet mou e-mailovou adresu",
    "social.dream.givePhone": "Sdílet mé telefonní číslo",
    "social.dream.joinerApproved": "Připojíte se k tomuto záznamu",
    "social.dream.joinerButton": "Připojte se k tomuto záznamu",
    "social.dream.joinerButtonMobile": "Zúčastněte se",
    "social.dream.joinerDream": "Skupinový sen",
    "social.dream.joinerInfo": "Pokud vlastník záznamu potvrdí vaši účast, stane se tento záznam skupinovou místností, kde máte další práva.",
    "social.dream.joinerMessage": "Zde zadejte zprávu pro vlastníka položky:",
    "social.dream.joinerPending": "Vaši účast musí ještě potvrdit majitel přihlášky.",
    "social.dream.joinerSupport": "Podporujete tento záznam",
    "social.dream.joinerSupportButton": "Podpořte tento záznam",
    "social.dream.joinerSupportButtonMobile": "Podpora",
    "social.dream.joinerSupportInfo": "Neustále pracujeme na vylepšeních, jako je podpora penězi nebo vstupními body. Zůstaňte naladěni.",
    "social.dream.joinerSupportPending": "Vlastník záznamu musí stále povolit vaši podporu",
    "social.dream.member": "Člen",
    "social.dream.members": "Členové",
    "social.dream.meToo": "Chci to udělat taky",
    "social.dream.meTooInfo": "Ukažte vlastníkovi záznamu, že si myslíte, že je záznam skvělý a že jej chcete vytvořit.",
    "social.dream.noFollow": "Nesledujete žádný záznam!",
    "social.dream.noJoiner": "Ještě nejste členem skupiny sen",
    "social.dream.noLikes": "Ještě se vám nelíbí žádný záznam!",
    "social.dream.noSupport": "Nepodporujete položku",
    "social.dream.registerFirstText": "Abyste mohli příspěvek sdílet, podpořit jej nebo uvést, že byste jej také rádi vložili, musíte být přihlášeni. Registrace ke sdílení příspěvku je rychlá a bezplatná. Stačí kliknout na tlačítko \"Registrovat\".",
    "social.dream.registerFirstTitle": "Sníte, podporujete, dokonce děláte?",
    "social.dream.settings": "Nastavení vstupu",
    "social.dream.shareTitle": "Sdílet sen",
    "social.dream.similar": "Podobné položky",
    "social.dreamline.addImageToDream": "Přidejte tento obrázek do svého příspěvku",
    "social.dreamline.addMedia": "Přidat obrázek / video",
    "social.dreamline.allActivities": "Všechny aktivity společnosti Dreamline",
    "social.dreamline.anonymous": "Anonymní",
    "social.dreamline.commentButton": "Komentář:",
    "social.dreamline.comments": "píše:",
    "social.dreamline.depublished": "Tento komentář byl odstraněn pro nevhodné chování.",
    "social.dreamline.dreampointsInfo": "To jsou body snů tohoto záznamu. Více informací o Dreampointech najdete ve svém profilu.",
    "social.dreamline.fewDreampoints": "má jen několik bodů snů. Komentujte, lajkujte nebo sdílejte příspěvek.",
    "social.dreamline.filterBySteps": "Filtrování podle kroků",
    "social.dreamline.filterComment": "Komentáře",
    "social.dreamline.filteredBy": "Činnosti filtrované podle",
    "social.dreamline.filteredBySteps": "Filtrováno podle kroků",
    "social.dreamline.filterFavorite": "Sleduje",
    "social.dreamline.filterLike": "Líbí se mi",
    "social.dreamline.filterShare": "Akcie",
    "social.dreamline.filterStep": "Kroky",
    "social.dreamline.flagged": "Tento příspěvek byl nahlášen",
    "social.dreamline.isComment": "komentáře",
    "social.dreamline.isCopycatJoiner": "také sen",
    "social.dreamline.isFirstUpdate": "vytvořeno",
    "social.dreamline.isShare": "sdílené",
    "social.dreamline.isStep": "učinil nový krok",
    "social.dreamline.isUpdate": "aktualizováno",
    "social.dreamline.lastEntry": "Aktualizace posledního záznamu",
    "social.dreamline.locked": "Tento obsah je viditelný pouze pro ostatní snílky",
    "social.dreamline.lockedCTA": "Připojte se k tomuto záznamu nyní",
    "social.dreamline.noMessages": "Tento záznam zatím nevykazuje žádnou aktivitu. Napište první komentář.",
    "social.dreamline.noMessagesFiltered": "U tohoto záznamu není s tímto nastavením filtru žádná aktivita.",
    "social.dreamline.nowFollower": "následuje tento záznam!",
    "social.dreamline.nowJoiner": "je nyní příznivcem",
    "social.dreamline.nowLikes": "jako tento záznam nyní!",
    "social.dreamline.nowSharing": "sdílel tento záznam!",
    "social.dreamline.onlyJoinerVisible": "Viditelný pouze pro ostatní snílky/podporovatele",
    "social.dreamline.prefixComment": "má tuto položku před",
    "social.dreamline.prefixCopycatJoiner": "ráda bych přidala tento záznam, protože",
    "social.dreamline.prefixFavorite": "sleduje tento záznam od",
    "social.dreamline.prefixJoiner": "podporuje tuto položku, protože",
    "social.dreamline.prefixLike": "se líbí tento záznam od",
    "social.dreamline.prefixShare": "má tuto položku před",
    "social.dreamline.prefixStep": "předtím",
    "social.dreamline.prefixUpdate": "má tuto položku před",
    "social.dreamline.profile": "Profil",
    "social.dreamline.provideImage": "Dávám tento obrázek k dispozici majiteli záznamu.",
    "social.dreamline.resetFilter": "Obnovit filtr",
    "social.dreamline.sendComment": "Odeslat",
    "social.dreamline.sharePart": "Sdílet",
    "social.dreamline.toggleStream": "Komentáře",
    "social.dreamline.toggleStreamSingle": "Komentář:",
    "social.dreamline.writeComment": "Napište komentář...",
    "social.Favorite": "Sledujte",
    "social.follow": "Sledujte",
    "social.Follow": "Sledujte",
    "social.hasLiked": "Tento záznam se vám již líbí.",
    "social.join": "Připojte se k",
    "social.Join": "Připojte se k",
    "social.Joiner": "Snění podél",
    "social.like": "Stejně jako",
    "social.Like": "Stejně jako",
    "social.report.headlinePrefix": "Jste tam",
    "social.report.headlineSuffix": "hlásit.",
    "social.report.login": "Pro nahlášení příspěvku musíte být přihlášeni.",
    "social.report.personSuffix": "s",
    "social.report.reportOther": "Další urážlivý obsah",
    "social.report.reportScam": "Zkreslování, šikana.",
    "social.report.reportSex": "Sexuální obsah",
    "social.report.reportSpam": "Jedná se o spam",
    "social.report.reportViolence": "Zobrazení násilí",
    "social.report.send": "Odeslat zprávu",
    "social.report.title": "Nahlásit",
    "social.share": "Sdílet",
    "social.Share": "Sdílet",
    "syd.ui.dreamerCommercial": "Standard B2B",
    "syd.ui.userCommercial": "B2B Dreamer",
    "user.company.addUser": "Vytvoření dalších uživatelů pro",
    "user.company.inviteUser": "Odeslání pozvánky",
    "user.company.userIsInvited": "Byl odeslán e-mail s pozvánkou",
    "user.company.userIsActive": "Uživatel je aktivní",
    "user.company.userIsNotActive": "Uživatel ještě není aktivován",
    "user.company.additionalWebsite": "Další webové stránky společnosti (pouze doména bez https://)",
    "user.company.companyName": "Název společnosti",
    "user.company.contactFor": "Kontaktní údaje pro",
    "user.company.contactForDefault": "Obecný kontakt",
    "user.company.copyContact": "Převzetí kontaktních údajů z údajů o společnosti pro zadání",
    "user.company.email": "Kontaktní e-mail",
    "user.company.facebook": "Účet společnosti na Facebooku (pouze název účtu)",
    "user.company.fax": "Číslo faxu pro písemný kontakt",
    "user.company.google": "Účet společnosti na Google+ (pouze název účtu)",
    "user.company.instagram": "Účet společnosti na Instagramu (pouze název účtu)",
    "user.company.linkedin": "Účet společnosti na síti LinkedIn (pouze název účtu)",
    "user.company.newCustomerAccount": "Vytvoření firemního účtu",
    "user.company.noCustomerValidation": "Váš firemní účet ještě nebyl aktivován.",
    "user.company.noCustomerYet": "Vaší registraci zatím nebyl přiřazen obchodní účet.",
    "user.company.notAllowed": "Nemůžete provádět žádné změny, protože váš uživatelský status ({{type}}) to neumožňuje.",
    "user.company.phone": "Kontaktní telefonní číslo",
    "user.company.pinterest": "Účet společnosti na Pinterestu (pouze název účtu)",
    "user.company.remarks": "Poznámky",
    "user.company.registerNumber": "Číslo obchodního rejstříku",
    "user.company.registerAuth": "Místní soud",
    "user.company.acerCode": "Kód ACER vaší dodávky energie",
    "user.company.taxNumber": "Daňové číslo",
    "user.company.type.default": "Standardní",
    "user.company.type.procurator": "Oprávněný signatář",
    "user.company.type.manager": "Výkonný ředitel",
    "user.company.twitter": "Účet společnosti na Twitteru (pouze název účtu bez @)",
    "user.company.ustid": "UStID",
    "user.company.ustid.notValid": "Uveďte prosím platné identifikační číslo pro DPH",
    "user.company.website": "Webové stránky společnosti (pouze doména bez https://)",
    "user.company.xing": "Účet Xing společnosti (pouze název účtu)",
    "user.company.youtube": "Účet společnosti na Youtube (pouze název účtu)",
    "user.message.after-save-error": "Vaše změny se nepodařilo uložit.",
    "user.message.after-save-success": "Vaše změny byly uloženy.",
    "user.message.can-not-login": "S těmito údaji uživatele a hesla vás nemůžeme přihlásit! Je uživatelské jméno napsáno správně? Zadali jste heslo správně? Při registraci se mohlo něco pokazit.",
    "user.message.can-not-logout": "Nemohli jsme vás odhlásit!",
    "user.message.conflict": "Toto uživatelské jméno již existuje, zvolte jiné!",
    "user.message.fileExistsError": "Odeslání se nezdařilo. Soubor již existuje",
    "user.message.fileSizeError": "Odeslání se nezdařilo. Soubor je příliš velký",
    "user.message.loginFailureBottom": "Možná ještě nemáte účet Entryer?",
    "user.message.loginFailureTop": "Je vaše uživatelské jméno napsáno správně? Zadali jste heslo správně?",
    "user.message.registration-error": "Při registraci došlo k chybě!",
    "user.message.registration-success": "Brzy byste od nás měli obdržet potvrzovací e-mail.",
    "user.message.unknownError": "Neznámá chyba při nahrávání souboru",
    "user.message.upload-success": "Úspěšné nahrání",
    "user.message.verify-error": "Vaši e-mailovou adresu se nepodařilo ověřit. Kontaktujte prosím správce.",
    "user.message.verify-success": "Vaše e-mailová adresa byla potvrzena. Nyní se můžete zaregistrovat:",
    "user.ui.accountGreeting": "Dobrý den,",
    "user.ui.accountMenu": "Domovská stránka a informace",
    "user.ui.accountMenuActivities": "Aktivity",
    "user.ui.accountMenuCompanySettings": "Informace o společnosti",
    "user.ui.accountMenuEntries": "Moje příspěvky",
    "user.ui.accountMenuEntryPre": "Vstupy,",
    "user.ui.accountMenuEvents": "Moje události",
    "user.ui.accountMenuFavorites": "Moji oblíbení",
    "user.ui.accountMenuFollows": "Záznamy, které sleduji",
    "user.ui.accountMenuImages": "Obrázky/videa/dokumenty",
    "user.ui.accountMenuJoin": "Sny mé skupiny",
    "user.ui.accountMenuLikes": "Příspěvky, které se mi líbí",
    "user.ui.accountMenuMeasuring": "Moje místa a údaje o měření",
    "user.ui.accountMenuMessages": "Zprávy",
    "user.ui.accountMenuNewsletter": "Moje odběry newsletteru",
    "user.ui.accountMenuPoi": "Moje umístění",
    "user.ui.accountMenuPress": "Moje články",
    "user.ui.accountMenuProducts": "Moje produkty",
    "user.ui.accountMenuJobs": "Moje práce",
    "user.ui.accountMenuProfile": "Profil uživatele",
    "user.ui.accountMenuRatings": "Recenze",
    "user.ui.accountMenuRegistrationData": "Přihlašovací údaje",
    "user.ui.accountMenuReviews": "Recenze",
    "user.ui.accountMenuSettings": "Nastavení",
    "user.ui.accountMenuSkillsOffer": "Nabízím...",
    "user.ui.accountMenuSkillsRequest": "Potřebuji...",
    "user.ui.accountMenuOffer": "Moje nabídky",
    "user.ui.accountMenuRequest": "Moje požadavky",
    "user.ui.accountMenuSkillsSearch": "Žádosti a nabídky",
    "user.ui.accountMenuSubscriptions": "Předplatné",
    "user.ui.accountMenuSubscritions": "Moje předplatné",
    "user.ui.accountMenuSupport": "Podporované položky",
    "user.ui.accountMenuSupports": "Příspěvky, které podporuji",
    "user.ui.accountMenuTender": "Nabídky",
    "user.ui.accountMenuOrders": "Moje objednávky",
    "user.ui.accountNoMessages": "Zatím jste neobdrželi žádné zprávy.",
    "user.ui.accountProgressbar": "Váš profil je {{value}}% dokončeno",
    "user.ui.accountProgressbarEntry": "Váš vstup je {{value}}% dokončeno",
    "user.ui.accountSettings": "Další nastavení",
    "user.ui.accountCookieSettings": "Kliknutím sem upravíte nastavení.",
    "user.ui.accountCookieEssential": "Základní",
    "user.ui.accountCookieAnalysis": "Analýza dat",
    "user.ui.accountCookieSocial": "Sociální média",
    "user.ui.accountCookieReviews": "Recenze",
    "user.ui.accountToolbarLogin": "Přihlášení",
    "user.ui.accountToolbarLoginTooltip": "Zaregistrujte se zdarma a začněte",
    "user.ui.accountToolbarLogout": "Odhlášení",
    "user.ui.adminPreview": "Náhled",
    "user.ui.backToLogin": "Zpět na registrační stránku",
    "user.ui.birthday": "Narozeniny",
    "user.ui.cannotLogin": "Nemůžete se přihlásit? Klikněte zde pro obnovení hesla.",
    "user.ui.changePassword": "Rád bych si změnil heslo",
    "user.ui.changeUserGroup": "Ostatní předplatné",
    "user.ui.city": "Město",
    "user.ui.companyName": "Název společnosti",
    "user.ui.companyNameTooltip": "Důležité: Váš záznam se pak zobrazí také pod těmito názvy společností.",
    "user.ui.country": "Země",
    "user.ui.createNewAccount": "Vytvoření nového uživatelského účtu",
    "user.ui.dataDelete": "další položky. Chcete soubor trvale odstranit? Všude, kde je tento soubor použit, je nahrazen zástupným znakem.",
    "user.ui.dataUsage": "Soubor je uložen v",
    "user.ui.dataUsageNone": "Soubor se dosud nepoužívá. Chcete soubor trvale odstranit?",
    "user.ui.deleteImage": "Odstranit obrázek",
    "user.ui.description": "Zde uveďte obecný popis osoby nebo společnosti.",
    "user.ui.documents": "Dokumenty",
    "user.ui.documentSearch": "Vyhledávání dokumentů",
    "user.ui.download": "{{title}} ke stažení",
    "user.ui.email": "E-mailová adresa",
    "user.ui.fillOutForm": "Pro registraci je nutné vyplnit všechna povinná pole.",
    "user.ui.firstName": "Křestní jméno",
    "user.ui.academicTitle": "Akademický titul",
    "user.ui.gender": "M/Ž",
    "user.ui.genderFemale": "samice",
    "user.ui.genderFemaleSalutation": "Paní",
    "user.ui.genderMale": "muži",
    "user.ui.genderMaleSalutation": "Pan",
    "user.ui.genderDiverse": "různorodé",
    "user.ui.genderThird": "Není specifikováno",
    "user.ui.housenumber": "Ne.",
    "user.ui.iAmCustomer": "Rád bych si vytvořil účet B2B",
    "user.ui.internalInfo": "Jsou tu pro vás zprávy. Klikněte zde.",
    "user.ui.lastLogin": "Poslední registrace dne",
    "user.ui.languageAvailable": "K dispozici ve vašem jazyce",
    "user.ui.languageMissing": "K dispozici pouze v",
    "user.ui.lastName": "Příjmení",
    "user.ui.loginButton": "Přihlášení",
    "user.ui.loginTitle": "Přihlášení uživatele",
    "user.ui.memberSince": "Členem od",
    "user.ui.mobileNumber": "Číslo mobilního telefonu",
    "user.ui.myAddedStorage": "Poskytnuté obrázky",
    "user.ui.myStorage": "Můj adresář",
    "user.ui.noRegisterMessage": "V případě zájmu o uživatelský účet se obraťte na uvedené kontaktní adresy. Jako uživatel se nemůžete zaregistrovat.",
    "user.ui.oldPassword": "Předchozí heslo",
    "user.ui.password": "Heslo",
    "user.ui.phoneType": "Telefon",
    "user.ui.phoneTypeMobile": "Mobilní",
    "user.ui.phoneTypeLandline": "Pevná linka",
    "user.ui.pleaseSelect": "Vyberte prosím",
    "user.ui.pleaseSelectSalutation": "Vyberte prosím pozdrav",
    "user.ui.registerButton": "Nastavení uživatelského účtu nyní",
    "user.ui.registerMessage": "Zaregistrujte se a začněte ihned. Je to rychlé a snadné.",
    "user.ui.registerTitle": "Jste u nás noví?",
    "user.ui.requestPasswordReset": "Vyžádání nového hesla a odstranění starého hesla",
    "user.ui.requestPasswordSuccess": "Brzy byste měli obdržet e-mail s odkazem na obnovení.",
    "user.ui.requestPasswordError": "Neznámá chyba při resetování hesla.",
    "user.ui.resetPassword": "Obnovení hesla",
    "user.ui.setPassword": "Nastavení hesla",
    "user.ui.resetPasswordPage": "Obnovení hesla",
    "user.ui.setPasswordPage": "Nastavení hesla",
    "user.ui.resetPasswordSuccess": "Vaše heslo bylo resetováno, nyní se můžete přihlásit.",
    "user.ui.setPasswordSuccess": "Vaše heslo bylo nastaveno, nyní se můžete přihlásit.",
    "user.ui.selectCustomerType": "Jste ... vyberte prosím",
    "user.ui.stayLoggedin": "Zůstaňte přihlášeni",
    "user.ui.stayLoggedinInfo": "Pokud se jedná o veřejně přístupné zařízení, deaktivujte tuto možnost a pro dokončení se znovu odhlaste.",
    "user.ui.street": "Ulice",
    "user.ui.salutation": "Pozdrav",
    "user.ui.title": "Název",
    "user.ui.thankYouForSubscribing": "Děkujeme, že jste se přihlásili k odběru našeho zpravodaje.",
    "user.ui.errorAtSubscribing": "Chyba při odběru newsletteru",
    "user.ui.unsubscribe": "odhlásit se",
    "user.ui.unsubscribePage": "Opravdu se chcete odhlásit z odběru našeho newsletteru?",
    "user.ui.uploadDrop": "Chcete-li nahrát soubor, přetáhněte jej sem nebo",
    "user.ui.uploadSelectFile": "vybrat soubor",
    "user.ui.uploadToMyStorage": "Nahrání nových obrázků do mého adresáře",
    "user.ui.uploadUserImage": "Výběr obrázku uživatele",
    "user.ui.username": "E-mailová adresa",
    "user.ui.userName": "Uživatelské jméno",
    "user.ui.visibilityInfo": "Tip: O tom, co se bude veřejně zobrazovat z vašeho profilu, rozhodujete vy! Kliknutím na symbol oka nastavíte viditelnost záznamů:",
    "user.ui.zip": "Poštovní směrovací číslo",
    "user.ui.phone": "Telefonní číslo",
    "user.user": "Účastník",
    "user.users": "Záznamy",
    "widgets.shoppingCart.wishlist": "Memo a seznam přání",
    "widgets.shoppingCart.wishlist.empty": "V poznámkovém bloku a seznamu přání zatím nemáte žádné produkty.",
    "widgets.shoppingCart.cart": "Nákupní košík",
    "user.product.addedToFavorites": "Položka byla přidána do oblíbených",
    "order.status.zero": "Přijatá objednávka",
    "order.status.one": "Probíhá",
    "order.status.two": "Objednávka byla odeslána",
    "order.status.three": "Dostupné na",
    "order.status.four": "Návrat na",
    "order.status.default": "Neznámý stav povolání",
    "country.germany": "Německo",
    "country.austria": "Rakousko",
    "country.belgium": "Belgie",
    "country.denmark": "Dánsko",
    "country.bulgaria": "Bulharsko",
    "country.croatia": "Chorvatsko",
    "country.cyprus": "Kypr",
    "country.czechia": "Česká republika",
    "country.estonia": "Estonsko",
    "country.finland": "Finsko",
    "country.france": "Francie",
    "country.greece": "Řecko",
    "country.hungary": "Maďarsko",
    "country.ireland": "Irsko",
    "country.italy": "Itálie",
    "country.latvia": "Lotyšsko",
    "country.lithuania": "Litva",
    "country.luxembourg": "Lucembursko",
    "country.lu": "Lucembursko",
    "country.malta": "Malta",
    "country.netherlands": "Nizozemsko",
    "country.poland": "Polsko",
    "country.portugal": "Portugalsko",
    "country.romania": "Rumunsko",
    "country.slovakia": "Slovensko",
    "country.slovenia": "Slovinsko",
    "country.spain": "Španělsko",
    "country.sweden": "Švédsko",
    "country.norway": "Norsko",
    "country.switzerland": "Švýcarsko",
    "country.turkey": "Krocan",
    "country.unitedKingdom": "Spojené království",
    "openingHours.tNowClosed": "Nyní uzavřeno",
    "openingHours.tOpeningHoursNotKnown": "Další otevírací doba není známa",
    "openingHours.tNowOpen": "Otevřeno nyní",
    "openingHours.tNowStillOpen": "Nyní stále otevřeno",
    "openingHours.tNextOpeningHourUnknown": "datum příštího otevření neznámé",
    "openingHours.tSoonOpenFrom": "brzy znovu otevřít od",
    "openingHours.tFrom": "z",
    "openingHours.tUntil": "na",
    "openingHours.tOpenAgainFrom": "znovu otevřít z",
    "openingHours.tOpenAgainFrom2": "Znovu otevřete na",
    "comparison.fields.measurements": "Hromadné",
    "comparison.fields.weight": "Hmotnost",
    "application.tag.neu": "Nový",
    "application.tag.sale": "Prodej",
    "application.tag.eco": "Eco",
    "application.tag.expired": "prošlé",
    "application.tag.sonderrabatt": "Speciální sleva",
    "job.application.selectLocation": "Vyberte svou polohu",
    "job.application.location": "K dispozici v následujících regionech"
  };
});